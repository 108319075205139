import WritingInstructionsCard from "../common/WritingInstructionsCard";
import { observer } from "mobx-react-lite";

const WritingInstructions = observer((props: any) => {
  const { nextStep } = props;

  return (
    <div className="w-full flex justify-center flex-col">
      <p className="w-11/12 mx-10 text-base md:text-xl font-extrabold mb-6">IELTS Academic Writing - 1 hour</p>
      <WritingInstructionsCard
        border='rgba(227, 24, 55, 0.30)'
        bg='linear-gradient(216deg, #FFF9FA 1.21%, #FFF 97.42%)'
        width='w-full'
        height='h-full'>
          <>
            <div>
              <h3 className="text-base md:text-xl font-extrabold mb-2">
                INSTRUCTIONS TO CANDIDATES
              </h3>
              <h4>1. Answer both parts.</h4>{" "}
              <h4>2. You can change your answers at any time during test.</h4>
            </div>

            <div className="mt-6">
              <h3 className="text-base md:text-xl font-extrabold mb-2">
                INFORMATION FOR CANDIDATES
              </h3>
              <h4>1. There are 2 parts in this test.</h4>
              <h4>
                2. Part 2 contributes twice as much as Part 1 to the writing
                score.
              </h4>
              <h4>
                3. The test clock will show you when there are 10 minutes and 5
                minutes remaining.
              </h4>
            </div>
            <h4 className="md:mt-64 text-center">
              Do not click ‘Start test’ until you are told to do so.{" "}
            </h4>
            <button
              onClick={nextStep}
              className="bg-[#E31837] text-white py-2 mt-6 px-6 mx-auto rounded-full"
            >
              Start Test
            </button>
          </>
     </WritingInstructionsCard>
    </div>
  );
});

export default WritingInstructions;
