import { observer } from "mobx-react-lite";
import { useStore } from "../Hooks/use-store";
import { useNavigate } from "react-router-dom";
import FullTestStartIntroCard from "../components/instructionsCard/instructionsLayout/FullTestStartIntroCard";
import { useEffect } from "react";


const Fulltest = observer(() => {
  const { startTestStore } = useStore();
  let testId = startTestStore.testId;
  let testType = startTestStore.testType;
  let currentPart = startTestStore.currentStep;
  let step = startTestStore.step;
  
  const navigate = useNavigate();
 
  const handleClose = async(navigation : boolean) => {
    startTestStore.setCounter(false);
    startTestStore.setGetTestV2results([]);
    startTestStore.setIsAnimated(false);
    startTestStore.setIsRecording(false);
    startTestStore.setIsLoading()
    startTestStore.setStep(0);
    startTestStore.setIsRecording(false);
    startTestStore.setIsTaskCard(false);
    startTestStore.setCompleteTestFailed(false);
    navigation && navigate("/speaking");
  };

  const breadCrumbsParts = () => {
    if (startTestStore.partType !== "full") {
      return <> &gt; {startTestStore.partType}</>;
    }
  };

  useEffect(() => {
    window.addEventListener('popstate', () => {
      // Perform your desired operations here
      startTestStore.setCounter(false);
      startTestStore.setGetTestV2results([]);
      startTestStore.setIsAnimated(false);
      startTestStore.setIsRecording(false);
      startTestStore.setStep(0);
      navigate("/speaking");
      startTestStore.setIsRecording(false);
    });

    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener('popstate', () => {});
  }, []);

  const testLabel = (type : any) => {
    if (type === "full") {
      return "Full Test";
    } else if (type === "part1") {
      return "Part 1";
    } else if (type === "part2") {
      return "Part 2";
    } else {
      return "Part 3";
    }
  }

  return (
    <div className="border border-solid border-[#E3E5E9] rounded-md bg-[#FEFEFE] pb-6 h-full flex-shrink-0 m-[3vw] ">
        <div className="h-16 mb-3 flex justify-between items-center px-5 py-3 border-b border-b-[#E3E5E9]">
          <div className="text-[#292D35] font-semibold text-2xl">
            {testLabel(testType)}
            {testType === "full" && breadCrumbsParts()}
          </div>
          <button onClick={() => handleClose(true)} className="cursor-pointer">
            Close
          </button>
        </div>
        <FullTestStartIntroCard testId={testId} testType={testType} handleClose={handleClose}/>
      </div>
  );
});

export default Fulltest;
