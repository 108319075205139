import ReadingPartTest from './ReusablePart'
import { useStore } from "../../../Hooks/use-store";
import { observer } from 'mobx-react-lite';


const Readingpart3 = observer(({ nextStep }: any) => {
    const { startTestStoreReading } = useStore();
    const { part3Questions } = startTestStoreReading;
    const part3StateData = {
      singleAnswer : startTestStoreReading.part3SingleAnswer,
      setSingleAnswer : startTestStoreReading.setPart3SingleAnswer,
      multipleAnswer : startTestStoreReading.part3MultipleAnswer,
      setMultipleAnswer : startTestStoreReading.setPart3MultipleAnswer,
      taskIdentifyingItemAnswers : startTestStoreReading.part3TaskIdentifyingItemAnswers,
      setTaskIdentifyingItemAnswers : startTestStoreReading.setPart3TaskIdentifyingItemAnswers,
      noteCompletionAnswer : startTestStoreReading.part3NoteCompletionAnswer,
      setNoteCompletionAnswer : startTestStoreReading.setPart3NoteCompletionAnswer,
      senetenceComplete : startTestStoreReading.part3SenetenceComplete,
      setSenetenceComplete : startTestStoreReading.setPart3SenetenceComplete,
      summaryCompletionOneWordAnswers : startTestStoreReading.part3SummaryCompletionOneWordAnswers,
      setSummaryCompletionOneWordAnswers : startTestStoreReading.setPart3SummaryCompletionOneWordAnswers,
      dragAnswers : startTestStoreReading.part3DragAnswers,
      setDragAnswers : startTestStoreReading.setPart3DragAnswers,
      dragAndDropAnswers : startTestStoreReading.part3DragAndDropAnswers,
      setDragAndDropAnswers : startTestStoreReading.setPart3DragAndDropAnswers,
      dragSentenceAnswers : startTestStoreReading.part3DragSentenceAnswers,
      setDragSentenceAnswers : startTestStoreReading.setPart3DragSentenceAnswers,
      draggedCurrentSentence : startTestStoreReading.part3DraggedCurrentSentence,
      setDraggedCurrentSentence : startTestStoreReading.setPart3DraggedCurrentSentence,
      tableCompletionAnswer : startTestStoreReading.part3TableCompletionAnswer,
      setTableCompletionAnswer : startTestStoreReading.setPart3TableCompletionAnswer,
      matchingHeadingOptions : startTestStoreReading.part3MatchingHeadingOptions,
      setMatchingHeadingOptions : startTestStoreReading.setPart3MatchingHeadingOptions,
      matchingHeadingAnswer : startTestStoreReading.part3MatchingHeadingAnswer,
      setMatchingHeadingAnswer : startTestStoreReading.setPart3MatchingHeadingAnswer,
      matchingFeatureAnswers : startTestStoreReading.part3MatchingFeatureAnswers,
      setMatchingFeatures : startTestStoreReading.setPart3MatchingFeatures,
    }


  return (
    <ReadingPartTest store={startTestStoreReading} stateData={part3StateData} paraQuestions={part3Questions} nextStep={nextStep} partType={'part3'}/>
  )
})

export default Readingpart3