import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter} from "react-router-dom";
import { useStore } from "./Hooks/use-store";
import { apiInterceptor } from "./api/api-interceptor";
import ScrollToTop from "./components/common/scrolltoTop";
import RoutingC from "./components/Routing/RoutingC";


function App() {
  const { userStore } = useStore();
  
  apiInterceptor(userStore);
  return (
    <BrowserRouter>
      <ScrollToTop />
     <RoutingC />
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;