import apiSauce from "./api-sauce";
import { jwtDecode } from "jwt-decode";

export const apiInterceptor = (userStore: any) => {
    apiSauce.apisauce?.axiosInstance.interceptors.request.use(async (config) => {

            const excludedPaths = ['/get_auth_token', '/send-otp', '/verify-otp?version=2.0', '/sso/login'];
            const shouldExclude = excludedPaths.some(path => config?.url?.includes(path));

            if (!shouldExclude) {
                
                const tokenExpired = checkTokenExpiry(userStore?.token);
                let token = null;
                if (tokenExpired) {
                  try {
                    token = await userStore?.getMemoizedRefreshToken(userStore?.refreshToken);
                  } catch (err) {
                    console.error(err);
                  }
                }

                if (config.headers) {
                    config.headers["Authorization"] = `Bearer ${token ?? userStore?.token}`;
                }
            }
        return config;
    },
    (error) => {
        Promise.reject(error);
    });
    apiSauce.apisauce?.addMonitor(monitor => {
        if (monitor.status === 436) {
            const token_expired_event = new Event('token-expired');
            window.dispatchEvent(token_expired_event);
        }
    });
}

const checkTokenExpiry = (token : string ) => {
    if(token){
        let tokenData = jwtDecode(token);
        let currentTime = Date.now() / 1000; //converting millisec into sec.
        if(currentTime > ((tokenData?.exp as number) - (5 * 60))){ //checking weather current Time > 5 min lesser than token exp
            return true;
        }
        return false;
    }
}
