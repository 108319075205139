import apiSauce from "./api-sauce";


export class PaymentApi {
    async getplansApi(): Promise<any> {
        return apiSauce.apisauce?.post(`web/get-plans`)
    }

    async generateOrderApi(body:any):Promise<any>{

        return apiSauce.apisauce?.post(`/v1/generate-order`,JSON.stringify(body))
    }
}