import { observer } from "mobx-react-lite";
import PartTestsLayout from "../PartTestsLayout";
import { useStore } from "../../../Hooks/use-store";
import { useEffect, useState } from "react";

const WritingPart1 = observer(({ nextStep }: any) => {
  const { resultsStoreWriting, startTestStoreWriting } = useStore();
  const { writingPart1Answer } = resultsStoreWriting;
  const [wordCount, setWordCount] = useState(0);
  let time = startTestStoreWriting.timer;

  const handleChange = (e: any) => {
    const currentValue = e.target.value;
    const words = currentValue.split(/\s+/).filter(Boolean);

    if (words.length > 1000) {
      const trimmedValue = currentValue.split(/\s+/).slice(0, 1000).join(' ');
      resultsStoreWriting.setWritingPart1Answers(trimmedValue, '');
      alert("Attention: Your content surpasses the 1000-word limit. Kindly reduce the word count.");
      return;
    } else {
      resultsStoreWriting.setWritingPart1Answers(currentValue, '');
    }

    setWordCount(words.length);
  };

  useEffect(() => {
    startTestStoreWriting.setPartType("part1");
  }, []);

  useEffect(() => {
    if (time <= 0) {
      resultsStoreWriting.setStepManagement("part_test_completed", "Part 1", "Part 2");
    }
  }, [time]);

  useEffect(() => {
    const words = writingPart1Answer.text ? writingPart1Answer.text.trim().split(/\s+/) : []
    if(words.length >0){
      setWordCount(words.length);
    }else{
      setWordCount(0);
    }
   
  }, [writingPart1Answer.text]);

  return (
    <div className="h-screen">
      <PartTestsLayout
        disabled={wordCount < 150}
        buttonTitle={startTestStoreWriting.testType !== 'full' ? "Submit" : "Next"}
        partType={"Part1"}
        nextStep={nextStep}
        title={"Academic Writing Part 1"}
        desc={"1. You should spend 20 minutes on this task. Write at least 150 words."}
      >
        <div className="flex p-6 h-full justify-between flex-wrap md:flex-nowrap mx-6 bg-[#F8F9FA] border border-[#E3E5E9] rounded-lg">
          <div className="w-full">
            <p className="mb-8 w-11/12">
              {startTestStoreWriting?.part1Questions?.question}
            </p>
            <img src={startTestStoreWriting?.part1Questions?.image} alt="questionImg" />
          </div>
          <div className="w-full pl-3">
            <div className="h-[90%]">
              <textarea
                name="text"
                onChange={handleChange}
                placeholder="Write your answer..."
                value={writingPart1Answer.text}
                className="w-full bg-[#FFF] border border-[E3E5E9] rounded-lg h-[90%] p-4"
              ></textarea>
              <span className="text-red-600 text-sm">
                *The maximum word count for this test is 1000 words. Please ensure your content adheres to this limit.
              </span>
            </div>
            <div className="flex justify-between pt-4">
              <p className="text-[#4E5664] text-xs md:text-lg">Word Count: {wordCount}</p>
              <p className="text-[#292D3566] text-xs md:text-lg cursor-pointer">UPLOAD IMAGE</p>
            </div>
          </div>
        </div>
      </PartTestsLayout>
    </div>
  );
});

export default WritingPart1;
