import React, { useEffect } from "react";
import PartTestLayoutReading from "../partTestLayoutReading";
import RenderQuestions from "../utils/renderingQuestions";
import { observer } from "mobx-react-lite";
import useDragAndDropSentence from "../../../customHooks/useDragAndDropSentence";

const ReadingPartTest = observer(({ nextStep, stateData, store, paraQuestions, partType }: any ) => {

  const {paragraph, questions} = paraQuestions;
  const { handleDragSentenceStartFromAnswer, handleDragOver } = useDragAndDropSentence();

  const renderParagraph =(para : string)=>{
    const regex = /{qId: (\d+)}./g;
    const hasQId = regex.test(para);
    if (hasQId) {
      return para.split(regex).map((part: any, index: any) => {
        if (index % 2 === 0) {
          return part;
        } else {
          const qId = part;
          let passageHeading = stateData.matchingHeadingAnswer.find(
            (item: any) => item.qId == qId
          );
          
          return (
            <button //these have been changed from span tag to button to avoid sonar issue
              key={qId}
              onDragOver={(e) => handleDragOver(e)}
              onDrop={(e) => handleMatchingheadingDrop(e, qId)}
              className={`rounded-lg p-1 my-1  text-[#E3E5E9] text-justify ${
                passageHeading?.qId
                  ? "border border-[#E31837]"
                  : "border border-[#E3E5E9]"
              }`}
            >
              {passageHeading?.qId ? (
                <button
                  className="text-[#292D35] cursor-pointer"
                  draggable
                  onDragOver={(e) => handleDragOver(e)}
                  onDragStart={(e) => handlePassageHeadingDragStart(e, passageHeading.answer)}
                >
                  {passageHeading?.answer}
                </button> //these have been changed from span tag to button to avoid sonar issue
              ) : (<span>Drop Here qId:${qId}</span>
              )}
            </button>
          );
        }
      });
    }else{
      return <div className='whitespace-pre-line'>{para}</div>;
    }
    
  }
  const handleRadioChange = (qId: any, answer: any) => {
    const existingAnswerIndex = stateData.singleAnswer.findIndex(
      (answer: any) => answer.qId === qId
    );
    if (
      existingAnswerIndex !== -1 &&
      stateData.singleAnswer[existingAnswerIndex].answer === answer
    ) {
      const updatedAnswers = [...stateData.singleAnswer];
      updatedAnswers[existingAnswerIndex].answer = null; // Set to null or an empty string as per your requirement
      stateData.setSingleAnswer(updatedAnswers);
      return; // Exit the function early since we've handled the deselection
    }
    if (existingAnswerIndex !== -1) {
      const updatedAnswers = [...stateData.singleAnswer];
      updatedAnswers[existingAnswerIndex].answer = answer;
      stateData.setSingleAnswer(updatedAnswers);
    } else {
      stateData.setSingleAnswer([...stateData.singleAnswer, { qId, answer:answer }]);
    }
  };

  const handleRadioChangeTaskIdentifying = (qId: any, answer: any) => {
    const existingAnswerIndex = stateData.taskIdentifyingItemAnswers.findIndex(
      (answer : any) => answer.qId === qId
    );
    if (
      existingAnswerIndex !== -1 &&
      stateData.taskIdentifyingItemAnswers[existingAnswerIndex].answer ===
      answer
    ) {
      const updatedAnswers = [...stateData.taskIdentifyingItemAnswers];
      updatedAnswers[existingAnswerIndex].answer = null; // Set to null or an empty string as per your requirement
      stateData.setTaskIdentifyingItemAnswers(updatedAnswers);
      return; // Exit the function early since we've handled the deselection
    }
    if (existingAnswerIndex !== -1) {
      const updatedAnswers = [...stateData.taskIdentifyingItemAnswers];
      updatedAnswers[existingAnswerIndex].answer = answer;
      stateData.setTaskIdentifyingItemAnswers(updatedAnswers);
    } else {
      stateData.setTaskIdentifyingItemAnswers([
          ...stateData.taskIdentifyingItemAnswers,
          { qId, answer:answer },
        ]);
    }
  };

  const handleCheckBoxChange = (qId: any, answer: any, isChecked: any) => {
    const existingAnswerIndex = stateData.multipleAnswer.findIndex(
      (answer : any) => answer.qId === qId
    );
    if (existingAnswerIndex !== -1) {
      const updatedMultipleAnswers = [...stateData.multipleAnswer];
      const updatedOptionValue = Array.isArray(answer)
        ? answer
        : [answer];
      if (isChecked) {
        updatedMultipleAnswers[existingAnswerIndex].answer = [
          ...updatedMultipleAnswers[existingAnswerIndex].answer,
          ...updatedOptionValue,
        ];
      } else {
        updatedMultipleAnswers[existingAnswerIndex].answer =
          updatedMultipleAnswers[existingAnswerIndex].answer.filter(
            (value : any) => !updatedOptionValue.includes(value)
          );
      }
      stateData.setMultipleAnswer(updatedMultipleAnswers);
    } else {
      stateData.setMultipleAnswer([...stateData.multipleAnswer, { qId, answer: [answer] },])
    }
  };

  const handleChangeOneWordAnswer = (qId: any, answer: any) => {
    const existingAnswerIndex = stateData.noteCompletionAnswer.findIndex(
      (answer : any) => answer.qId === qId
    );
    if (existingAnswerIndex !== -1) {
      const updateOneWordAnswerNew = [...stateData.noteCompletionAnswer];
      updateOneWordAnswerNew[existingAnswerIndex].answer = answer;
      stateData.setNoteCompletionAnswer(updateOneWordAnswerNew);
    } else {
      stateData.setNoteCompletionAnswer([
        ...stateData.noteCompletionAnswer,
        { qId : qId, answer : answer },
      ]);
    }
  };

  const handleChangeOneWordAnswerSentenceCompletion = (
    qId: any,
    answer: any
  ) => {
    const existingAnswerIndex = stateData.senetenceComplete.findIndex(
      (answer : any) => answer.qId === qId
    );

    if (existingAnswerIndex !== -1) {
      const updateOneWordAnswerNew = [...stateData.senetenceComplete];
      updateOneWordAnswerNew[existingAnswerIndex].answer = answer;
      stateData.setSenetenceComplete(updateOneWordAnswerNew);
    } else {
      stateData.setSenetenceComplete([
        ...stateData.senetenceComplete,
        { qId, answer: answer },
      ]);
    }
  };

  const onWordAnswerInputValues = (qId: any) => {
    if (stateData.noteCompletionAnswer && stateData.noteCompletionAnswer.length > 0) {
      let object = stateData.noteCompletionAnswer.find((item: any) => item.qId === qId);
      if (object?.answer) {
        return object.answer;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const onWordAnswerInputValuesSentenceCompletion = (qId: any) => {
    if (stateData.senetenceComplete && stateData.senetenceComplete.length > 0) {
      let object = stateData.senetenceComplete.find(
        (item: any) => item.qId === qId
      );
      return object?.answer
    }
  };
  const twoWordAnswerSummaryCompletionInputValues = (qId: any) => {
    if (
      stateData.summaryCompletionOneWordAnswers &&
      stateData.summaryCompletionOneWordAnswers.length > 0
    ) {
      let object = stateData.summaryCompletionOneWordAnswers.find(
        (item: any) => item.qId === qId.toString()
      );
      return object?.answer;
    }
  };
  const handleDragStart = (e: any, displayedItem: any) => {
    const displayedItemString = displayedItem.answer;
    e.dataTransfer.setData("SummaryCompletiondisplayedItem", displayedItemString);
    e.dataTransfer.setData("answer", "");
  };

  const handleSentenceDragStart = (e: any, displayedItem: any) => {
    const displayedItemString = displayedItem.answer;
    e.dataTransfer.setData("SentenceEnding", displayedItemString);
    e.dataTransfer.setData("answer", "");
  };

  const handleDragStartFromAnswer = (e: any, answer: any) => {
    e.dataTransfer.setData("SummaryCompletiondisplayedItem", answer.toString());
    e.dataTransfer.setData("displayedItem", null);
  };
  const handleDropFromDisplayedItem = (e: any) => {
    const draggedItemString = e.dataTransfer.getData("SummaryCompletiondisplayedItem");
    if(draggedItemString !== ""){
      const updateAnswers = [...stateData.dragAnswers];
      const updateQuestions = [...stateData.dragAndDropAnswers];
      const findItemDragged = updateQuestions.find(
        (item) => item.answer === draggedItemString
      );
      const filteredQuestions = updateQuestions.filter(
        (item) => item.answer !== draggedItemString
      );
      findItemDragged?.answer && updateAnswers.push(findItemDragged?.answer);
      updateAnswers.length > 0 && stateData.setDragAnswers(updateAnswers);
      stateData.setDragAndDropAnswers(filteredQuestions);
    }
  };

  const handleDropSentenceFromDisplayedItem = (e: any) => {
    const draggedItemString = e.dataTransfer.getData("SentenceEnding");

    if(draggedItemString !== ""){

      const updateAnswers = [...stateData.dragSentenceAnswers];
      const updateQuestions = [...stateData.draggedCurrentSentence];
      const findItemDragged = updateQuestions.find(
        (item) => item.answer === draggedItemString
      );
      const filteredQuestions = updateQuestions.filter(
        (item) => item.answer !== draggedItemString
      );
      findItemDragged?.answer && updateAnswers.push(findItemDragged?.answer);
  
      stateData.setDragSentenceAnswers(updateAnswers);
      stateData.setDraggedCurrentSentence(filteredQuestions);

    }
  };

  const handleDrop = (e: any, qId: any) => {
    const draggedItemString = e.dataTransfer.getData("displayedItem"); // Get the string representation // This should log the string representation\
    let draggedItemObj;
    try {
      if (draggedItemString) {
        draggedItemObj = JSON.parse(draggedItemString); // Try to parse the string
      }
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
    
    const draggedAnswer = e.dataTransfer.getData("SummaryCompletiondisplayedItem");
    
    if(draggedAnswer !== ""){
      const existingAnswerIndex = stateData.dragAndDropAnswers.findIndex(
        (answer : any) => answer.qId === qId
      );
      if (existingAnswerIndex !== -1) {
        if (draggedAnswer) {
          
          let updateQuestyionsArray = [...stateData.dragAndDropAnswers];
          let previousQuestionId = updateQuestyionsArray.find((item) => item.answer ===  draggedAnswer)?.qId;
          if(previousQuestionId !== qId && draggedAnswer !== stateData.dragAndDropAnswers[existingAnswerIndex].answer){
            let updateAnswersArray = [...stateData.dragAnswers];
            updateAnswersArray.push(
              stateData.dragAndDropAnswers[existingAnswerIndex].answer
            );
            updateQuestyionsArray[existingAnswerIndex].answer = draggedAnswer;
            updateQuestyionsArray = updateQuestyionsArray.filter((item: any) => item.qId !== previousQuestionId)
            let finalUpdatedAnswers = updateAnswersArray.filter((item: any) => item !== draggedAnswer);
            finalUpdatedAnswers.length > 0 && stateData.setDragAnswers(finalUpdatedAnswers);
            stateData.setDragAndDropAnswers(updateQuestyionsArray);
          }  
        } else if(draggedItemObj?.answer !== undefined && draggedItemObj.answer !== null && draggedItemObj.answer !== ""){
            let answer = draggedItemObj.answer;
            let draggedIndex = draggedItemObj.qId;
            let newQuestionsArray = [...stateData.dragAndDropAnswers];
            let updateAnswersArray = [...stateData.dragAnswers];
            updateAnswersArray.push(
              newQuestionsArray[existingAnswerIndex].answer
            );
            newQuestionsArray[existingAnswerIndex].answer = answer;
      
            let fArray = newQuestionsArray.filter(
              (item: any) => item.qId !== draggedIndex
            );
            updateAnswersArray.length > 0 && stateData.setDragAnswers(updateAnswersArray);
            stateData.setDragAndDropAnswers(fArray);
          }
      } else {
        if (draggedAnswer) {
          let updateAnswersArray = [...stateData.dragAnswers].filter(
            (item: any) => item !== draggedAnswer
          );
          let answer = draggedAnswer;
          let updatedQuestionsArray = stateData.dragAndDropAnswers;
          updatedQuestionsArray = updatedQuestionsArray.filter((item: any) => item.answer !== answer);
          stateData.setDragAndDropAnswers([
            ...updatedQuestionsArray,
            { qId, answer:answer },
          ]);
  
          updateAnswersArray.length > 0 && stateData.setDragAnswers(updateAnswersArray);
        } else if(draggedItemObj?.answer !== undefined && draggedItemObj.answer !== null && draggedItemObj.answer !== ""){
            let answer = draggedItemObj.answer;
            let draggedIndex = draggedItemObj.qId;
    
            let newQuestionsArray = [...stateData.dragAndDropAnswers].filter(
              (item: any) => item.qId !== draggedIndex
            );
    
            stateData.setDragAndDropAnswers([
              ...newQuestionsArray,
              { qId, answer:answer },
            ]);
          }
      }
    }
  };
  const handleSentenceDrop = (e: any, qId: any) => {
    const draggedItemString = e.dataTransfer.getData("displayedItem"); // Get the string representation // This should log the string representation\
    let draggedItemObj;
    try {
      if (draggedItemString) {
        draggedItemObj = JSON.parse(draggedItemString); // Try to parse the string
      }
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
    const draggedAnswer = e.dataTransfer.getData("SentenceEnding");

    if(draggedAnswer !== ""){
      const existingAnswerIndex = stateData.draggedCurrentSentence.findIndex(
        (answer : any) => answer.qId === qId
      );
      if (existingAnswerIndex !== -1) {
        //In this blank answer already filled
        if (draggedAnswer) {

          let updateQuestyionsArray = [...stateData.draggedCurrentSentence];
          let previousQuestionId = updateQuestyionsArray.find((item) => item.answer ===  draggedAnswer)?.qId;
          if( previousQuestionId !== qId && draggedAnswer !== stateData.draggedCurrentSentence[existingAnswerIndex].answer){
            let updateAnswersArray = [...stateData.dragSentenceAnswers];
            updateAnswersArray.push(
              stateData.draggedCurrentSentence[existingAnswerIndex].answer
            );
            updateQuestyionsArray[existingAnswerIndex].answer = draggedAnswer;
            updateQuestyionsArray = updateQuestyionsArray.filter((item: any) => item.qId !== previousQuestionId)
            let finalUpdatedAnswers = updateAnswersArray.filter(
              (item: any) => item != draggedAnswer
            );
            stateData.setDragSentenceAnswers(finalUpdatedAnswers);
            stateData.setDraggedCurrentSentence(updateQuestyionsArray);
          }
        } else {
          if(draggedItemObj?.answer !== undefined && draggedItemObj.answer !== null && draggedItemObj.answer !== ""){
            let answer = draggedItemObj.answer;
            let draggedIndex = draggedItemObj.qId;
            let newQuestionsArray = [...stateData.draggedCurrentSentence];
            let updateAnswersArray = [...stateData.dragSentenceAnswers];
            updateAnswersArray.push(
              newQuestionsArray[existingAnswerIndex].answer
            );
            newQuestionsArray[existingAnswerIndex].answer = answer;
            let fArray = newQuestionsArray.filter(
              (item: any) => item.qId !== draggedIndex
            );
            stateData.setDragSentenceAnswers(updateAnswersArray);
            stateData.setDraggedCurrentSentence(fArray);
          }
        }
      } else {
        //Blnk is Empty
        if (draggedAnswer) {
          let updateAnswersArray = [...stateData.dragSentenceAnswers].filter(
            (item: any) => item !== draggedAnswer
          );
          let answer = draggedAnswer;
          let updatedQuestionsArray = stateData.draggedCurrentSentence;
          updatedQuestionsArray = updatedQuestionsArray.filter((item: any) => item.answer !== answer);
          stateData.setDraggedCurrentSentence([
            ...updatedQuestionsArray,
            { qId, answer:answer },
          ]);
          stateData.setDragSentenceAnswers(updateAnswersArray);
        } else {
          if(draggedItemObj?.answer !== undefined && draggedItemObj.answer !== null && draggedItemObj.answer !== ""){
            let answer = draggedItemObj.answer;
            let draggedIndex = draggedItemObj.qId;
            let newQuestionsArray = [...stateData.draggedCurrentSentence].filter(
              (item: any) => item.qId !== draggedIndex
            );
  
            stateData.setDraggedCurrentSentence([
              ...newQuestionsArray,
              { qId, answer:answer },
            ]);
          }  
        }
      }
    }

  };

  const handleChangeSummaryCompletionOneWordAnswers = (
    qId: any,
    answer: any
  ) => {
    const existingAnswerIndex = stateData.summaryCompletionOneWordAnswers.findIndex(
      (answer : any) => answer.qId === qId
    );
    if (existingAnswerIndex !== -1) {
      const updateOneWordAnswerNew = [...stateData.summaryCompletionOneWordAnswers];
      updateOneWordAnswerNew[existingAnswerIndex].answer = answer;
      stateData.setSummaryCompletionOneWordAnswers(updateOneWordAnswerNew);
    } else {
      stateData.setSummaryCompletionOneWordAnswers([
          ...stateData.summaryCompletionOneWordAnswers,
          { qId, answer:answer },
        ]);
    }
  };

  // Function to replace words with input fields

  // Function to replace {} with input fields
  const replaceWithInputFields = (
    text: string,
    indexOfSentence: any,
    qId: any
  ) => {
    const parts = text.split("{}"); // Split the text by {} to get parts around the {}
    let elements: JSX.Element[] = [];

    parts.forEach((part, index) => {
      elements.push(<span key={`text-${qId}`}>{part}</span>); // Add the text part
      if (index < parts.length - 1) {
        // Check if not the last part
        elements.push(
          <input
            key={`input-${qId}`}
            type="text"
            value={onWordAnswerInputValues(qId)}
            placeholder={`Fill in the blank ${qId}`}
            className="border border-[#E3E5E9] rounded-lg p-1 my-1"
            onChange={(e) => handleChangeOneWordAnswer(qId, e.target.value)}
          />
        ); // Add input field
      }
    });

    return elements;
  };

  const replaceWithInputFieldsSentenceCompletion = (
    text: string,
    indexOfSentence: any,
    qId: any
  ) => {
    
    const parts = text.split("{}"); // Split the text by {} to get parts around the {}
    let elements: JSX.Element[] = [];
    parts.forEach((part, index) => {
      elements.push(<span key={`text-${qId}`}>{part}</span>); // Add the text part
      if (index < parts.length - 1) {
        // Check if not the last part
        elements.push(
          <input
            key={`input-${qId}`}
            type="text"
            value={onWordAnswerInputValuesSentenceCompletion(qId)}
            placeholder={`Fill in the Sentence ${qId}`}
            className="border border-[#E3E5E9] rounded-lg p-1 my-1"
            onChange={(e) =>
              handleChangeOneWordAnswerSentenceCompletion(qId, e.target.value)
            }
          />
        ); // Add input field
      }
    });

    return elements;
  };

  const inputHandler = (e: any) => {
    let qId = e.target.id;
    let answer = e.target.value;
    const existingAnswerIndex = stateData.tableCompletionAnswer.findIndex(
      (answer : any) => answer.qId === qId
    );
    if (existingAnswerIndex !== -1) {
      const updateOneWordAnswerNew = [...stateData.tableCompletionAnswer];
      updateOneWordAnswerNew[existingAnswerIndex].answer = answer;
      stateData.setTableCompletionAnswer(updateOneWordAnswerNew);
    } else {
      stateData.setTableCompletionAnswer([...stateData.tableCompletionAnswer, { qId, answer }]);
    }
  };

  const handleDragStartMatchingHeader = (e : any,answer : any) => {
    e.dataTransfer.setData("matchingHeading", answer.toString());
  }

  const handlePassageHeadingDragStart = (e :any , heading : string )=>{ 
    e.dataTransfer.setData("passageHeading", heading);
  }  

  const handleDropMatchingHeading = (e : any) => {
    const draggedItemString = e.dataTransfer.getData("passageHeading");
    if(draggedItemString !== ""){
      let matchingHeadingOptionsArray = stateData.matchingHeadingOptions;
      matchingHeadingOptionsArray.push(draggedItemString);
      stateData.setMatchingHeadingOptions(matchingHeadingOptionsArray);
      let matchingHeadingArray = stateData.matchingHeadingAnswer;
      matchingHeadingArray = matchingHeadingArray.filter( (item : any) => item.answer !== draggedItemString);
      stateData.setMatchingHeadingAnswer(matchingHeadingArray);
    }
  }

  const handleMatchingheadingDrop = (e : any,questionId : any) => {
   
    const draggedItemString = e.dataTransfer.getData("matchingHeading");
    const draggedItem = e.dataTransfer.getData("passageHeading");



    if(draggedItemString !== ""){
      let matchingHeadingArray = stateData.matchingHeadingAnswer;
      let matchingHeadingAnswerIndex = matchingHeadingArray.findIndex((item : any) => item.qId === questionId);

  
      if(matchingHeadingAnswerIndex !== -1){
        let oldMatchingHeadingOption = matchingHeadingArray[matchingHeadingAnswerIndex].answer;

        matchingHeadingArray[matchingHeadingAnswerIndex].answer = draggedItemString;
        stateData.setMatchingHeadingAnswer(matchingHeadingArray);
        let matchingHeadingOptionsArray = stateData.matchingHeadingOptions;
        matchingHeadingOptionsArray = matchingHeadingOptionsArray.filter( (item : any) => item !== draggedItemString);
        matchingHeadingOptionsArray.push(oldMatchingHeadingOption);
        stateData.setMatchingHeadingOptions(matchingHeadingOptionsArray);

      }else if(matchingHeadingAnswerIndex === -1){
        let matchingHeadingAnswer = {
          qId : questionId,
          answer : draggedItemString
        }
  
        matchingHeadingArray.push(matchingHeadingAnswer);
        stateData.setMatchingHeadingAnswer(matchingHeadingArray);
        let matchingHeadingOptionsArray = stateData.matchingHeadingOptions;
        matchingHeadingOptionsArray = matchingHeadingOptionsArray.filter( (item : any) => item !== draggedItemString);
        stateData.setMatchingHeadingOptions(matchingHeadingOptionsArray);
      }
    } else if(draggedItem !== "") {

      let matchingHeadingArray = stateData.matchingHeadingAnswer;
      let matchingAnswerIndex = matchingHeadingArray.findIndex((item : any) => item.qId === questionId);

      if(matchingAnswerIndex !== -1){
        let matchingHeadingOption = matchingHeadingArray[matchingAnswerIndex].answer;
        let matchingHeadingOptionsArray = stateData.matchingHeadingOptions;
        matchingHeadingOptionsArray.push(matchingHeadingOption);
        stateData.setMatchingHeadingOptions(matchingHeadingOptionsArray);
      }

      let matchingItemIndex = matchingHeadingArray.findIndex((item : any) => item.answer === draggedItem);

      if(matchingItemIndex !== -1){
        matchingHeadingArray[matchingItemIndex].qId=questionId;
      }

      stateData.setMatchingHeadingAnswer(matchingHeadingArray);
    }
  }

  const handleMatchingFeature = (qId:any,optionaValue:any) => {
    const index:any = stateData.matchingFeatureAnswers.findIndex( (itm: any) => itm.qId === qId)
    let updatedArray = [];
    if (index !== -1) {
      updatedArray = [...stateData.matchingFeatureAnswers];
      updatedArray[index].answer = optionaValue;
    } else {
      updatedArray =  [...stateData.matchingFeatureAnswers, { qId: qId, answer: optionaValue }];
    }
    stateData.setMatchingFeatures(updatedArray);
  };

  const titleExtractor = (part : string) => {
    if (part === 'part1') {
      return "Part 1";
    } else if (part === 'part2') {
      return "Part 2";
    } else if (part === 'part3') {
      return "Part 3";
    }
  }
  const partTypeExtractor = (part : string) => {
    if (part === 'part1') {
      return "Part1";
    } else if (part === 'part2') {
      return "Part2";
    } else if (part === 'part3') {
      return "Part3";
    }
  }

  useEffect(() => {
    store.setPartTypeReading(partType)
  },[])

  return (
    <div>
      <PartTestLayoutReading
        buttonTitle={store.testType != "full" ? "Submit" : "Next"}
        partType={partTypeExtractor(partType)}
        nextStep={nextStep}
        title={`Academic Reading ${titleExtractor(partType)}`}
        description={""}
      >
        <div className="flex border h-full border-[#E3E5E9] p-4 m-6 bg-[#FFF] rounded-lg">
          <div className="w-1/2 border-[#D9D9D9] pr-4 text-justify max-h-[53vh] overflow-y-scroll">
            {renderParagraph(paragraph?.content)}
          </div>
          <div className="w-1/2 flex flex-col pl-5 border-l-2">
            <div className="flex flex-col">
              {questions && questions.length > 0 &&
                questions.map((item: any, index: any) => (
                  <React.Fragment key={item["question-refrence-id"]}>
                    <div className="text-[#3C424C] text-lg font-semibold">
                      Questions {item["question-range"]}
                    </div>
                    <RenderQuestions
                      item={item}
                      singleAnswers={stateData.singleAnswer}
                      multipleAnswers={stateData.multipleAnswer}
                      tableCompletionAnswer={stateData.tableCompletionAnswer}
                      taskIdentifyingItemAnswers={stateData.taskIdentifyingItemAnswers}
                      handleRadioChange={handleRadioChange}
                      handleCheckBoxChange={handleCheckBoxChange}
                      replaceWithInputFields={replaceWithInputFields}
                      twoWordAnswerSummaryCompletionInputValues={
                        twoWordAnswerSummaryCompletionInputValues
                      }
                      handleChangeSummaryCompletionOneWordAnswers={
                        handleChangeSummaryCompletionOneWordAnswers
                      }
                      handleDragOver={handleDragOver}
                      handleDragStart={handleDragStart}
                      handleDrop={handleDrop}
                      handleDragStartFromAnswer={handleDragStartFromAnswer}
                      matchingHeadingAnswers={stateData.matchingHeadingOptions}
                      setMatchingHeadingAnswers={stateData.matchingHeadingOptions}
                      handleDragStartMatchingHeader={handleDragStartMatchingHeader}
                      handleDropMatchingHeading={handleDropMatchingHeading}
                      handleMatchingFeature={handleMatchingFeature}
                      MatchingFeatureAnswers={stateData.matchingFeatureAnswers}
                      handleDropFromDisplayedItem={handleDropFromDisplayedItem}
                      darggedCurrentItem={stateData.dragAndDropAnswers}
                      darggedCurrentSentence={stateData.draggedCurrentSentence}
                      setDarggedCurrentSentence={stateData.setDraggedCurrentSentence}
                      dragAnswers={stateData.dragAnswers}
                      setDragAnswers={stateData.setDragAnswers}
                      handleRadioChangeTaskIdentifying={handleRadioChangeTaskIdentifying}
                      replaceWithInputFieldsSentenceCompletion={
                        replaceWithInputFieldsSentenceCompletion
                      }
                      setDragSentenceAnswers={stateData.setDragSentenceAnswers}
                      handleSentenceDragStart={handleSentenceDragStart}
                      handleDragSentenceStartFromAnswer={handleDragSentenceStartFromAnswer}
                      handleDropSentenceFromDisplayedItem={handleDropSentenceFromDisplayedItem}
                      handleSentenceDrop={handleSentenceDrop}
                      dragSentenceAnswers={stateData.dragSentenceAnswers}
                      inputHandler={inputHandler}
                    />
                  </React.Fragment>
                ))}
            </div>
          </div>
        </div>
      </PartTestLayoutReading>
    </div>
  );
});

export default ReadingPartTest;
