import { observer } from "mobx-react-lite";
import React from "react";

const NoteCompletion = ({ item, replaceWithInputFields }: any) => {

  return (
    <>
      <div className="text-[#3C424C] text-sm">
        {" "}
        Complete the notes. Write{" "}
        <span className="font-semibold">ONE WORD AND/OR A NUMBER</span> in each field
      </div>
      <div className="text-[#3C424C] text-lg font-semibold mt-2" >
        {item["questionDescription"]}
      </div>
      {item["listOfQuestions"].length > 0 &&
        item["listOfQuestions"].map((question: any, index: any) => {
          return (
            <div key={question.questionId} className="text-[#3C424C] text-base text-justify">
              <li>
                {replaceWithInputFields(question.questionName, index, question.questionId)}
              </li>
            </div>
          );
        })}
    </>
  );
};

export default observer(NoteCompletion);
