import { makePersistable } from 'mobx-persist-store';
import { makeAutoObservable } from "mobx";
import { StartTestReadingApi } from '../api/start-test-reading-api';

type multipleAnswers = {
    qId: number; // Assuming questionId is always a number
    answer: any[];
}
type noteCompletionAnsewrs = {
    qId: number;
    answer: any;
};
type summaryCompletionOneWordAnswers = {
    qId: number;
    answer: any;
};
type dragAndDropAnswers = {
    qId: any;
    answer: any;
};
type taskIdentifying = {
    qId: number;
    answer: any;
};
type SenetenceComplete = {
    qId: number;
    answer: any;
};
type matchingSentenceEnd = {
    qId: any;
    answer: any;
};
type tableCompletion = {
    qId: number; // Assuming questionId is always a number
    answer: any[];
};

interface ResultData {
    detail: string;
}

export class StartTestStoreReading {
    statusCode:any;
    readingErrorMessage:any;
    testId = null;
    testType = null;
    partType = null;
    freeTestData = [];
    part1Questions: any = {};
    part2Questions: any = {};
    part3Questions: any = {};
    timer: any = 3600;
    isTestEnded:any = null;
    storeName:any = "reading";
    isLoading: boolean = false;
    tryAgainButton:boolean=false;
    part1Answers:any =[];
    part2Answers:any =[];
    part3Answers:any =[];
    part1SingleAnswer:any = [];
    part1MultipleAnswer:multipleAnswers[] = [];
    part1NoteCompletionAnswer:noteCompletionAnsewrs[] = []
    part1SummaryCompletionOneWordAnswers:summaryCompletionOneWordAnswers[] = [];
    part1DragAndDropAnswers:dragAndDropAnswers[] = [];
    part1TaskIdentifyingItemAnswers:taskIdentifying[] = [];
    part1SenetenceComplete:SenetenceComplete[] = [];
    part1DraggedCurrentSentence:dragAndDropAnswers[] = [];
    part1MatchingSentenceEndAnswers:matchingSentenceEnd[] = [];
    part1TableCompletionAnswer:tableCompletion[] = [];
    part1DragAnswers: any = [];
    part1DragSentenceAnswers: any = [];
    part1MatchingHeadingOptions: any = [];
    part1DraggedMatchingHeading: any = [];
    part1MatchingHeadingAnswer : any = [];
    part1MatchingFeatureAnswers:any = [];
    part2SingleAnswer:any = [];
    part2MultipleAnswer:multipleAnswers[] = [];
    part2NoteCompletionAnswer:noteCompletionAnsewrs[] = []
    part2SummaryCompletionOneWordAnswers:summaryCompletionOneWordAnswers[] = [];
    part2DragAndDropAnswers:dragAndDropAnswers[] = [];
    part2TaskIdentifyingItemAnswers:taskIdentifying[] = [];
    part2SenetenceComplete:SenetenceComplete[] = [];
    part2DraggedCurrentSentence:dragAndDropAnswers[] = [];
    part2MatchingSentenceEndAnswers:matchingSentenceEnd[] = [];
    part2TableCompletionAnswer:tableCompletion[] = [];
    part2DragAnswers: any = [];
    part2DragSentenceAnswers: any = [];
    part2MatchingHeadingOptions: any = [];
    part2DraggedMatchingHeading: any = [];
    part2MatchingHeadingAnswer : any = [];
    part2MatchingFeatureAnswers:any =[];
    part3SingleAnswer:any = [];
    part3MultipleAnswer:multipleAnswers[] = [];
    part3NoteCompletionAnswer:noteCompletionAnsewrs[] = []
    part3SummaryCompletionOneWordAnswers:summaryCompletionOneWordAnswers[] = [];
    part3DragAndDropAnswers:dragAndDropAnswers[] = [];
    part3TaskIdentifyingItemAnswers:taskIdentifying[] = [];
    part3SenetenceComplete:SenetenceComplete[] = [];
    part3DraggedCurrentSentence:dragAndDropAnswers[] = [];
    part3MatchingSentenceEndAnswers:matchingSentenceEnd[] = [];
    part3TableCompletionAnswer:tableCompletion[] = [];
    part3DragAnswers: any = [];
    part3DragSentenceAnswers: any = [];
    part3MatchingHeadingOptions: any = [];
    part3DraggedMatchingHeading: any = [];
    part3MatchingHeadingAnswer : any = [];
    part3MatchingFeatureAnswers:any =[];
    isFreeTestLoading:boolean =false

    //answersheets from Be
     answerSheet:any = []
     part1AnswerSheet:any =[]
     part2AnswerSheet:any = []
     part3AnswerSheet:any = []

    //Overall Scores
    partOneScore:any = null;
    partTwoScore:any = null;
    partThreeScore:any =null;
    overallScore:any = null;

    completeTestFailed:boolean = false;


    constructor(private startTestReadingAPi: StartTestReadingApi) {
        makeAutoObservable(this)
        makePersistable(this, { name: "StartReadingTestStore", properties: ['testType','tryAgainButton','completeTestFailed','testId','readingErrorMessage'], storage: window.localStorage });
        this.setPart1NoteCompletionAnswer = this.setPart1NoteCompletionAnswer.bind(this);
        this.setPart2NoteCompletionAnswer = this.setPart2NoteCompletionAnswer.bind(this);
        this.setPart3NoteCompletionAnswer = this.setPart3NoteCompletionAnswer.bind(this);
        this.setPart1SingleAnswer = this.setPart1SingleAnswer.bind(this);
        this.setPart2SingleAnswer = this.setPart2SingleAnswer.bind(this);
        this.setPart3SingleAnswer = this.setPart3SingleAnswer.bind(this);
        this.setPart1MultipleAnswer = this.setPart1MultipleAnswer.bind(this);
        this.setPart2MultipleAnswer = this.setPart2MultipleAnswer.bind(this);
        this.setPart3MultipleAnswer = this.setPart3MultipleAnswer.bind(this);
        this.setPart1DragAnswers = this.setPart1DragAnswers.bind(this);
        this.setPart2DragAnswers = this.setPart2DragAnswers.bind(this);
        this.setPart3DragAnswers = this.setPart3DragAnswers.bind(this);
        this.setPart1DragAndDropAnswers = this.setPart1DragAndDropAnswers.bind(this);
        this.setPart2DragAndDropAnswers = this.setPart2DragAndDropAnswers.bind(this);
        this.setPart3DragAndDropAnswers = this.setPart3DragAndDropAnswers.bind(this);
        this.setPart1DraggedCurrentSentence = this.setPart1DraggedCurrentSentence.bind(this);
        this.setPart2DraggedCurrentSentence = this.setPart2DraggedCurrentSentence.bind(this);
        this.setPart3DraggedCurrentSentence = this.setPart3DraggedCurrentSentence.bind(this);
        this.setPart1DragSentenceAnswers = this.setPart1DragSentenceAnswers.bind(this);
        this.setPart2DragSentenceAnswers = this.setPart2DragSentenceAnswers.bind(this);
        this.setPart3DragSentenceAnswers = this.setPart3DragSentenceAnswers.bind(this);
        this.setPart1MatchingHeadingAnswer = this.setPart1MatchingHeadingAnswer.bind(this);
        this.setPart2MatchingHeadingAnswer = this.setPart2MatchingHeadingAnswer.bind(this);
        this.setPart3MatchingHeadingAnswer = this.setPart3MatchingHeadingAnswer.bind(this);
        this.setPart1MatchingHeadingOptions = this.setPart1MatchingHeadingOptions.bind(this);
        this.setPart2MatchingHeadingOptions = this.setPart2MatchingHeadingOptions.bind(this);
        this.setPart3MatchingHeadingOptions = this.setPart3MatchingHeadingOptions.bind(this);
        this.setPart1DraggedMatchingHeading = this.setPart1DraggedMatchingHeading.bind(this);
        this.setPart2DraggedMatchingHeading = this.setPart2DraggedMatchingHeading.bind(this);
        this.setPart3DraggedMatchingHeading = this.setPart3DraggedMatchingHeading.bind(this);
        this.setPart1MatchingFeatures = this.setPart1MatchingFeatures.bind(this);
        this.setPart2MatchingFeatures = this.setPart2MatchingFeatures.bind(this);
        this.setPart3MatchingFeatures = this.setPart3MatchingFeatures.bind(this);
        this.setPart1TaskIdentifyingItemAnswers = this.setPart1TaskIdentifyingItemAnswers.bind(this);
        this.setPart2TaskIdentifyingItemAnswers = this.setPart2TaskIdentifyingItemAnswers.bind(this);
        this.setPart3TaskIdentifyingItemAnswers = this.setPart3TaskIdentifyingItemAnswers.bind(this);
        this.setPart1SenetenceComplete = this.setPart1SenetenceComplete.bind(this);
        this.setPart2SenetenceComplete = this.setPart2SenetenceComplete.bind(this);
        this.setPart3SenetenceComplete = this.setPart3SenetenceComplete.bind(this);
        this.setPart1SummaryCompletionOneWordAnswers = this.setPart1SummaryCompletionOneWordAnswers.bind(this);
        this.setPart2SummaryCompletionOneWordAnswers = this.setPart2SummaryCompletionOneWordAnswers.bind(this);
        this.setPart3SummaryCompletionOneWordAnswers = this.setPart3SummaryCompletionOneWordAnswers.bind(this);
        this.setPart1TableCompletionAnswer = this.setPart1TableCompletionAnswer.bind(this);
        this.setPart2TableCompletionAnswer = this.setPart2TableCompletionAnswer.bind(this);
        this.setPart3TableCompletionAnswer = this.setPart3TableCompletionAnswer.bind(this);
        this.setPart1AllAnswers = this.setPart1AllAnswers.bind(this);
        this.setPart2AllAnswers = this.setPart2AllAnswers.bind(this);
        this.setPart3AllAnswers = this.setPart3AllAnswers.bind(this);
        this.setTimer = this.setTimer.bind(this)
        this.setPartOneScore = this.setPartOneScore.bind(this);
        this.setPartTwoScore = this.setPartTwoScore.bind(this);
        this.setPartThreeScore = this.setPartThreeScore.bind(this);
        this.setPart1Questions = this.setPart1Questions.bind(this);
        this.setPart2Questions = this.setPart2Questions.bind(this);
        this.setPart3Questions = this.setPart3Questions.bind(this);
    }

    async setPart1Questions (questions: any) {
        this.part1Questions = questions;
    }

    async setPart2Questions (questions: any) {
        this.part2Questions = questions;
    }

    async setPart3Questions (questions: any){
        this.part3Questions = questions;
    }

    async setTimer(time: any) {
        this.timer = time;
    }
    async setTestIdReading(testId: any) {
        this.testId = testId;
    }

    async setTestTypeReading(typeTest: any) {
        this.testType = typeTest

    }
async setLoading(bool:boolean){
    this.isLoading = bool
}
    async setPartTypeReading(partType: any) {
        this.partType = partType;
    }
    
    async setPart1SingleAnswer(answer: any) {
        this.part1SingleAnswer = answer;
    }

    async setPart1MultipleAnswer(multipleAnswer: any){
        this.part1MultipleAnswer = multipleAnswer;
    }

    async setPart1NoteCompletionAnswer(noteCompletionAnswer : any){
        this.part1NoteCompletionAnswer = noteCompletionAnswer;
    }

    async setPart1SummaryCompletionOneWordAnswers(summaryCompletionOneWordAnswers : any){
        this.part1SummaryCompletionOneWordAnswers = summaryCompletionOneWordAnswers;
    }

    async setPart1DragAndDropAnswers(dragAndDropAnswers : any){
        this.part1DragAndDropAnswers = dragAndDropAnswers 
    } 

    async setPart1TaskIdentifyingItemAnswers(taskIdentifyingItemAnswers : any){
        this.part1TaskIdentifyingItemAnswers = taskIdentifyingItemAnswers;
    }

    async setPart1SenetenceComplete(senetenceComplete : any){
        this.part1SenetenceComplete = senetenceComplete;
    }

    async setPart1DraggedCurrentSentence(draggedCurrentSentence : any){
        this.part1DraggedCurrentSentence = draggedCurrentSentence;
    }

    async setPart1MatchingSentenceEndAnswers(matchingSentenceEndAnswers : any){
        this.part1MatchingSentenceEndAnswers = matchingSentenceEndAnswers;
    }

    async setPart1TableCompletionAnswer(tableCompletionAnswer: any){
        this.part1TableCompletionAnswer = tableCompletionAnswer;
    } 

    async setPart1DragAnswers(dragAnswer : any){
        this.part1DragAnswers = dragAnswer;
    }

    async setPart1DragSentenceAnswers(dragSentenceAnswers : any){
        this.part1DragSentenceAnswers = dragSentenceAnswers;
    }

    async setPart1MatchingHeadingOptions(matchingHeadings : any){
        this.part1MatchingHeadingOptions = matchingHeadings;
    }

    async setPart1DraggedMatchingHeading(draggedMatchingHeading : string) {
        this.part1DraggedMatchingHeading = draggedMatchingHeading
    }

    async setPart1MatchingHeadingAnswer(headingAnswer : string) {
        this.part1MatchingHeadingAnswer = headingAnswer;
    }

    async setPart1MatchingFeatures(matchingFeaturesAnswers: any){
        this.part1MatchingFeatureAnswers = matchingFeaturesAnswers;
    }

    async setPart2SingleAnswer(answer: any) {
        this.part2SingleAnswer = answer;
    }

    async setPart2MultipleAnswer(multipleAnswer: any){
        this.part2MultipleAnswer = multipleAnswer;
    }

    async setPart2NoteCompletionAnswer(noteCompletionAnswer : any){
        this.part2NoteCompletionAnswer = noteCompletionAnswer;
    }

    async setPart2SummaryCompletionOneWordAnswers(summaryCompletionOneWordAnswers : any){
        this.part2SummaryCompletionOneWordAnswers = summaryCompletionOneWordAnswers;
    }

    async setPart2DragAndDropAnswers(dragAndDropAnswers : any){
        this.part2DragAndDropAnswers = dragAndDropAnswers 
    } 

    async setPart2TaskIdentifyingItemAnswers(taskIdentifyingItemAnswers : any){
        this.part2TaskIdentifyingItemAnswers = taskIdentifyingItemAnswers;
    }

    async setPart2SenetenceComplete(senetenceComplete : any){
        this.part2SenetenceComplete = senetenceComplete;
    }

    async setPart2DraggedCurrentSentence(draggedCurrentSentence : any){
        this.part2DraggedCurrentSentence = draggedCurrentSentence;
    }

    async setPart2MatchingSentenceEndAnswers(matchingSentenceEndAnswers : any){
        this.part2MatchingSentenceEndAnswers = matchingSentenceEndAnswers;
    }

    async setPart2TableCompletionAnswer(tableCompletionAnswer: any){
        this.part2TableCompletionAnswer = tableCompletionAnswer;
    } 

    async setPart2DragAnswers(dragAnswer : any){
        this.part2DragAnswers = dragAnswer;
    }

    async setPart2DragSentenceAnswers(dragSentenceAnswers : any){
        this.part2DragSentenceAnswers = dragSentenceAnswers;
    }

    async setPart2MatchingHeadingOptions(matchingHeadings : any){
        this.part2MatchingHeadingOptions = matchingHeadings;
    }

    async setPart2DraggedMatchingHeading(draggedMatchingHeading : string) {
        this.part2DraggedMatchingHeading = draggedMatchingHeading
    }

    async setPart2MatchingHeadingAnswer(headingAnswer : string) {
        this.part2MatchingHeadingAnswer = headingAnswer;
    }

    async setPart2MatchingFeatures(matchingFeaturesAnswers: any){
        this.part2MatchingFeatureAnswers = matchingFeaturesAnswers;
    }

    async setPart3SingleAnswer(answer: any) {
        this.part3SingleAnswer = answer;
    }

    async setPart3MultipleAnswer(multipleAnswer: any){
        this.part3MultipleAnswer = multipleAnswer;
    }

    async setPart3NoteCompletionAnswer(noteCompletionAnswer : any){
        this.part3NoteCompletionAnswer = noteCompletionAnswer;
    }

    async setPart3SummaryCompletionOneWordAnswers(summaryCompletionOneWordAnswers : any){
        this.part3SummaryCompletionOneWordAnswers = summaryCompletionOneWordAnswers;
    }

    async setPart3DragAndDropAnswers(dragAndDropAnswers : any){
        this.part3DragAndDropAnswers = dragAndDropAnswers 
    } 

    async setPart3TaskIdentifyingItemAnswers(taskIdentifyingItemAnswers : any){
        this.part3TaskIdentifyingItemAnswers = taskIdentifyingItemAnswers;
    }

    async setPart3SenetenceComplete(senetenceComplete : any){
        this.part3SenetenceComplete = senetenceComplete;
    }

    async setPart3DraggedCurrentSentence(draggedCurrentSentence : any){
        this.part3DraggedCurrentSentence = draggedCurrentSentence;
    }

    async setPart3MatchingSentenceEndAnswers(matchingSentenceEndAnswers : any){
        this.part3MatchingSentenceEndAnswers = matchingSentenceEndAnswers;
    }

    async setPart3TableCompletionAnswer(tableCompletionAnswer: any){
        this.part3TableCompletionAnswer = tableCompletionAnswer;
    } 

    async setPart3DragAnswers(dragAnswer : any){
        this.part3DragAnswers = dragAnswer;
    } 

    async setPart3DragSentenceAnswers(dragSentenceAnswers : any){
        this.part3DragSentenceAnswers = dragSentenceAnswers;
    }

    async setPart3MatchingHeadingOptions(matchingHeadings : any){
        this.part3MatchingHeadingOptions = matchingHeadings;
    }

    async setPart3DraggedMatchingHeading(draggedMatchingHeading : string) {
        this.part3DraggedMatchingHeading = draggedMatchingHeading
    }

    async setPart3MatchingHeadingAnswer(headingAnswer : string) {
        this.part3MatchingHeadingAnswer = headingAnswer;
    }

    async setPart3MatchingFeatures(matchingFeaturesAnswers: any){
        this.part3MatchingFeatureAnswers = matchingFeaturesAnswers;
    }

    async getFreeTestDataReading() {
        try {
            this.isLoading = true;
            this.isFreeTestLoading = true
            let freeTests = await this.startTestReadingAPi.fetchFreeTestDataReading()
            if (freeTests.status == 200) {
                this.freeTestData = freeTests.data
                this.isLoading = false;
                this.tryAgainButton = false;
                this.isFreeTestLoading = false
              } else {
                this.isLoading = false;
                this.tryAgainButton = true;
                this.isFreeTestLoading = false
              }
        } catch (e: any) {
            this.tryAgainButton = true;
            this.isFreeTestLoading = false
        }
    }

    async generateTestReading(typeTest: any) {
        try {
            this.isLoading = true;
            let res = await this.startTestReadingAPi.generateTestReading(typeTest);
              if (res.status == 200) {
                this.testId = res.data.test_id;
                this.startFreeTestReading(typeTest)
              }else if(res.data.istestcompleted){                              
                this.isLoading = false;
                this.tryAgainButton = true;
                this.statusCode = res.status;
                this.readingErrorMessage = res.data.reason;
              } else {
                this.isLoading = false;
                this.tryAgainButton = true;
                this.statusCode = res.status;
                this.readingErrorMessage = res.data.detail;
              }
            return res
        } catch (e) {
            console.log(e, "generateTest end point failed");
            this.isLoading = false;
            this.tryAgainButton = true;
        }
    }


    async startFreeTestReading(typeTst: any) {
        let result : any;
        try{
            let result = await this.startTestReadingAPi.startFreeTestReading(this.testId);
        if (result?.status === 200) {
            let payload = {
                id: this.testId,
                type: typeTst,
            };
            let res3 : any = await this.startTestReadingAPi.getTestReading(payload);
            if (res3?.status === 200) {
                if (res3?.data?.type === 'part1') {
                    if(res3?.data?.data){
                        this.part1Questions = res3?.data?.data;
                    }else{
                        this.isLoading = false;
                        this.tryAgainButton = true;
                        this.statusCode = res3.status;
                        this.readingErrorMessage = res3.data.detail;
                    }  
                } else if (res3?.data?.type === 'part2') {
                    if(res3?.data?.data){
                        this.part2Questions = res3?.data?.data;
                    }else{
                        this.isLoading = false;
                        this.tryAgainButton = true;
                        this.statusCode = res3.status;
                        this.readingErrorMessage = res3.data.detail;
                    }
                } else if (res3?.data?.type === 'part3') {
                    if(res3?.data?.data){
                        this.part3Questions = res3?.data?.data;
                    }else{
                        this.isLoading = false;
                        this.tryAgainButton = true;
                        this.statusCode = res3.status;
                        this.readingErrorMessage = res3.data.detail;
                    }
                }else if(res3?.data?.type === 'full'){
                    if(res3?.data?.data?.partOneQuestion && res3?.data?.data?.partTwoQuestion && res3?.data?.data?.partThreeQuestion){
                        this.part1Questions = res3?.data?.data?.partOneQuestion;
                        this.part2Questions = res3?.data?.data?.partTwoQuestion;
                        this.part3Questions = res3?.data?.data?.partThreeQuestion;
                    }else{
                        this.isLoading = false;
                        this.tryAgainButton = true;
                        this.statusCode = res3.status;
                        this.readingErrorMessage = res3.data.detail;
                    }
                }
                this.isLoading = false;
                this.tryAgainButton = false;
            } else {
                this.isLoading = false;
                this.tryAgainButton = true;
                this.statusCode = res3.status;
                this.readingErrorMessage = res3.data.detail;
            }
        } else {
            this.isLoading = false;
            this.tryAgainButton = true;
            this.statusCode =  result?.status;
            this.readingErrorMessage = (result?.data as ResultData)?.detail?? '';
        }
        }catch(err){
            console.error(err)
            this.isLoading = false;
            this.tryAgainButton = true;
            this.statusCode = result?.status;
            this.readingErrorMessage = (result?.data as ResultData)?.detail?? ''; 
        }
        
    }

    async setCompleteTestFailed(value : any){
        this.completeTestFailed = value;
    }

    async setPart1AllAnswers(answers:any){
        this.part1Answers = answers
    }
    async setPart2AllAnswers(answers:any){
        this.part2Answers = answers
    }
   async  setPart3AllAnswers(answers:any){
        this.part3Answers = answers
    }

    async setPartOneScore(score:any) {
        this.partOneScore = score
    }
  
    async setPartTwoScore(score:any) {
        this.partTwoScore = score
    }

    async setPartThreeScore(score:any) {
        this.partThreeScore = score
    }

    async setIsTestEnded(value:any){
        this.isTestEnded = value;
    }
    
    async submitAnswerReading() {
        this.isTestEnded = true;
        try{
            if (this.partType == 'part3') {
                this.isTestEnded = true;
            }
            let payload;
            if (this.testType == 'full') {
                payload = {
                    testId: this.testId,
                    userAnswers: [...this.part1Answers, ...this.part2Answers, ...this.part3Answers]
                }
            } else if (this.partType == 'part1') {
                payload = {
                    testId: this.testId,
                    userAnswers: this.part1Answers
                }
            } else if (this.partType == 'part2') {
                payload = {
                    testId: this.testId,
                    userAnswers: this.part2Answers
                }
            } else {
                payload = {
                    testId: this.testId,
                    userAnswers: this.part3Answers
                }
            }
           
            const res:any = await this.startTestReadingAPi.submitAnswerApiReading(payload);
            if(res?.status === 200){
               //enable after be is done for submit answer end point
               if( res?.data?.type == 'full'){
                this.part1AnswerSheet = res.data.answers_sheets.partOneAnswerSheet;
                this.part2AnswerSheet = res.data.answers_sheets.partTwoAnswerSheet;
                this.part3AnswerSheet = res.data.answers_sheets.partThreeAnswerSheet;
                this.answerSheet = [...res.data.answers_sheets.partOneAnswerSheet,
                    ...res.data.answers_sheets.partTwoAnswerSheet,
                    ...res.data.answers_sheets.partThreeAnswerSheet
                ]     
               }else if(res.status.testType == 'part1'){
                this.part1AnswerSheet = res.data.answers_sheets.partOneAnswerSheet;
                
               }else if(res.status.testType == 'part2'){
                this.part2AnswerSheet = res.data.answers_sheets.partTwoAnswerSheet;
                
               }else if(res.status.testType == 'part3'){
                this.part3AnswerSheet = res.data.answers_sheets.partThreeAnswerSheet;
                
               }
            }else{
                this.isTestEnded = false;
                this.completeTestFailed = true;
            }
            return res

        }catch(e){
            this.isTestEnded = false;
            this.completeTestFailed = true;
            console.error(e)
        }
       
      }

      async completeTest(payload:any){
        try{
            let response = await this.startTestReadingAPi.endTestReading(payload);
           if( response?.status == 200){
            this.isTestEnded = null
            this.completeTestFailed = false;
            //return response.data.message
           } else {
            this.isTestEnded = false;
            this.completeTestFailed = true;
           }
           
        }catch(e){
            this.isTestEnded = false;
            this.completeTestFailed = true;
            console.log(e)
        }
       
    
      }
    
    async clearAllAnswers(){
        this.part1Answers = [];
        this.part3Answers = [];
        this.part1SingleAnswer = [];
        this.part2Answers = [];
        this.part1MultipleAnswer = [];
        this.part1NoteCompletionAnswer = []
        this.part1SummaryCompletionOneWordAnswers = [];
        this.part1DragAndDropAnswers = [];
        this.part1TaskIdentifyingItemAnswers = [];
        this.part1SenetenceComplete = [];
        this.part1DraggedCurrentSentence = [];
        this.part1MatchingSentenceEndAnswers = [];
        this.part1TableCompletionAnswer = [];
        this.part1DragAnswers = [];
        this.part1DragSentenceAnswers = [];
        this.part1MatchingHeadingOptions = [];
        this.part1DraggedMatchingHeading = [];
        this.part1MatchingHeadingAnswer = [];
        this.part1MatchingFeatureAnswers =[];
        this.part2SingleAnswer = [];
        this.part2MultipleAnswer = [];
        this.part2NoteCompletionAnswer = []
        this.part2SummaryCompletionOneWordAnswers = [];
        this.part2DragAndDropAnswers = [];
        this.part2TaskIdentifyingItemAnswers = [];
        this.part2SenetenceComplete = [];
        this.part2DraggedCurrentSentence = [];
        this.part2MatchingSentenceEndAnswers = [];
        this.part2TableCompletionAnswer = [];
        this.part2DragAnswers = [];
        this.part2DragSentenceAnswers = [];
        this.part2MatchingHeadingOptions = [];
        this.part2DraggedMatchingHeading = [];
        this.part2MatchingHeadingAnswer = [];
        this.part2MatchingFeatureAnswers =[];
        this.part3SingleAnswer = [];
        this.part3MultipleAnswer = [];
        this.part3NoteCompletionAnswer = []
        this.part3SummaryCompletionOneWordAnswers = [];
        this.part3DragAndDropAnswers = [];
        this.part3TaskIdentifyingItemAnswers = [];
        this.part3SenetenceComplete = [];
        this.part3DraggedCurrentSentence = [];
        this.part3MatchingSentenceEndAnswers = [];
        this.part3TableCompletionAnswer = [];
        this.part3DragAnswers = [];
        this.part3DragSentenceAnswers = [];
        this.part3MatchingHeadingOptions = [];
        this.part3DraggedMatchingHeading = [];
        this.part3MatchingHeadingAnswer = [];
        this.part3MatchingFeatureAnswers = [];
    
        //answersheets from Be
         this.answerSheet = []
         this.part1AnswerSheet =[]
         this.part2AnswerSheet = []
         this.part3AnswerSheet = []
    
        //Overall Scores
        this.partOneScore = null;
        this.partTwoScore = null;
        this.partThreeScore =null;
        this.overallScore = null;
    
    }

}