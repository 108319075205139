// Utility function to display questions
export const displayQuestions = (partQuestions: any, partAllAnswers: any,isQuestionAnswered:any) => {
    return (
      <div className="flex ">
        {partQuestions &&
          partQuestions.length > 0 &&
          partQuestions.map((item: any, index: any) => {
            let isAnswered = isQuestionAnswered(partAllAnswers, item);
            let isSeperateQns =
              !!(typeof item === "string" && item.includes("-"));
  
            return (
              <div
                key={item}
                className={`${
                  isAnswered
                   ? "border border-[#E31837] text-[#E31837] bg-[#FFEAEA] "
                    : "border border-gray-500 text-[#737E93] bg-[#F8F9FA]"
                } ${
                  isSeperateQns ? "w-20 h-8" : "w-8 h-8"
                }  rounded-full  flex justify-center items-center mx-1 font-semibold`}
              >
                {item}
              </div>
            );
          })}
      </div>
    );
  };
  