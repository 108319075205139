import * as React from "react";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  let formattedValue;

  if (props.value < 90) {
    formattedValue = Math.round(props.value);
  } else if (props.value < 95) {
    formattedValue = props.value.toFixed(1);
  } else if (props.value < 100) {
    formattedValue = props.value.toFixed(2);
  } else {
    formattedValue = props.value;
  }

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} sx={{backgroundColor:'#FFEAEA',height:20,borderRadius:5,'& .MuiLinearProgress-bar': {
          backgroundColor: '#e31837', // Color of the progress bar
        }}}/>
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">
         {formattedValue}%
        </Typography>
      </Box>
    </Box>
  );
}

export default function LinearWithValueLabel() {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress < 80) {
          return prevProgress + 5;
        } else if (prevProgress < 85) {
          return prevProgress + 3;
        } else if(prevProgress < 90) {
          return prevProgress + 1;
        } else if (prevProgress < 95) {
          return prevProgress + 0.1;
        } else if (prevProgress < 100) {
          return prevProgress + 0.01;
        } else if(prevProgress >= 99.99) {
          return prevProgress = 99.99;
        }else {
          return prevProgress;
        }
      });
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ width: "75%",marginLeft: 'auto', marginRight: 'auto', marginTop: 5 }}>
      <LinearProgressWithLabel value={progress} />
    </Box>
  );
}
