// useTabs.ts
import { useState } from 'react';

interface Tab {
  label: string;
  content: JSX.Element;
}

export const useTabs = (initialTabs: Tab[], initialPartType: string) => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  const getTabContent = (partType: string, tabs: Tab[]) => {
    switch (partType) {
      case 'part1':
        return tabs[0].content;
      case 'part2':
        return tabs[1].content;
      case 'part3':
        return tabs[2].content;
      case 'part4':
        return tabs[3].content;
      default:
        return tabs[activeTab].content;
    }
  };

  return { activeTab, handleTabClick, getTabContent };
};
