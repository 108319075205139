import React from "react";
import SingleChoiceQuestions from "../questionTypes/SingleChoiceQuestions";
import MultiAnswerQuestions from "../questionTypes/MultiAnswerQuestions";
import NoteCompletion from "../questionTypes/NoteCompletion";
import TaskIdentifyingInformation from "../questionTypes/TaskIdentifyingInformation";
import { observer } from "mobx-react-lite";
import SummaryCompletionOneWordFromText from "../questionTypes/SummaryCompletionOneWordFromText";
import SummaryCompletionSelectingFromListOfWords from "../questionTypes/SummaryCompletionSelectingFromListOfWords";
import SenetenceCompletion from "../questionTypes/SenetenceCompletion";
import MatchingSentenceEnding from "../questionTypes/MatchingSentenceEnding";
import TableCompletion from "../questionTypes/TableCompletion";
import MatchingHeaders from "../questionTypes/MatchingHeaders";
import MatchingFeatures from "../questionTypes/MatchingFeatures";

interface RenderQuestionsProps {
  item: any;
  handleRadioChange?: (qId: any, answer: any) => void;
  handleRadioChangeTaskIdentifying?: (qId: any, answer: any) => void;
  handleCheckBoxChange?: (qId: any, answer: any, isChecked: any) => void;
  replaceWithInputFields?: (
    text: string,
    indexOfSentence: any,
    qId: any
  ) => void; // Adjust this type based on your requirements
  replaceWithInputFieldsSentenceCompletion?:( text: string,
    indexOfSentence: any,
    qId: any) => void;
  twoWordAnswerSummaryCompletionInputValues?:(qId:any) => void;
  handleChangeSummaryCompletionOneWordAnswers?:( qId: any,
    answer: any) => void;
    handleDragOver:any;
  handleDragStart:any;
  handleDrop:any;
  handleDragStartFromAnswer:any;
  handleDragStartMatchingHeader:any;
  handleDropMatchingHeading:any;
  handleMatchingFeature : any;
  MatchingFeatureAnswers:any;
  handleDropFromDisplayedItem:any;
  handleSentenceDragStart:any;
  handleDragSentenceStartFromAnswer:any
  handleDropSentenceFromDisplayedItem:any;
  handleSentenceDrop:any;
  darggedCurrentItem:any;
  dragAnswers:any;
  setMatchingHeadingAnswers: any;
  setDragAnswers:any;
  setDragSentenceAnswers:any;
  matchingHeadingAnswers: any;
  darggedCurrentSentence:any;
  setDarggedCurrentSentence:any;
  dragSentenceAnswers:any;
  inputHandler?: (qId:any,answer:any) => void;
  singleAnswers:any;
  multipleAnswers:any;
  tableCompletionAnswer: any;
  taskIdentifyingItemAnswers: any;
}

const RenderQuestions: React.FC<RenderQuestionsProps> = ({
  item,
  singleAnswers,
  multipleAnswers,
  tableCompletionAnswer,
  taskIdentifyingItemAnswers,
  handleRadioChange,
  handleCheckBoxChange,
  replaceWithInputFields,
  twoWordAnswerSummaryCompletionInputValues,
  handleChangeSummaryCompletionOneWordAnswers,
  handleDragOver,
  handleDragStart,
  handleDrop,
  handleDragStartFromAnswer,
  handleDragStartMatchingHeader,
  handleDropMatchingHeading,
  handleMatchingFeature,
  MatchingFeatureAnswers,
  handleDropFromDisplayedItem,
  handleSentenceDragStart,
  handleDragSentenceStartFromAnswer,
  handleDropSentenceFromDisplayedItem,
  handleSentenceDrop,
  darggedCurrentItem,
  dragAnswers,
  setMatchingHeadingAnswers,
  setDragAnswers,
  handleRadioChangeTaskIdentifying,
  replaceWithInputFieldsSentenceCompletion,
  matchingHeadingAnswers,
  darggedCurrentSentence,
  setDarggedCurrentSentence,
  setDragSentenceAnswers,
  dragSentenceAnswers,
  inputHandler
}) => {
  let componentToRender = null;
  switch (item["question-type"]) {
    case "Multiple Choice":
      return (
        <SingleChoiceQuestions
          item={item}
          handleRadioChange={handleRadioChange}
          singleAnswers={singleAnswers}
        />
      );
    case "Multiple Choice (more than one answer)":
      return (
        <MultiAnswerQuestions
          item={item}
          handleCheckBoxChange={handleCheckBoxChange}
          multipleAnswers={multipleAnswers}
        />
      );
    case "Note Completion":
      return (
        <NoteCompletion
          item={item}
          replaceWithInputFields={replaceWithInputFields}
        />
      );
    case "Identifying Information (True/False/Not Given)":
      return (
        <TaskIdentifyingInformation
        item={item}
        handleRadioChangeTaskIdentifying={handleRadioChangeTaskIdentifying}
        taskIdentifyingItemAnswers={taskIdentifyingItemAnswers}
        />
      );
    case "Summary Completion (selecting word from text)":
      return (
       <SummaryCompletionOneWordFromText item={item} twoWordAnswerSummaryCompletionInputValues={twoWordAnswerSummaryCompletionInputValues}
       handleChangeSummaryCompletionOneWordAnswers={handleChangeSummaryCompletionOneWordAnswers}/>
      );
    case "Summary Completion (selecting from a list of words or phrases)":
      return (
       <SummaryCompletionSelectingFromListOfWords 
       item={item}
       handleDragOver={handleDragOver}
        handleDragStart={handleDragStart}
        handleDrop={handleDrop}
        handleDragStartFromAnswer={handleDragStartFromAnswer}
        handleDropFromDisplayedItem={handleDropFromDisplayedItem}
        darggedCurrentItem={darggedCurrentItem}
        dragAnswers={dragAnswers}
        setDragAnswers={setDragAnswers}
        />
      );
    case "Sentence Completion":
      return (
       <SenetenceCompletion item={item}
       replaceWithInputFieldsSentenceCompletion={replaceWithInputFieldsSentenceCompletion}/>
      );
    case "Sentence Endings":
    return (
      <MatchingSentenceEnding item={item} setDragSentenceAnswers={setDragSentenceAnswers}
      handleDragOver={handleDragOver}
      handleSentenceDragStart={handleSentenceDragStart}
      handleDragSentenceStartFromAnswer={handleDragSentenceStartFromAnswer}
      handleDropSentenceFromDisplayedItem={handleDropSentenceFromDisplayedItem}
      handleSentenceDrop={handleSentenceDrop}
      dragSentenceAnswers={dragSentenceAnswers}
      darggedCurrentSentence={darggedCurrentSentence}
      setDarggedCurrentSentence={setDarggedCurrentSentence}/>
    );
    case "Table Completion":
        return (<TableCompletion item={item} inputHandler={inputHandler} tableCompletionAnswer={tableCompletionAnswer}/>);
    case "Matching Headings":
       return (<MatchingHeaders item={item} 
        handleDragStartMatchingHeader={handleDragStartMatchingHeader} 
        handleDragOver={handleDragOver}
        handleDropMatchingHeading={handleDropMatchingHeading}
        setMatchingHeadingAnswers={setMatchingHeadingAnswers}
        matchingHeadingAnswers={matchingHeadingAnswers}
        />
      )
      case "Matching Features":
        return (<MatchingFeatures item={item} 
          handleMatchingFeature={handleMatchingFeature}
          MatchingFeatureAnswers={MatchingFeatureAnswers}
        />
      )
    default:
      return null;
  }
};
export default observer(RenderQuestions);
