// utils/renderInputFieldQuestion.tsx

import { observer } from 'mobx-react-lite';
import React from 'react';

const RenderInputFieldQuestion = (
 props:any
) => {
  const {cell,tableCompletionAnswer,inputHandler} = props;
  if (cell.includes("{qId:")) {
    // Use regex to find and replace {qId:4} with an input field
    const parts = cell.split(/\{qId:(\d+)\}/);
    const result = parts.map((part: any, index: any) => {
      if (index % 2 === 1) {
        const matchingAnswer = tableCompletionAnswer.find(
          (item: any) => item.qId == parts[index]
        );
        return (
          <input
            key={parts[index]}
            type="text"
            id={parts[index]}
            placeholder={`question ${parts[index]}`}
            className="border border-[#E3E5E9] rounded-md p-2 w-[8rem] mx-1"
            value={matchingAnswer ? matchingAnswer.answer : ""}
            onChange={inputHandler}
          />
        );
      } else {
        return part;
      }
    });

    // Render the array of React elements
    return <div>{result}</div>;
  } else {
    return <>{cell}</>;
  }
};

export default observer(RenderInputFieldQuestion);
