import apiSauce from "./api-sauce";

export class StartTestApi{

async startFreeTest(id:any):Promise<any>{

    return await apiSauce.apisauce?.post(`/start-test?test_id=${id}`)
}

async generateCategories():Promise<any>{
    return await apiSauce.apisauce?.get(`/fetch-question-categories`)
}

async generateProTestApi(typeTst:any,ID:any):Promise<any>{
    let url:any;
    if(typeTst !=='part1'){
        url = `/generate-test-v4?type=${typeTst}`
    }else{
        url =`generate-test-v4?type=part1&category_id=${ID}`
    }

    return await apiSauce.apisauce?.post(url)
}

async getFreeTestPartOneDataApi(payload:any):Promise<any>{
    return await apiSauce.apisauce?.get(`/get-test-v2?test_id=${payload.id}&type=${payload.type}`)
}

async submitAnswerApi(payload:any):Promise<any>{
      return await apiSauce.apisauce?.post(`/submit-answer`, payload);

}

async endTestApi(testId:number):Promise<any>{
    return await apiSauce.apisauce?.get(`/end-test?test_id=${testId}`)
}

}