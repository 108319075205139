
import { makePersistable } from 'mobx-persist-store';
import { makeAutoObservable } from "mobx";
import { PaymentApi } from '../api/payments-api';

export class PaymentStore {

    plansData: any = []
    selectedPlan: any = {}

    constructor(private PaymentApi: PaymentApi) {
        makeAutoObservable(this);
        makePersistable(this, { name: 'PaymentStore', properties: ['plansData'], storage: window.localStorage });
    }

    async getPlans() {
        try {

            let res = await this.PaymentApi.getplansApi();
            if (res.status === 200) {

                this.plansData = res.data
            }
        } catch (e) {
            console.log(e, "ERROR")
        }
    }

async selectPlan(data:any){
this.selectedPlan = data;
}

async generateOrder(){
    try{
        let payload = {
            plan_id :this.selectedPlan.id,
            code:""
        }
        const res = await this.PaymentApi.generateOrderApi(payload);
        return res

    }catch(e){
        console.log(e,"ERROR in the generate order")
    }
}

}