import {ListeningTestResultsApi } from "./../api/listening-test-results-api";
import { makePersistable } from "mobx-persist-store";
import { makeObservable, observable, action } from "mobx";
import { BaseTestResultsStore } from "./baseClass";


export class TestListeningResultsStore extends BaseTestResultsStore {
  graphData = {
    dates: Array<string>,
    scores: Array<number>,
  };
  individualTestScorePart3: any = {};
  individualTestScorePart4: any = {};
  aiFeedbackData:any = {}
  getQuestionAnalysisPartOne: any = {};
  getQuestionAnalysisPartTwo: any = {};
  isGrapghDataLoading = false
  ListeningPart1Answer = {
    text: "",
    img: "",
  };

  ListeningPart2Answer = {
    text: "",
    img: "",
  };

  partType:any = '';
  constructor(private ListeningTestResultsApi: ListeningTestResultsApi) {
    super("listeningResults");
    makeObservable(this, {
      graphData:observable,
      individualTestScorePart3:observable,
      individualTestScorePart4:observable,
      aiFeedbackData:observable,
      getQuestionAnalysisPartOne:observable,
      getQuestionAnalysisPartTwo:observable,
      isGrapghDataLoading:observable,
      ListeningPart1Answer:observable,
      ListeningPart2Answer:observable,
      partType:observable,
      setListeningPart1Answers:action,
      setListeningPart2Answers:action,
      testResultsUpdate:action,
      setTestCompleted:action,
      freeTestCompletedClicked:action,
      getGraphData:action,
      getTestScoreListening:action,
      getQuestionAnalysis:action,
      isFreeTestCompleted:action
    })
    makePersistable(this, {
      name: "TestListeningResultsStore",
      properties: [
        "individualTestScorePart3",
        "individualTestScorePart4",
        "getQuestionAnalysisPartOne",
        "getQuestionAnalysisPartTwo",
        "partType",
        "aiFeedbackData"
      ],
      storage: window.localStorage,
    });
  }

  async setListeningPart1Answers(value:any,img:any) {
    this.ListeningPart1Answer.text = value;
    this.ListeningPart1Answer.img = img
  }
  async setListeningPart2Answers(value:any,img:any) {
    this.ListeningPart2Answer.text = value;
    this.ListeningPart2Answer.img = img
  }

  async testResultsUpdate(querryParam: string,page:number) {
    const response = await this.ListeningTestResultsApi.testResultsUpdateListening(
      querryParam,page
    );
    this.testResults = response.data.data;
    if (response.data.data.length === 0) {
      this.isTestsFound = true;
    } else {
      this.isTestsFound = false;
    }
    return response;
  }
  async setTestCompleted(tId: any, tType: any) {
    this.completedTestId = tId;
    this.completedTestType = tType;
  }

  async freeTestCompletedClicked(bool: boolean) {
    this.isFreeTestCompletedClicked = bool;
  }

  async getGraphData() {
    return await this.ListeningTestResultsApi.getAverageScoreDataListening();
  }

  // async getAverageTestScore() {
  //   try{
  //     this.isGrapghDataLoading = true
  //   const res = await this.ListeningTestResultsApi.getAverageScoreDataListening();
  //   if (res.status === 200) {
  //     this.averageScore = res.data.avg_score ? res.data.avg_score : 0;

  //     const transformedData = res.data.data.reduce(
  //       (result: any, item: any) => {
  //         result.scores.push(item.score);
  //         const dateObject = new Date(item.date);
  //         result.dates.push(formatDateToCustomFormat(dateObject));
  //         return result;
  //       },
  //       { scores: [] as string[], dates: [] as number[] }
  //     );
  //     this.graphData = transformedData;
  //     this.isGrapghDataLoading = false;
  //     this.hasUserTakenAnyTest = res.data.data.length > 0;
  //     return res.data.avg_score;
  //   }
  
  //   }catch(e){
  //     this.isGrapghDataLoading = false
  //     console.log(e,"ERRor")
  //   }
  // }


  async getTestScoreListening(tstId: any,type:any) {
    this.isLoading = true;
    this.partType = type;
    this.isResultsLoading =true
    try {
      const res = await this.ListeningTestResultsApi.getTestScoreApiListening(tstId);
      if (res.status === 200) {
        this.isResultsLoading =false;
        this.isLoading = false;
        if(type === 'part1'){
          this.individualTestScorePart1 = res.data.part1;
        }else if(type === 'part2'){
          this.individualTestScorePart2 = res.data.part2;
        }else if(type === 'part3'){
          this.individualTestScorePart3 = res.data.part3;
        }else if(type === 'part4'){
          this.individualTestScorePart4 = res.data.part4;
        }else{
          this.individualTestScorePart1 = res.data.part1;
          this.individualTestScorePart2 = res.data.part2;
          this.individualTestScorePart3 = res.data.part3;
          this.individualTestScorePart4 = res.data.part4;
        }
        
      }
    } catch (e) {
      console.log(e);
    }
  }

  async getQuestionAnalysis(tstId: any, typeOfTest: any) {
    try {
      const res = await this.ListeningTestResultsApi.getQuestionAnalysisApiReading(
        tstId
      );
      if (res.status === 200) {
         this.aiFeedbackData = res.data
      }
    } catch (e) {
      console.log(e, "questionanalysispart");
    }
  
}
async isFreeTestCompleted (test_id:any){
  this.isLoading = true
  try{
      const res = await this.ListeningTestResultsApi.isFreeTestCompletedListening(test_id);
      if(res.status === 200){
        return  res.is_completed
      }
      this.isLoading = false
  }catch(e){
      console.log(e,"idFreeTest Completed");
      this.isLoading = false
  }
}

}
