import React from 'react'
import { useStore } from "../Hooks/use-store";
import { useNavigate } from "react-router-dom";
import IeltsUtilities from './IeltsUtilities';
import CambriUtilities from './CambriUtilities';
import SslCertificates from './SslCertificates';
import Payments from './Payments';

const Utilities = () => {

    const { userStore } = useStore();

    const navigate = useNavigate();

    const [project,setProject] = React.useState();

    const handleActionChange = (e:any) => {
      let value = e.target.value;
      setProject(value);
    }
  
    const logoutHandler = () => {
        userStore.clearStoredDate();
        navigate("/login");
        localStorage.clear();
    };

    const customComponent = (action:any) => {
        switch (action) {
         case 'ielts'  : return (<IeltsUtilities/>);
         case 'cambri' : return (<CambriUtilities/>);
         case 'sslCerts' : return (<SslCertificates/>);
         case 'payments' : return <Payments />
       }  
     }
    
    const user = userStore.studentDetails.mobileNumber;

  return (
    <div>
      {
        user === "+919550735454" || user === "+919908479039" || user === "+919502188434" ?  <>
        <header className='w-full bg-white flex justify-between py-5 px-10 text-3xl border-b'>
          <p className=''>
            Utilities
          </p>
          <button onClick={logoutHandler} className='cursor-pointer'>
            Logout
          </button>
        </header>

        <body className='m-10'>
        <div className='text-xl'>
            <p>Choose a project</p>
            <select className='w-72 border rounded px-2 py-1' value={project} onChange={(e) => handleActionChange(e)}>
             <option >--select an option--</option>
             <option value="cambri">Cambri</option>
             <option value="ielts">IELTS</option>
             <option value="sslCerts">SSL Certificates</option>
             <option value="payments">Payments</option>
            </select>
          </div>
          <div className='my-10 relative'>
            {customComponent(project)}
          </div>
        </body>
    </> : 
      <div className='h-screen flex'>
        <h2 className='text-xl w-1/3 px-5 py-10 border border-[#E31837] rounded-xl m-auto'>Sorry, It seems you don't have access to this page,. please {''} <button className='text-blue-500 cursor-pointer underline' onClick={() => navigate("/login")}>log in</button>{''} to your account</h2>
      </div>
      }
    </div>

  )
}

export default Utilities;