import { observer } from 'mobx-react-lite';
import React from 'react'

const TaskIdentifyingInformation = ({item,handleRadioChangeTaskIdentifying,taskIdentifyingItemAnswers}:any) => {
  return (
    <div>
         <div className="text-[#3C424C] text-sm my-2">
         Choose <span className='font-bold'>TRUE</span> if the statement agrees with the information given in the text, 
         choose <span className='font-bold'>FALSE</span> if the statement contradicts the information, or choose 
         {''}<span className='font-bold'>NOT GIVEN</span> if there is no information on this.
              </div>
      {item["list-of-questions"].length > 0 &&
        item["list-of-questions"].map((question: any, index: any) => {
          return (
            <>
              {" "}
             
              <div className="p-4 border border-[#E3E5E9] rounded-xl m-2 flex flex-col">
                <div className="text-[#292D35] text-base">{`${question.qId}. ${question.qName}`}</div>
                <div>
                  {question.options.length > 0 &&
                    question.options.map((option: any, index: any) => {
                      const matchingAnswer =  taskIdentifyingItemAnswers.find(
                        (item: any) => item.qId === question.qId 
                      );
                      return (
                        <div className="text-[#737E93]" key={option}>
                          <input
                            type="radio"
                            className="w-3 h-3 mr-1 "
                            name={question.qId}
                            value={option}
                            checked={matchingAnswer && matchingAnswer.answer === option}
                            onChange={(e) =>{
                              handleRadioChangeTaskIdentifying(e.target.name, e.target.value)
                            }
                          }
                            
                          />{" "}
                          {option}
                        </div>
                      );
                    })}
                </div>
              </div>
            </>
          );
        })}
    </div>
  )
}

export default observer(TaskIdentifyingInformation)