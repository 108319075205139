import React, { useEffect } from 'react'

const Timer = ({time,setTime,intervalRef}:any) => {
    useEffect(() => {
      const interval = setInterval(() => {
        setTime((prevTime: number) => {
          return prevTime - 1;
        });
      }, 1000);
  
      // Store the interval ID in the intervalRef
      if (intervalRef.current) {
        intervalRef.current = interval;
      }
  
      return () => {
        clearInterval(interval);
      };
    }, []);
  
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    
    return (
      <div>
        <p className="text-[#E31837] font-bold pl-2" ref={intervalRef}>
          {minutes.toString().padStart(2, "0")}:
          {seconds.toString().padStart(2, "0")}
        </p>
      </div>
    );
}

export default Timer