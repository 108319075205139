

export const singleChoice = (questionItem: any) => {
  let correctAnswerList: any[] = [];
  questionItem.listOfQuestions.map((question: any) => {
    let answer = {
      qId: question.questionId,
      qName: question.questionName,
      questionType: questionItem.questionType,
      correctAnswer: question.answers[0].answers[0],
    };
    correctAnswerList.push(answer);
  });
  return correctAnswerList;
};

export const multipleChoice = (questionItem: any) => {
  let correctAnswerList: any[] = [];
  questionItem.listOfQuestions.map((question: any) => {
    let answer = {
      qId: question.questionId,
      qName: question.questionName,
      questionType: questionItem.questionType,
      correctAnswer: question.answers[0].answers,
    };
    correctAnswerList.push(answer);
  });
  console.log(correctAnswerList, "vinodmultipleChoice");
  return correctAnswerList;
};

export const noteAndSentenceCompletion = (questionItem: any) => {
  let correctAnswerList: any[] = [];
  questionItem.listOfQuestions.map((question: any) => {
    let answer = {
      qId: question.questionId,
      qName: question.questionName,
      questionType: questionItem.questionType,
      correctAnswer: question.answers[0].answers,
    };
    correctAnswerList.push(answer);
  });
  return correctAnswerList;
};

export const planDiagram = (questionItem: any) => {
  let correctAnswerList: any[] = [];
  let questionIdArray: any[] = [];
  const [start, end] = questionItem.questionRange.split("-").map(Number);
  for (let i = start; i <= end; i++) {
    questionIdArray.push(i);
  }
  questionItem.listOfQuestions[0].items.tbody.map(
    (item: any, index: number) => {
      for (let key in item) {
        if (item[key] === true) {
          let answer = {
            qId: questionIdArray[index],
            correctAnswer: key,
          };
          correctAnswerList.push(answer);
        }
      }
    }
  );
  return correctAnswerList;
};


export const tableCompletion = (questionItem: any) => {
  let correctAnswerList: any[] = [];
  questionItem.listOfQuestions.forEach((question: any) => {
    const answers = question.answers.map((answer: any) => ({
      qId: Object.keys(answer)[0],
      correctAnswer: Object.values(answer)[0],
    }));
    question.items.tbody.forEach((tbodyItem: any) => {
      const qIdMatches: any = [];
      for (const key in tbodyItem) {
        if (Object.hasOwn(tbodyItem, key)) {
          const value = tbodyItem[key];
          const match: any = value.match(/\{qId:(\d+)\}/);
          if (match) {
            qIdMatches.push(match[1]);
          }
        }
      }
      qIdMatches.forEach((qId: any) => {
        const correctAnswer = answers.find(
          (answer: any) => answer.qId === qId
        )?.correctAnswer;
        if (qId && correctAnswer) {
          const answerObj = {
            qId: qId,
            correctAnswer: correctAnswer,
            table: question.items.tbody,
            qName: question.questionName,
            questionType: questionItem.questionType,
          };
          correctAnswerList.push(answerObj);
        }
      });
    });
  });
  return correctAnswerList;
};

export const shortAnswer = (questionItem: any) => {
  let correctAnswerList: any[] = [];
  let range = questionItem["questionRange"].split("-");
  let questionsArray = [];
  let [startingQuestion, endingQuestion] = range;
  for (let i = startingQuestion; i <= endingQuestion; i++) {
    questionsArray.push(i);
  }
  questionsArray.forEach((questionNumber: any) => {
    let answer = {
      qId: questionNumber,
      qName: questionItem.questionDescription,
      questionType: questionItem.questionType,
      correctAnswer: questionItem.listOfQuestions[0].answers[0].answers,
    };
    correctAnswerList.push(answer);
  });
  return correctAnswerList;
};
