import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import apiSauce from "../api/api-sauce";
import Loader from "../../src/Loader/index";
import { toast } from "react-toastify";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Tab } from "@headlessui/react";

interface UserFormProps {
  mobileNumber: string;
  handleMobileNumber: (val: string) => void;
  module: string;
  handleModuleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: () => void;
  disabled: boolean;
  showListeningModule?: boolean;
}

const UserForm: React.FC<UserFormProps> = ({
  mobileNumber,
  handleMobileNumber,
  module,
  handleModuleChange,
  handleSubmit,
  disabled,
  showListeningModule = false,
}) => {
  return (
    <section className="space-y-7">
      <div>
        <p>User mobile number</p>
        <PhoneInput
          containerClass="w-72 h-12"
          containerStyle={{ borderColor: "#737E93", borderRadius: "5px" }}
          dropdownStyle={{ width: "18rem" }}
          country={"in"}
          value={mobileNumber}
          onChange={handleMobileNumber}
        />
      </div>
      <div>
        <p className="my-2">Module</p>
        <div className="space-x-5">
          <label>
            <input
              type="radio"
              className="mr-1"
              value="speaking"
              checked={module === "speaking"}
              onChange={handleModuleChange}
            />
            {""}Speaking
          </label>
          <label>
            <input
              type="radio"
              className="mr-1"
              value="writing"
              checked={module === "writing"}
              onChange={handleModuleChange}
            />
            {""}Writing
          </label>
          <label>
            <input
              type="radio"
              className="mr-1"
              value="reading"
              checked={module === "reading"}
              onChange={handleModuleChange}
            />
            {""}Reading
          </label>
          {showListeningModule && (
            <label>
              <input
                type="radio"
                className="mr-1"
                value="listening"
                checked={module === "listening"}
                onChange={handleModuleChange}
              />
              {""}Listening
            </label>
          )}
        </div>
      </div>
      <button
        onClick={handleSubmit}
        disabled={mobileNumber === "+91" || module === "" || disabled}
        className={`text-white py-2 w-52  mx-auto rounded-full ${
          mobileNumber === "" || module === "" ? "bg-gray-300" : "bg-[#E31837]"
        }`}
      >
        Submit
      </button>
    </section>
  );
};

const IeltsUtilities = () => {
  const [actionType, setActionType] = React.useState();

  const handleActionChange = (e: any) => {
    let value = e.target.value;
    setActionType(value);
  };

  const customComponent = (action: any) => {
    switch (action) {
      case "newUser":
        return <NewUserCreation />;
      case "proExtension":
        return <ProExtension />;
      case "deleteTest":
        return <DeleteTest />;
      case "serverLog":
        return <ServerLog />;
      case "getTests":
        return <GetUserTest />;
      case "proUsers":
        return <ProUserData />;
      case "testCount":
        return <TestCount />;
      case "ieltsProUsers":
        return <IeltsProUsers />;
      case "recentTests":
        return <RecentTests />;
      case "dashboardChange":
        return <DashboardCustomization />;
    }
  };

  return (
    <>
      <div className="text-xl">
        <p>Choose an action</p>
        <select
          className="w-72 border rounded px-2 py-1"
          value={actionType}
          onChange={(e) => handleActionChange(e)}
        >
          <option>--select an option--</option>
          <option value="newUser">Pro plan for New User </option>
          <option value="proExtension">Pro plan for Old User</option>
          <option value="deleteTest">User Test's Deletion</option>
          <option value="dashboardChange">User Module Access</option>
          <option value="serverLog">Generate failure server log's</option>
          <option value="getTests">User Test's count</option>
          <option value="proUsers">IELTS Dashboard</option>
          <option value="testCount">All Modules Test's Count</option>
          <option value="ieltsProUsers">Ielts All Pro Users</option>
          <option value="recentTests">Recent Test statistics</option>
        </select>
      </div>
      <div className="my-10 relative">{customComponent(actionType)}</div>
    </>
  );
};

export default IeltsUtilities;

export const ProExtension = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [date, setDate] = useState("");
  const [disabled, setDisabled] = useState(false);

  const handleMobileNumber = (value: any) => {
    setMobileNumber(value);
  };

  const handleDateChange = (e: any) => {
    const value = e.target.value;
    setDate(value);
  };

  const handleSubmit = async () => {
    setDisabled(true);
    const selectedDate = new Date(date);
    const year = selectedDate.getFullYear();
    const month = String(selectedDate.getMonth() + 1).padStart(2, "0");
    const day = String(selectedDate.getDate()).padStart(2, "0");
    const dateValue = `${day}-${month}-${year}`;
    let payload = {
      mobile: `+${mobileNumber}`,
      date: dateValue,
    };
    try {
      let response: any = await apiSauce.apisauce?.put(
        `/admin/updatepro`,
        payload
      );
      if (response?.status === 200) {
        toast.success(response?.data?.message || "updated Successfully");
        setMobileNumber("+91");
        setDate("");
      } else {
        setDisabled(false);
        toast.error("Something went wrong");
      }
    } catch (err) {
      setDisabled(false);
      toast.error("Something went wrong");
      console.error(err);
    }
  };

  return (
    <section className="space-y-7">
      <div>
        <p>User mobile number</p>
        <PhoneInput
          containerClass="w-72 h-12"
          containerStyle={{ borderColor: "#737E93", borderRadius: "5px" }}
          dropdownStyle={{ width: "18rem" }}
          country={"in"}
          value={mobileNumber}
          onChange={(val) => handleMobileNumber(val)}
        />
      </div>
      <div>
        <p>Select the date</p>
        <input
          type="date"
          className="border w-72 rounded px-2 py-1"
          min={new Date().toISOString().split("T")[0]}
          value={date}
          onChange={(e) => handleDateChange(e)}
        />
      </div>
      <div>
        <button
          onClick={handleSubmit}
          disabled={mobileNumber === "+91" || date === "" || disabled}
          className={`text-white py-2 w-52  mx-auto rounded-full ${
            mobileNumber === "" || date === "" ? "bg-gray-300" : "bg-[#E31837]"
          }`}
        >
          Submit
        </button>
      </div>
    </section>
  );
};

export const DeleteTest = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [module, setModule] = useState("");
  const [disabled, setDisabled] = useState(false);

  const handleMobileNumber = (value: any) => {
    setMobileNumber(value);
  };

  const handleModuleChange = (e: any) => {
    let value = e.target.value;
    setModule(value);
  };

  const handleSubmit = async () => {
    setDisabled(true);
    let errorResponse;
    try {
      let response: any = await apiSauce.apisauce?.delete(
        `/admin/remove_tests?mobile=%2B${mobileNumber}&module_name=${module}`
      );
      errorResponse = response?.data?.detail;
      if (response?.status === 200) {
        toast.success(response?.data?.message || "updated Successfully");
        setDisabled(false);
        setMobileNumber("+91");
        setModule("");
      } else {
        setDisabled(false);
        toast.error(response?.data?.detail);
      }
    } catch (err) {
      setDisabled(false);
      toast.error(errorResponse);
      console.error(err);
    }
  };

  return (
    <UserForm
      mobileNumber={mobileNumber}
      handleMobileNumber={handleMobileNumber}
      module={module}
      handleModuleChange={handleModuleChange}
      handleSubmit={handleSubmit}
      disabled={disabled}
      showListeningModule={true} // Show the Listening module
    />
  );
};

export const ServerLog = () => {
  useEffect(() => {
    getLogs();
  }, []);

  const [data, setData] = useState<
    Array<{
      id: number;
      question_id: number;
      audio_file: string;
      card_id: null;
      answer_id: null;
      test_id: number;
      user_id: number;
      reason: { reason_for_exception: string } | null;
      issue_raised_at: string;
    }>
  >([]);
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();
  const [showFullContent, setShowFullContent] = useState(false);

  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  const getLogs = async () => {
    setLoading(true);
    try {
      let response = await apiSauce.apisauce?.get(
        `/admin/fetch_exception_logs`
      );
      if (response?.status === 200) {
        setData(response.data as any);
        setLoading(false);
      } else {
        setLoading(false);
        setError(true);
      }
    } catch (err) {
      setLoading(false);
      setError(true);
      console.error(err);
    }
  };

  const renderReason = (item: any) => {
    let reasonForException = "N/A";

    if (item.reason && typeof item.reason.reason_for_exception === "string") {
      if (showFullContent) {
        reasonForException = item.reason.reason_for_exception;
      } else if (item.reason.reason_for_exception.length > 150) {
        reasonForException = `${item.reason.reason_for_exception.substring(
          0,
          150
        )}...`;
      } else {
        reasonForException = item.reason.reason_for_exception;
      }
    }
    return reasonForException;
  };

  const renderContent = () => {
    if (loading) {
      return (
        <div className="w-[10%] mx-auto my-36">
          <Loader />
        </div>
      );
    } else if (error) {
      return <p className="mx-auto">Something went wrong</p>;
    } else {
      return (
        <div>
          <table className="table-auto border-collapse mx-auto w-[80%]">
            <thead className="sticky -top-1 bg-white">
              <tr>
                <th className="py-2 px-4 border">User Id</th>
                <th className="py-2 px-4 border">Test Id</th>
                <th className="py-2 px-4 border">TimeStamp</th>
                <th className="py-2 px-4 border">Reason</th>
              </tr>
            </thead>
            <tbody className="max-h-[400px] overflow-y-auto">
              {data?.map((item: any) => (
                <tr key={item.test_id}>
                  <td className="py-2 px-4 border">{item.user_id}</td>
                  <td className="py-2 px-4 border">{item.test_id}</td>
                  <td className="py-2 px-4 border">
                    {String(new Date(item.issue_raised_at).toLocaleString())}
                  </td>
                  <td className="py-2 px-4 border">
                    {renderReason(item)}
                    {item.reason &&
                      typeof item.reason.reason_for_exception === "string" &&
                      item.reason.reason_for_exception.length > 150 && (
                        <button
                          className="text-blue-500 cursor-pointer"
                          onClick={toggleContent}
                        >
                          {showFullContent ? "read less" : "read more"}
                        </button>
                      )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
  };

  return <>{renderContent()}</>;
};

export const GetUserTest = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [module, setModule] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [data, setData] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>(false);

  const moduleName: any = useRef(); //based on this reference variable table will be rendered

  const handleMobileNumber = (value: any) => {
    setMobileNumber(value);
  };

  const handleModuleChange = (e: any) => {
    let value = e.target.value;
    setModule(value);
  };

  const handleSubmit = async () => {
    setDisabled(true);
    moduleName.current = module;
    let errorResponse;
    try {
      let response: any = await apiSauce.apisauce?.get(
        `/admin/user/tests?mobile=%2B${mobileNumber}&module_name=${module}`
      );
      errorResponse = response?.data?.detail;
      if (response?.status === 200) {
        setDataFetched(true);
        setData(response?.data);
        setDisabled(false);
        setLoading(false);
        setError(false);
      } else {
        setLoading(false);
        setError(true);
        setDataFetched(false);
        setDisabled(false);
        toast.error(response?.data?.detail);
      }
    } catch (err) {
      setLoading(false);
      setError(true);
      setDataFetched(false);
      setDisabled(false);
      toast.error(errorResponse);
      console.error(err);
    }
  };

  const readingScoreRender = (item: any): string => {
    if (!item.score || typeof item.score !== "object") {
      return "";
    }

    const parts = Object.keys(item.score).filter(
      (key) => key !== "overallBand" && typeof item.score[key] === "object"
    );
    const readingScore = parts
      .map((part) => `<p>${part} : ${item.score[part].overall_score}</p>`)
      .join(""); // Use join() to concatenate the array into a single string
    const overallBand = `<p>overallBand : ${item.score.overallBand}</p>`;

    return readingScore + overallBand;
  };

  const writingSpeakingScoreRender = (item: any): string => {
    const scoreKeys = Object.keys(item.score);
    const scoreHTML = scoreKeys
      .map((key) => `<p>${key.replace(/_/g, " ")}: ${item.score[key]}</p>`)
      .join(""); // Use join() to concatenate the array into a single string

    return scoreHTML;
  };

  const handleTestId = (moduleType: any, testId: any) => {
    if (["reading", "writing", "speaking"].includes(moduleType)) {
      window.open(`/test/${moduleType}/${testId}`);
    }
  };

  const scoreRendering = (item: any): string => {
    let action = moduleName.current;
    switch (action) {
      case "speaking":
        return writingSpeakingScoreRender(item);
      case "writing":
        return writingSpeakingScoreRender(item);
      case "reading":
        return readingScoreRender(item);
    }
    return "";
  };

  const renderTestId = (moduleName: any, item: any) => {
    const handleClick = (type: any, id: any) => (
      <button
        onClick={() => handleTestId(type, id)}
        className="cursor-pointer hover:text-blue-500 hover:underline"
      >
        {id}
      </button>
    );

    switch (moduleName.current) {
      case "reading":
        return handleClick("reading", item?.reading_test_set_id);
      case "speaking":
        return handleClick("speaking", item?.test_id);
      case "writing":
        return handleClick("writing", item?.writing_test_id);
      default:
        return item?.listening_test_set_id;
    }
  };

  const renderTable = (data: any) => (
    <table className="table-auto border-collapse mx-auto w-[80%] mt-16">
      <thead className="sticky -top-1 bg-white">
        <tr>
          <th className="py-2 px-4 border">User Id</th>
          <th className="py-2 px-4 border">Test Id</th>
          <th className="py-2 px-4 border">Test Date</th>
          <th className="py-2 px-4 border">Status</th>
          <th className="py-2 px-4 border">Score</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item: any) => (
          <tr key={item.user_id}>
            <td className="py-2 px-4 border">{item.user_id}</td>
            <td className="py-2 px-4 border">
              {renderTestId(moduleName, item)}
            </td>
            <td className="py-2 px-4 border">
              {String(new Date(item.updated_at).toLocaleString())}
            </td>
            <td className="py-2 px-4 border">
              {item.is_finished ? "Completed" : "Not completed"}
            </td>
            <td className="py-2 px-4 border">
              {item.score ? (
                <div
                  dangerouslySetInnerHTML={{ __html: scoreRendering(item) }}
                />
              ) : (
                "N/A"
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  const renderContent = () => {
    if (loading) {
      return (
        <div className="w-[10%] mx-auto my-36">
          <Loader />
        </div>
      );
    }
    if (error) {
      return (
        <p className="text-4xl flex items-center justify-center">
          Something went wrong
        </p>
      );
    }
    if (dataFetched) {
      return data.length > 0 ? (
        renderTable(data)
      ) : (
        <p className="text-center text-xl mt-20">
          No Tests attempted by the user
        </p>
      );
    }
    return <></>;
  };

  return (
    <>
      <UserForm
        mobileNumber={mobileNumber}
        handleMobileNumber={handleMobileNumber}
        module={module}
        handleModuleChange={handleModuleChange}
        handleSubmit={handleSubmit}
        disabled={disabled}
        showListeningModule={false} // Hide the Listening module
      />

      <>{renderContent()}</>
    </>
  );
};

export const ProUserData = () => {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    const iframe = document.querySelector("iframe");
    if (iframe) {
      iframe.addEventListener("load", () => {
        setLoading(false);
      });
    }

    return () => {
      if (iframe) {
        iframe.removeEventListener("load", () => {
          setLoading(false);
        });
      }
    };
  }, []);

  return (
    <>
      {loading ? (
        <div className="w-[10%] mx-auto my-36">
          <Loader />
        </div>
      ) : (
        <></>
      )}
      <iframe
        src="https://ielts1.api.cambri.ai/api/dashboard?page=1&search_query=&city_filter=&region_filter=&country_filter=&referral_code="
        width="100%"
        className="h-[70vh]"
        title="hero"
      ></iframe>
    </>
  );
};

export const TestCount = () => {
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();
  const [testCountData, setTestCountData] = useState<any>();

  useEffect(() => {
    getTestCount();
  }, []);

  const getTestCount = async () => {
    setLoading(true);
    try {
      let response = await apiSauce.apisauce?.get(`/admin/fetchtestcounts`);
      if (response?.status === 200) {
        setTestCountData(response.data as any);
        setLoading(false);
      } else {
        setLoading(false);
        setError(true);
      }
    } catch (err) {
      setLoading(false);
      setError(true);
      console.error(err);
    }
  };

  const allCategories = new Set([
    ...(testCountData?.speaking_details?.part1
      ? Object.keys(testCountData.speaking_details.part1)
      : []),
    ...(testCountData?.speaking_details?.part2
      ? Object.keys(testCountData.speaking_details.part2)
      : []),
    ...(testCountData?.speaking_details?.part3
      ? Object.keys(testCountData.speaking_details.part3)
      : []),
    ...(testCountData?.speaking_details?.full
      ? Object.keys(testCountData.speaking_details.full)
      : []),
  ]);
  const Categories = Array.from(allCategories);

  const testsRendering = () => {
    if (loading) {
      return (
        <div className="w-[10%] mx-auto my-36">
          <Loader />
        </div>
      );
    } else if (error) {
      return (
        <p className="text-4xl flex items-center justify-center">
          Something went wrong
        </p>
      );
    } else {
      return (
        <div>
          {testCountData?.writing ? (
            <div className="my-10">
              <h1 className="text-center text-5xl mb-5">Writing</h1>
              <p className="w-[80%] mx-auto text-3xl">
                Total Tests : {testCountData?.writing}
              </p>
              <table className="table-auto border-collapse mx-auto w-[80%] text-xl">
                <thead className="bg-white">
                  <tr>
                    <th className="py-2 px-4 border">Part 1</th>
                    <th className="py-2 px-4 border">Part 2</th>
                    <th className="py-2 px-4 border">Full</th>
                  </tr>
                </thead>
                <tbody>
                  <td className="py-2 px-4 border text-center">
                    {testCountData?.writing_details?.part1 || "No Data"}
                  </td>
                  <td className="py-2 px-4 border text-center">
                    {testCountData?.writing_details?.part2 || "No Data"}
                  </td>
                  <td className="py-2 px-4 border text-center">
                    {testCountData?.writing_details?.full || "No Data"}
                  </td>
                </tbody>
              </table>
            </div>
          ) : (
            <></>
          )}
          {testCountData?.reading ? (
            <div className="my-10">
              <h1 className="text-center text-5xl mb-5">Reading</h1>
              <p className="w-[80%] mx-auto text-3xl">
                Total Tests : {testCountData?.reading}
              </p>
              <table className="table-auto border-collapse mx-auto w-[80%] text-xl">
                <thead className="bg-white">
                  <tr>
                    <th className="py-2 px-4 border">Part 1</th>
                    <th className="py-2 px-4 border">Part 2</th>
                    <th className="py-2 px-4 border">Part 3</th>
                    <th className="py-2 px-4 border">Full</th>
                  </tr>
                </thead>
                <tbody>
                  <td className="py-2 px-4 border text-center">
                    {testCountData?.reading_details?.part1 || "No Data"}
                  </td>
                  <td className="py-2 px-4 border text-center">
                    {testCountData?.reading_details?.part2 || "No Data"}
                  </td>
                  <td className="py-2 px-4 border text-center">
                    {testCountData?.reading_details?.part3 || "No Data"}
                  </td>
                  <td className="py-2 px-4 border text-center">
                    {testCountData?.reading_details?.full || "No Data"}
                  </td>
                </tbody>
              </table>
            </div>
          ) : (
            <></>
          )}
          {testCountData?.listening ? (
            <div className="my-10">
              <h1 className="text-center text-5xl mb-5">Listening</h1>
              <p className="w-[80%] mx-auto text-3xl">
                Total Tests : {testCountData?.listening}
              </p>
              <table className="table-auto border-collapse mx-auto w-[80%] text-xl">
                <thead className="bg-white">
                  <tr>
                    <th className="py-2 px-4 border">Part 1</th>
                    <th className="py-2 px-4 border">Part 2</th>
                    <th className="py-2 px-4 border">Part 3</th>
                    <th className="py-2 px-4 border">Part 4</th>
                    <th className="py-2 px-4 border">Full</th>
                  </tr>
                </thead>
                <tbody>
                  <td className="py-2 px-4 border text-center">
                    {testCountData?.listening_details?.part1 || "No Data"}
                  </td>
                  <td className="py-2 px-4 border text-center">
                    {testCountData?.listening_details?.part2 || "No Data"}
                  </td>
                  <td className="py-2 px-4 border text-center">
                    {testCountData?.listening_details?.part3 || "No Data"}
                  </td>
                  <td className="py-2 px-4 border text-center">
                    {testCountData?.listening_details?.part4 || "No Data"}
                  </td>
                  <td className="py-2 px-4 border text-center">
                    {testCountData?.listening_details?.full || "No Data"}
                  </td>
                </tbody>
              </table>
            </div>
          ) : (
            <></>
          )}
          {testCountData?.speaking ? (
            <div className="my-10">
              <h1 className="text-center text-5xl mb-5">Speaking</h1>
              <p className="w-[80%] mx-auto text-3xl">
                Total Count : {testCountData?.speaking}
              </p>
              <table className="table-auto border-collapse mx-auto w-[80%] text-xl">
                <thead className="bg-white">
                  <tr>
                    <th className="py-2 px-4 border">Category</th>
                    <th className="py-2 px-4 border">Part 1</th>
                    <th className="py-2 px-4 border">Part 2</th>
                    <th className="py-2 px-4 border">Part 3</th>
                    <th className="py-2 px-4 border">Full</th>
                  </tr>
                </thead>
                <tbody>
                  {Categories.map((category) => (
                    <tr key={category}>
                      <td className="py-2 px-4 border">{category}</td>
                      <td className="py-2 px-4 border">
                        {testCountData?.speaking_details.part1[category] || ""}
                      </td>
                      <td className="py-2 px-4 border">
                        {testCountData?.speaking_details.part2[category] || ""}
                      </td>
                      <td className="py-2 px-4 border">
                        {testCountData?.speaking_details.part3[category] || ""}
                      </td>
                      <td className="py-2 px-4 border">
                        {testCountData?.speaking_details.full[category] || ""}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <></>
          )}
        </div>
      );
    }
  };

  return <>{testsRendering()}</>;
};

export const IeltsProUsers = () => {
  useEffect(() => {
    getProUsers();
  }, []);

  const [data, setData] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();

  const getProUsers = async () => {
    setLoading(true);
    try {
      let response = await apiSauce.apisauce?.get(`/admin/fetch/pro/users`);
      if (response?.status === 200) {
        setData(response.data as any);
        setLoading(false);
      } else {
        setLoading(false);
        setError(true);
      }
    } catch (err) {
      setLoading(false);
      setError(true);
      console.error(err);
    }
  };

  const proExpireDaysCount = (date: any) => {
    let currentDate = new Date();
    let diffDays = Math.ceil(
      (new Date(date).getTime() - currentDate.getTime()) / (1000 * 3600 * 24)
    );
    return diffDays;
  };

  const renderProUsers = () => {
    if (loading) {
      return (
        <div className="w-[10%] mx-auto my-36">
          <Loader />
        </div>
      );
    } else if (error) {
      return (
        <p className="text-4xl flex items-center justify-center">
          Something went wrong
        </p>
      );
    } else {
      return (
        <div>
          <p className="w-[80%] mx-auto text-3xl">
            Total Pro Users : {data.length}
          </p>
          <table className="table-auto border-collapse mx-auto w-[80%]">
            <thead className="sticky -top-1 bg-white">
              <tr>
                <th className="py-2 px-4 border">User Id</th>
                <th className="py-2 px-4 border">Mobile Number</th>
                <th className="py-2 px-4 border">Name</th>
                <th className="py-2 px-4 border">Email</th>
                <th className="py-2 px-4 border">Joined On</th>
                <th className="py-2 px-4 border">Pro Expiry</th>
                <th className="py-2 px-4 border">Pro Expiry Days</th>
              </tr>
            </thead>
            <tbody className="max-h-[400px] overflow-y-auto">
              {data?.map((item: any) => (
                <tr key={item.id}>
                  <td className="py-2 px-4 border text-center">{item.id}</td>
                  <td className="py-2 px-4 border text-center">
                    {item.mobile}
                  </td>
                  <td className="py-2 px-4 border text-center">{item.name}</td>
                  <td className="py-2 px-4 border text-center">{item.email}</td>
                  <td className="py-2 px-4 border text-center">
                    {new Date(item.created_at).toLocaleDateString("en-IN", {
                      month: "short",
                      day: "2-digit",
                      year: "numeric",
                    })}
                  </td>
                  <td className="py-2 px-4 border text-center">
                    {new Date(item.pro_till).toLocaleDateString("en-IN", {
                      month: "short",
                      day: "2-digit",
                      year: "numeric",
                    })}
                  </td>
                  <td className="py-2 px-4 border text-center">
                    {proExpireDaysCount(item.pro_till)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
  };

  return <>{renderProUsers()}</>;
};

export const RecentTests = () => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [minToDate, setMinToDate] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [data, setData] = useState<Array<any>>([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [loading, setLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>(false);

  const handleFromDateChange = (e: any) => {
    const selectedFromDate = e.target.value;
    setFromDate(selectedFromDate);
    setMinToDate(selectedFromDate);
    if (toDate < selectedFromDate) {
      setToDate("");
    }
  };

  const handleToDateChange = (e: any) => {
    const selectedToDate = e.target.value;
    setToDate(selectedToDate);
  };

  const currentDate = new Date().toISOString().split("T")[0];

  const handleSubmit = async () => {
    setDisabled(true);
    let payload = {
      start_date: new Date(fromDate).toISOString(),
      end_date: new Date(toDate).toISOString(),
    };
    try {
      let response: any = await apiSauce.apisauce?.post(
        `/admin/test-stats`,
        payload
      );
      if (response?.status === 200) {
        setData(response?.data);
        setError(false);
        setDataFetched(true);
        setDisabled(false);
        setLoading(false);
        setToDate("");
        setFromDate("");
        setMinToDate("");
      } else {
        setLoading(false);
        setError(true);
        setDisabled(false);
        toast.error("Something went wrong");
      }
    } catch (err) {
      setLoading(false);
      setError(true);
      setDisabled(false);
      toast.error("Something went wrong");
      console.error(err);
    }
  };

  const dates = data.map((item) => item.date);

  const totalTests = data.map((item) => item.total_test);
  const completedTests = data.map((item) => item.complete_test);
  const partiallyCompletedTests = data.map((item) => item.incomplete_test);
  const part1Tests = data.map((item) => item.part1);
  const part2Tests = data.map((item) => item.part2);
  const part3Tests = data.map((item) => item.part3);
  const fullTests = data.map((item) => item.full);

  const totalTestOptions = {
    chart: {
      type: "line",
      backgroundColor: "transparent",
    },
    legend: {
      enabled: false, // Hide the legend (series selection option)
    },

    title: {
      text: "Total Tests", // Change the chart title here
    },

    yAxis: {
      min: 0,
      max: Math.max(...totalTests) + 1,
      tickInterval: 1,
      title: {
        enabled: false,
      },
      labels: {
        style: {
          color: "#8F98A9",
        },
      },
      //tickPositions: [0,3,9]
    },

    xAxis: {
      categories: dates,
      lineColor: "transparent",
      labels: {
        style: {
          color: "#8F98A9",
        },
      },
    },

    series: [
      {
        data: totalTests,
        color: "#FFC2B6",
        marker: {
          symbol: "circle", // Set the marker symbol (shape)
          fillColor: "#FF7678", // Set the fill color of the markers (node color)
          lineColor: "#FFC2B6", // Set the border color of the markers
          lineWidth: 1, // Set the border width of the markers
          radius: 6, // Set the radius to control marker symbol size
        },
        name: "", // Series name (legend) for the line
      },
    ],
  };

  const CompletedTestOptions = {
    chart: {
      type: "line",
      backgroundColor: "transparent",
    },
    legend: {
      enabled: false, // Hide the legend (series selection option)
    },

    title: {
      text: "Completed Tests", // Change the chart title here
    },

    yAxis: {
      min: 0,
      max: Math.max(...completedTests) + 1,
      tickInterval: 1,
      title: {
        enabled: false,
      },
      labels: {
        style: {
          color: "#8F98A9",
        },
      },
      //tickPositions: [0,3,9]
    },

    xAxis: {
      categories: dates,
      lineColor: "transparent",
      labels: {
        style: {
          color: "#8F98A9",
        },
      },
    },

    series: [
      {
        data: completedTests,
        color: "#FFC2B6",
        marker: {
          symbol: "circle", // Set the marker symbol (shape)
          fillColor: "#FF7678", // Set the fill color of the markers (node color)
          lineColor: "#FFC2B6", // Set the border color of the markers
          lineWidth: 1, // Set the border width of the markers
          radius: 6, // Set the radius to control marker symbol size
        },
        name: "", // Series name (legend) for the line
      },
    ],
  };

  const partiallyCompletedTestOptions = {
    chart: {
      type: "line",
      backgroundColor: "transparent",
    },
    legend: {
      enabled: false, // Hide the legend (series selection option)
    },

    title: {
      text: "Partially Completed Tests", // Change the chart title here
    },

    yAxis: {
      min: 0,
      max: Math.max(...partiallyCompletedTests) + 1,
      tickInterval: 1,
      title: {
        enabled: false,
      },
      labels: {
        style: {
          color: "#8F98A9",
        },
      },
      //tickPositions: [0,3,9]
    },

    xAxis: {
      categories: dates,
      lineColor: "transparent",
      labels: {
        style: {
          color: "#8F98A9",
        },
      },
    },

    series: [
      {
        data: partiallyCompletedTests,
        color: "#FFC2B6",
        marker: {
          symbol: "circle", // Set the marker symbol (shape)
          fillColor: "#FF7678", // Set the fill color of the markers (node color)
          lineColor: "#FFC2B6", // Set the border color of the markers
          lineWidth: 1, // Set the border width of the markers
          radius: 6, // Set the radius to control marker symbol size
        },
        name: "", // Series name (legend) for the line
      },
    ],
  };

  const part1TestOptions = {
    chart: {
      type: "line",
      backgroundColor: "transparent",
    },
    legend: {
      enabled: false, // Hide the legend (series selection option)
    },

    title: {
      text: "Part-1 Tests", // Change the chart title here
    },

    yAxis: {
      min: 0,
      max: Math.max(...part1Tests) + 1,
      tickInterval: 1,
      title: {
        enabled: false,
      },
      labels: {
        style: {
          color: "#8F98A9",
        },
      },
      //tickPositions: [0,3,9]
    },

    xAxis: {
      categories: dates,
      lineColor: "transparent",
      labels: {
        style: {
          color: "#8F98A9",
        },
      },
    },

    series: [
      {
        data: part1Tests,
        color: "#FFC2B6",
        marker: {
          symbol: "circle", // Set the marker symbol (shape)
          fillColor: "#FF7678", // Set the fill color of the markers (node color)
          lineColor: "#FFC2B6", // Set the border color of the markers
          lineWidth: 1, // Set the border width of the markers
          radius: 6, // Set the radius to control marker symbol size
        },
        name: "", // Series name (legend) for the line
      },
    ],
  };

  const part2TestOptions = {
    chart: {
      type: "line",
      backgroundColor: "transparent",
    },
    legend: {
      enabled: false, // Hide the legend (series selection option)
    },

    title: {
      text: "Part-2 Tests", // Change the chart title here
    },

    yAxis: {
      min: 0,
      max: Math.max(...part2Tests) + 1,
      tickInterval: 1,
      title: {
        enabled: false,
      },
      labels: {
        style: {
          color: "#8F98A9",
        },
      },
      //tickPositions: [0,3,9]
    },

    xAxis: {
      categories: dates,
      lineColor: "transparent",
      labels: {
        style: {
          color: "#8F98A9",
        },
      },
    },

    series: [
      {
        data: part2Tests,
        color: "#FFC2B6",
        marker: {
          symbol: "circle", // Set the marker symbol (shape)
          fillColor: "#FF7678", // Set the fill color of the markers (node color)
          lineColor: "#FFC2B6", // Set the border color of the markers
          lineWidth: 1, // Set the border width of the markers
          radius: 6, // Set the radius to control marker symbol size
        },
        name: "", // Series name (legend) for the line
      },
    ],
  };

  const part3TestOptions = {
    chart: {
      type: "line",
      backgroundColor: "transparent",
    },
    legend: {
      enabled: false, // Hide the legend (series selection option)
    },

    title: {
      text: "Part-3 Tests", // Change the chart title here
    },

    yAxis: {
      min: 0,
      max: Math.max(...part3Tests) + 1,
      tickInterval: 1,
      title: {
        enabled: false,
      },
      labels: {
        style: {
          color: "#8F98A9",
        },
      },
      //tickPositions: [0,3,9]
    },

    xAxis: {
      categories: dates,
      lineColor: "transparent",
      labels: {
        style: {
          color: "#8F98A9",
        },
      },
    },

    series: [
      {
        data: part3Tests,
        color: "#FFC2B6",
        marker: {
          symbol: "circle", // Set the marker symbol (shape)
          fillColor: "#FF7678", // Set the fill color of the markers (node color)
          lineColor: "#FFC2B6", // Set the border color of the markers
          lineWidth: 1, // Set the border width of the markers
          radius: 6, // Set the radius to control marker symbol size
        },
        name: "", // Series name (legend) for the line
      },
    ],
  };

  const fullTestOptions = {
    chart: {
      type: "line",
      backgroundColor: "transparent",
    },
    legend: {
      enabled: false, // Hide the legend (series selection option)
    },

    title: {
      text: "Full Tests", // Change the chart title here
    },

    yAxis: {
      min: 0,
      max: Math.max(...fullTests) + 1,
      tickInterval: 1,
      title: {
        enabled: false,
      },
      labels: {
        style: {
          color: "#8F98A9",
        },
      },
      //tickPositions: [0,3,9]
    },

    xAxis: {
      categories: dates,
      lineColor: "transparent",
      labels: {
        style: {
          color: "#8F98A9",
        },
      },
    },

    series: [
      {
        data: fullTests,
        color: "#FFC2B6",
        marker: {
          symbol: "circle", // Set the marker symbol (shape)
          fillColor: "#FF7678", // Set the fill color of the markers (node color)
          lineColor: "#FFC2B6", // Set the border color of the markers
          lineWidth: 1, // Set the border width of the markers
          radius: 6, // Set the radius to control marker symbol size
        },
        name: "", // Series name (legend) for the line
      },
    ],
  };

  const tabsRendering = () => {
    if (loading) {
      return (
        <div className="w-[10%] mx-auto my-36">
          <Loader />
        </div>
      );
    } else if (error) {
      return (
        <p className="text-4xl mt-10 flex items-center justify-center">
          Something went wrong
        </p>
      );
    } else if (dataFetched) {
      if (data.length > 0) {
        return (
          <div className="w-full flex flex-col items-center my-10 justify-center">
            <Tab.Group>
              <Tab.List className="flex space-x-1 rounded-xl border-black border p-1 w-96 h-12">
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "w-full rounded-lg py-2.5 text-sm font-semibold leading-5 text-[#E31837]",
                      "ring-white ring-opacity-60 ring-offset-2 ring-offset-[#e318362b] focus:outline-none focus:ring-2",
                      selected
                        ? "bg-[#E31837] shadow text-white"
                        : "hover:bg-[#e318362b] hover:text-[#E31837]"
                    )
                  }
                >
                  Graphical View
                </Tab>
                <Tab
                  className={({ selected }) =>
                    classNames(
                      "w-full rounded-lg py-2.5 text-sm font-semibold leading-5 text-[#E31837]",
                      "ring-white ring-opacity-60 ring-offset-2 ring-offset-[#e318362b] focus:outline-none focus:ring-2",
                      selected
                        ? "bg-[#E31837] shadow text-white"
                        : "hover:bg-[#e318362b] hover:text-[#E31837]"
                    )
                  }
                >
                  Tabular view
                </Tab>
              </Tab.List>
              <Tab.Panels className="w-11/12 h-fit">
                <Tab.Panel>
                  {
                    <GraphData
                      totalTestOptions={totalTestOptions}
                      CompletedTestOptions={CompletedTestOptions}
                      partiallyCompletedTestOptions={
                        partiallyCompletedTestOptions
                      }
                      part1TestOptions={part1TestOptions}
                      part2TestOptions={part2TestOptions}
                      part3TestOptions={part3TestOptions}
                      fullTestOptions={fullTestOptions}
                    />
                  }
                </Tab.Panel>
                <Tab.Panel>{<TabularData tableData={data} />}</Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        );
      } else {
        return <p className="text-center text-xl mt-20">No Data</p>;
      }
    } else {
      return <></>;
    }
  };

  const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(" ");
  };

  return (
    <>
      <section className="space-y-7">
        <div className="flex space-x-10">
          <div>
            <p>Select From date</p>
            <input
              id="from-date-picker"
              type="date"
              className="border w-72 rounded px-2 py-1"
              max={toDate || currentDate}
              value={fromDate}
              onChange={(e) => handleFromDateChange(e)}
            />
          </div>
          <div>
            <p>Select To date</p>
            <input
              id="to-date-picker"
              type="date"
              className="border w-72 rounded px-2 py-1"
              min={minToDate}
              max={currentDate}
              value={toDate}
              onChange={(e) => handleToDateChange(e)}
            />
          </div>
        </div>
        <button
          onClick={handleSubmit}
          disabled={fromDate === "" || toDate === "" || disabled}
          className={`text-white py-2 w-52  mx-auto rounded-full ${
            fromDate === "" || toDate === "" || disabled
              ? "bg-gray-300"
              : "bg-[#E31837]"
          }`}
        >
          Submit
        </button>
      </section>

      <section>{tabsRendering()}</section>
    </>
  );
};

const GraphData = (props: any) => {
  const {
    totalTestOptions,
    CompletedTestOptions,
    partiallyCompletedTestOptions,
    part1TestOptions,
    part2TestOptions,
    part3TestOptions,
    fullTestOptions,
  } = props;
  return (
    <div className="mt-16 space-y-10">
      <HighchartsReact highcharts={Highcharts} options={totalTestOptions} />
      <HighchartsReact highcharts={Highcharts} options={CompletedTestOptions} />
      <HighchartsReact
        highcharts={Highcharts}
        options={partiallyCompletedTestOptions}
      />
      <HighchartsReact highcharts={Highcharts} options={part1TestOptions} />
      <HighchartsReact highcharts={Highcharts} options={part2TestOptions} />
      <HighchartsReact highcharts={Highcharts} options={part3TestOptions} />
      <HighchartsReact highcharts={Highcharts} options={fullTestOptions} />
    </div>
  );
};

const TabularData = (props: any) => {
  const { tableData } = props;
  return (
    <table className="table-auto border-collapse mx-auto w-[80%] mt-16">
      <thead className="sticky -top-1 bg-white">
        <tr>
          <th className="py-2 px-4 border">Dates</th>
          <th className="py-2 px-4 border">Total Test Count</th>
          <th className="py-2 px-4 border">Completed Test's Count</th>
          <th className="py-2 px-4 border">Partially Completed Test's Count</th>
          <th className="py-2 px-4 border">Part 1</th>
          <th className="py-2 px-4 border">Part 2</th>
          <th className="py-2 px-4 border">Part 3</th>
          <th className="py-2 px-4 border">Full Test</th>
        </tr>
      </thead>
      <tbody>
        {tableData.map((item: any) => {
          return (
            <tr key={item.date}>
              <td className="py-2 px-4 border text-center">{item.date}</td>
              <td className="py-2 px-4 border text-center">
                {item.total_test}
              </td>
              <td className="py-2 px-4 border text-center">
                {item.complete_test}
              </td>
              <td className="py-2 px-4 border text-center">
                {item.incomplete_test}
              </td>
              <td className="py-2 px-4 border text-center">{item.part1}</td>
              <td className="py-2 px-4 border text-center">{item.part2}</td>
              <td className="py-2 px-4 border text-center">{item.part3}</td>
              <td className="py-2 px-4 border text-center">{item.full}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export const DashboardCustomization = () => {
  const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(" ");
  };

  return (
    <section className="space-y-7">
      <div className="w-full flex flex-col items-center my-10 justify-center border rounded-xl py-5">
        <Tab.Group>
          <Tab.List className="flex space-x-1 rounded-xl border-black border p-1 w-96 h-12">
            <Tab
              className={({ selected }) =>
                classNames(
                  "w-full rounded-lg py-2.5 text-sm font-semibold leading-5 text-[#E31837]",
                  "ring-white ring-opacity-60 ring-offset-2 ring-offset-[#e318362b] focus:outline-none focus:ring-2",
                  selected
                    ? "bg-[#E31837] shadow text-white"
                    : "hover:bg-[#e318362b] hover:text-[#E31837]"
                )
              }
            >
              User's dashboard
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  "w-full rounded-lg py-2.5 text-sm font-semibold leading-5 text-[#E31837]",
                  "ring-white ring-opacity-60 ring-offset-2 ring-offset-[#e318362b] focus:outline-none focus:ring-2",
                  selected
                    ? "bg-[#E31837] shadow text-white"
                    : "hover:bg-[#e318362b] hover:text-[#E31837]"
                )
              }
            >
              Single User
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  "w-full rounded-lg py-2.5 text-sm font-semibold leading-5 text-[#E31837]",
                  "ring-white ring-opacity-60 ring-offset-2 ring-offset-[#e318362b] focus:outline-none focus:ring-2",
                  selected
                    ? "bg-[#E31837] shadow text-white"
                    : "hover:bg-[#e318362b] hover:text-[#E31837]"
                )
              }
            >
              Bulk User
            </Tab>
          </Tab.List>
          <Tab.Panels className="w-11/12 h-fit">
            <Tab.Panel>{<UserDashboardData />}</Tab.Panel>
            <Tab.Panel>{<SingleUserUpdate />}</Tab.Panel>
            <Tab.Panel>{<BulkUserUpdate />}</Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </section>
  );
};

export const SingleUserUpdate = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [disabled, setDisabled] = useState(false);

  const handleMobileNumber = (value: any) => {
    setMobileNumber(value);
  };

  const handleModuleChange = (e: any) => {
    const { value, checked } = e.target;
    setCheckedItems((prev) => {
      if (checked) {
        return [...prev, value];
      } else {
        return prev.filter((item) => item !== value);
      }
    });
  };

  const handleSubmit = async () => {
    setDisabled(true);
    let errorResponse;
    let numericCheckedItems = checkedItems.map(Number);
    let payload = {
      mobile: `+${mobileNumber}`,
      dashboard: numericCheckedItems,
    };
    try {
      let response: any = await apiSauce.apisauce?.put(
        `/admin/dashboardaccess`,
        payload
      );
      errorResponse = response?.data?.detail;
      if (response?.status === 200) {
        toast.success(response?.data?.message || "updated Successfully");
        setDisabled(false);
        setMobileNumber("");
        setCheckedItems([]);
      } else {
        setDisabled(false);
        toast.error(response?.data?.detail);
      }
    } catch (err) {
      setDisabled(false);
      toast.error(errorResponse);
      console.error(err);
    }
  };
  return (
    <>
      <div>
        <p>User mobile number</p>
        <PhoneInput
          containerClass="w-72 h-12"
          containerStyle={{ borderColor: "#737E93", borderRadius: "5px" }}
          dropdownStyle={{ width: "18rem" }}
          country={"in"}
          value={mobileNumber}
          onChange={(val) => handleMobileNumber(val)}
        />
      </div>
      <div>
        <p className="my-2">Select the preferred modules:</p>
        <div className="space-x-5">
          <label>
            <input
              type="checkbox"
              className="mr-1"
              value="4001"
              checked={checkedItems.includes("4001")}
              onChange={handleModuleChange}
            />
            {""}Speaking
          </label>
          <label>
            <input
              type="checkbox"
              className="mr-1"
              value="4002"
              checked={checkedItems.includes("4002")}
              onChange={handleModuleChange}
            />
            {""}Writing
          </label>
          <label>
            <input
              type="checkbox"
              className="mr-1"
              value="4003"
              checked={checkedItems.includes("4003")}
              onChange={handleModuleChange}
            />
            {""}Reading
          </label>
          <label>
            <input
              type="checkbox"
              className="mr-1"
              value="4004"
              checked={checkedItems.includes("4004")}
              onChange={handleModuleChange}
            />
            {""}Listening
          </label>
        </div>
      </div>
      <button
        onClick={handleSubmit}
        disabled={mobileNumber === "" || checkedItems.length === 0 || disabled}
        className={`text-white py-2 w-52  mx-auto rounded-full my-5 ${
          mobileNumber === "" || checkedItems.length === 0
            ? "bg-gray-300"
            : "bg-[#E31837]"
        }`}
      >
        Submit
      </button>
    </>
  );
};

export const BulkUserUpdate = () => {
  const [inputValue, setInputValue] = useState("");
  const [checkedItems, setCheckedItems] = useState<string[]>([]);
  const [disabled, setDisabled] = useState(false);

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
  };

  const handleModuleChange = (e: any) => {
    const { value, checked } = e.target;
    setCheckedItems((prev) =>
      checked ? [...prev, value] : prev.filter((item) => item !== value)
    );
  };

  const handleSubmit = async () => {
    setDisabled(true);
    let errorResponse;
    const mobileNumbersSet = new Set(
      inputValue.split(",").map((number) => number.trim())
    );
    const mobileNumbers = Array.from(mobileNumbersSet);
    let numericCheckedItems = checkedItems.map(Number);
    let payload = {
      mobile: mobileNumbers,
      dashboard: numericCheckedItems,
    };
    try {
      let response: any = await apiSauce.apisauce?.put(
        `/admin/bulk/users/dashboardaccess`,
        payload
      );
      errorResponse = response?.data?.detail;
      if (response?.status === 200) {
        toast.success(response?.data?.message || "updated Successfully");
        setDisabled(false);
        setInputValue("");
        setCheckedItems([]);
      } else {
        setDisabled(false);
        toast.error(response?.data?.detail);
      }
    } catch (err) {
      setDisabled(false);
      toast.error(errorResponse);
      console.error(err);
    }
  };
  return (
    <>
      <div>
        <p>Mobile numbers:</p>
        <textarea
          className="border rounded p-2"
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Enter mobile numbers separated by commas"
          rows={2}
          cols={50}
        />
        <p className="text-[#E31837] text-xs">
          Note:Enter mobile numbers as +91XXXXXXXXXX, +91XXXXXXXXXX, with
          country code and separated by commas.
        </p>
      </div>
      <div>
        <p className="my-2">Select the preferred modules:</p>
        <div className="space-x-5">
          <label>
            <input
              type="checkbox"
              className="mr-1"
              value="4001"
              checked={checkedItems.includes("4001")}
              onChange={handleModuleChange}
            />
            {""}Speaking
          </label>
          <label>
            <input
              type="checkbox"
              className="mr-1"
              value="4002"
              checked={checkedItems.includes("4002")}
              onChange={handleModuleChange}
            />
            {""}Writing
          </label>
          <label>
            <input
              type="checkbox"
              className="mr-1"
              value="4003"
              checked={checkedItems.includes("4003")}
              onChange={handleModuleChange}
            />
            {""}Reading
          </label>
          <label>
            <input
              type="checkbox"
              className="mr-1"
              value="4004"
              checked={checkedItems.includes("4004")}
              onChange={handleModuleChange}
            />
            {""}Listening
          </label>
        </div>
      </div>
      <button
        onClick={handleSubmit}
        disabled={!inputValue || checkedItems.length === 0 || disabled}
        className={`text-white py-2 w-52  mx-auto rounded-full my-5 ${
          !inputValue || checkedItems.length === 0
            ? "bg-gray-300"
            : "bg-[#E31837]"
        }`}
      >
        Submit
      </button>
    </>
  );
};

export const UserDashboardData = () => {
  const [dashboardData, setDashboardData] = useState<string[]>([]);
  const [mobileNumber, setMobileNumber] = useState("");
  const [userMobileNumber, setUserMobileNumber] = useState("");

  const handleMobileNumber = (value: any) => {
    setMobileNumber(value);
  };

  const decodeDashboards = (dashboards: Array<any>) => {
    return dashboards.map((element) => {
      switch (element) {
        case 4001:
          return "SPEAKING";
        case 4002:
          return "WRITING";
        case 4003:
          return "READING";
        case 4004:
          return "LISTENING";
        default:
          return "UNKNOWN";
      }
    });
  };

  const fetchUserDashboardData = async () => {
    let payload = {
      mobile: `+${mobileNumber}`,
    };
    let response: any;
    try {
      response = await apiSauce.apisauce?.post(
        `/admin/view/dashboard/access`,
        payload
      );
      if (response.status === 200) {
        setUserMobileNumber(mobileNumber);
        setMobileNumber("");
        setDashboardData(decodeDashboards(response?.data));
      } else {
        toast.error(response?.data?.detail || "Error from server");
      }
    } catch {
      toast.error(response?.data?.detail || "Error from server");
    }
  };
  return (
    <div className="flex justify-between">
      <section className="w-1/2">
        <div>
          <p>User mobile number</p>
          <PhoneInput
            containerClass="w-72 h-12"
            containerStyle={{ borderColor: "#737E93", borderRadius: "5px" }}
            dropdownStyle={{ width: "18rem" }}
            country={"in"}
            value={mobileNumber}
            onChange={(val) => handleMobileNumber(val)}
          />
        </div>
        <button
          onClick={fetchUserDashboardData}
          disabled={!mobileNumber}
          className={`text-white py-2 w-52  mx-auto rounded-full my-5 ${
            mobileNumber === "" ? "bg-gray-300" : "bg-[#E31837]"
          }`}
        >
          Submit
        </button>
      </section>
      <section className="flex flex-col my-auto w-1/2">
        {dashboardData.length > 0 ? (
          <>
            <p>Dashboards for the user +{userMobileNumber}</p>
            <ol>
              {dashboardData.map((item) => (
                <li key={item}>
                  {"\u2192"} {item}
                </li>
              ))}
            </ol>
          </>
        ) : (
          <></>
        )}
      </section>
    </div>
  );
};

export const NewUserCreation = () => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState({
    email:false,
});
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const [emailAlert,setEmailAlert] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const handleMobileNumber = (value: any) => {
    setMobileNumber(value);
  };

  const handleInputChange = (e: any) => {
    const { value,name,checked } = e?.target;
    if(name === 'email'){
      setEmail(value);
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailPattern.test(value) || value.trim() === "") {
        setError({...error, email : false});
      } else {
        setError({...error, email : true});
      }
    }else if(name === 'name'){
     setName(value);
    }else if(name === "emailAlert"){
      setEmailAlert(checked);
    }

  };

  

  const handleDateChange = (e: any) => {
    const value = e.target.value;
    setDate(value);
  };

  const handleSubmit = async () => {
    setDisabled(true);
    const selectedDate = new Date(date);
    const year = selectedDate.getFullYear();
    const month = String(selectedDate.getMonth() + 1).padStart(2, "0");
    const day = String(selectedDate.getDate()).padStart(2, "0");
    const dateValue = `${year}-${month}-${day}`;
    let payload = {
      mobile: `+${mobileNumber}`,
      date: dateValue,
      email: email,
      name : name,
      emailAlert : emailAlert,
    };
    try {
      let response: any = await apiSauce.apisauce?.put(
        `/admin/create/user/proextesion`,
        payload
      );
      if (response?.status === 200) {
        toast.success(response?.data?.message || "updated Successfully");
        setMobileNumber("+91");
        setEmail("");
        setError({
          email:false
        });
        setName("");
        setDate("");
      } else {
        setDisabled(false);
        toast.error(response.data.detail || "Something went wrong");
      }
    } catch (err) {
      setDisabled(false);
      toast.error("Something went wrong");
      console.error(err);
    }
  };

  return (
    <section className="space-y-7">
      <div className="w-full sm:w-[60%] md:w-[50%] lg:w-[40%] grid grid-cols-1 lg:grid-cols-2 gap-5">
        <div>
          <p>User mobile number<span className="text-red-500">*</span></p>
          <PhoneInput
            containerClass="w-72 h-12"
            containerStyle={{ borderColor: "#737E93", borderRadius: "5px" }}
            dropdownStyle={{ width: "18rem" }}
            country={"in"}
            value={mobileNumber}
            onChange={(val) => handleMobileNumber(val)}
          />
        </div>

        <div>
          <p>User Email<span className="text-red-500">*</span></p>
          <input
            className="w-72 h-12 rounded-md border border-[#737E93] px-2 py-1"
            value={email}
            name="email"
            onChange={(e) => handleInputChange(e)}
          />
          {error.email && (
            <p className="text-sm text-red-500">
              Please enter a valid email address.
            </p>
          )}
        </div>

        <div>
          <p>User Name<span className="text-red-500">*</span></p>
          <input
            className="w-72 h-12 rounded-md border border-[#737E93] px-2 py-1"
            value={name}
            name="name"
            onChange={(e) => handleInputChange(e)}
          />
        </div>

        <div>
          <p>Pro plan expiry<span className="text-red-500">*</span></p>
          <input
            type="date"
            className="w-72 h-12 rounded-md border border-[#737E93] px-2 py-1"
            min={new Date().toISOString().split("T")[0]}
            value={date}
            onChange={(e) => handleDateChange(e)}
          />
        </div>

        <div className="flex items-center space-x-2">
          
          <input
            type="checkbox"
            className=" h-12 rounded-md border border-[#737E93] px-2 py-1"
            checked={emailAlert}
            name="emailAlert"
            onChange={(e) => handleInputChange(e)}
          />
          <p>Email Alert</p>
        </div>
      </div>
      <div>
        <button
          onClick={handleSubmit}
          disabled={mobileNumber === "+91" || date === "" || disabled}
          className={`text-white py-2 w-52  mx-auto rounded-full ${
            mobileNumber === "" ||
            date === "" ||
            email.trim() === "" ||
            name.trim() === "" ||
            error.email 
              ? "bg-gray-300"
              : "bg-[#E31837]"
          }`}
        >
          Submit
        </button>
      </div>
    </section>
  );
};
