import { useEffect } from "react";
import { useStore } from "../../Hooks/use-store";
import { useNavigate } from "react-router-dom";
import closeIcon from "../../icons/closeTest.png";
import profileImage from "../../icons/UserCircle.png";
import clockIcon from "../../icons/Alarm.png";
import ConfirmYourDetails from "../common/confirmYourDetails";
import PartTestCompletedCard from "../common/PartTestCompletedCard";
import { observer } from "mobx-react-lite";
import WritingTimer from "../common/writingTimer";
import ListeningPart2 from "./ListeningParts/ListeningPart2";
import ListeningPart3 from "./ListeningParts/ListeningPart3";
import TestSountIntroCard from "./TestSoundIntroCard";
import ListeningInstructions from "./ListeningInstructions";
import ListeningPart4 from "./ListeningParts/ListeningPart4";
import ListeningPart1 from "./ListeningParts/ListeningPart1";
import Loader from "../../Loader";
import Band_details from "../../constants/Band_details.json";
import {
  singleChoice,
  multipleChoice,
  planDiagram,
  tableCompletion,
  noteAndSentenceCompletion,
  shortAnswer,
} from "./ListeningAnswerTransformation";
import { Shellwrapper } from "../Routing/RoutingC";
import ErrorPage from "../../pages/errorPage";
import { caluculateScore, aiFeedbackPayload } from "../../utils";
import UserProfile from "../common/userProfile";

const FullTestListening = observer(() => {
  const { startTestStoreListening, resultsStoreListening, userStore } =
    useStore();
  const { stepManagement } = resultsStoreListening;
  const {
    errorMessage,
    isLoading,
    tryAgainButton,
    part1Questions,
    part2Questions,
    part3Questions,
    part4Questions,
    setPartOneScore,
    setPartTwoScore,
    setPartThreeScore,
    setPartFourScore,
    setPart1AllAnswers,
    setPart2AllAnswers,
    setPart3AllAnswers,
    part1SingleAnswer,
    part1MultipleAnswer,
    part1NoteCompletionAnswer,
    part1DragAndDropAnswers,
    part1SenetenceComplete,
    part1DraggedCurrentSentence,
    part1TableCompletionAnswer,
    part1MatchingSentenceEndAnswers,
    part1SummaryCompletionOneWordAnswers,
    part1TaskIdentifyingItemAnswers,
    part1ShortAnswer,
    part2SingleAnswer,
    part2MultipleAnswer,
    part2NoteCompletionAnswer,
    part2DragAndDropAnswers,
    part2SenetenceComplete,
    part2DraggedCurrentSentence,
    part2MatchingSentenceEndAnswers,
    part2TableCompletionAnswer,
    part2SummaryCompletionOneWordAnswers,
    part2TaskIdentifyingItemAnswers,
    part2ShortAnswer,
    part3SingleAnswer,
    part3MultipleAnswer,
    part3NoteCompletionAnswer,
    part3DragAndDropAnswers,
    part3SenetenceComplete,
    part3DraggedCurrentSentence,
    part3MatchingSentenceEndAnswers,
    part3TableCompletionAnswer,
    part3SummaryCompletionOneWordAnswers,
    part3TaskIdentifyingItemAnswers,
    part3ShortAnswer,
    setPart4AllAnswers,
    part4SingleAnswer,
    part4MultipleAnswer,
    part4NoteCompletionAnswer,
    part4DragAndDropAnswers,
    part4SenetenceComplete,
    part4DraggedCurrentSentence,
    part4MatchingSentenceEndAnswers,
    part4TableCompletionAnswer,
    part4SummaryCompletionOneWordAnswers,
    part4TaskIdentifyingItemAnswers,
    part4ShortAnswer,
  } = startTestStoreListening;
  const { currentStepName, currentPartName, nextPartName } = stepManagement;
  const BandDetails: any = Band_details;

  const navigate = useNavigate();

  const combineAnswers = (setPartAllAnswers: any, ...rest: any) => {
    setPartAllAnswers(rest.flat(Infinity));
  };

  const nextStep = async () => {
    //set the mockdata of part1,2,3 questions after integration cleanup this
    // startTestStoreListening.setPartOneQuestions();
    // startTestStoreListening.setPartTwoQuestions();
    // startTestStoreListening.setPartThreeQuestions();
    // startTestStoreListening.setPartFourQuestions();
    // Based on the currentStepName sethe stepvalues like currentStepName,currentPartName,nextPartName and also tabs at the bottom

    if (currentStepName === "confirm_your_details") {
      resultsStoreListening.setStepManagement("test_sound", "", "");
    }
    if (currentStepName === "test_sound") {
      resultsStoreListening.setStepManagement("writing_instructions", "", "");
    }

    if (currentStepName === "writing_instructions") {
      resultsStoreListening.setActiveTab(0);
      startTestStoreListening.setTimer(1920);
      resultsStoreListening.setStepManagement("Part 1", "", "");
    }

    if (currentStepName === "Part 1") {
      if (startTestStoreListening.testType !== "full") {
        resultsStoreListening.setStepManagement(
          "part_test_completed",
          "Part 1",
          ""
        );
      } else {
        resultsStoreListening.setStepManagement(
          "part_test_completed",
          "Part 1",
          "Part 2"
        );
      }
      combineAnswers(
        setPart1AllAnswers,
        part1SingleAnswer,
        part1MultipleAnswer,
        part1NoteCompletionAnswer,
        part1DragAndDropAnswers,
        part1SenetenceComplete,
        part1DraggedCurrentSentence,
        part1MatchingSentenceEndAnswers,
        part1TableCompletionAnswer,
        part1SummaryCompletionOneWordAnswers,
        part1TaskIdentifyingItemAnswers,
        part1ShortAnswer
      );
    }

    if (
      currentStepName === "part_test_completed" &&
      currentPartName === "Part 1"
    ) {
      resultsStoreListening.setActiveTab(1);
      resultsStoreListening.setStepManagement("Part 2", "Part 2", "Part 3");
    }

    if (currentStepName === "Part 2") {
      if (startTestStoreListening.testType != "full") {
        resultsStoreListening.setStepManagement(
          "part_test_completed",
          "Part 2",
          ""
        );
      } else {
        resultsStoreListening.setStepManagement(
          "part_test_completed",
          "Part 2",
          "Part 3"
        );
      }
      combineAnswers(
        setPart2AllAnswers,
        part2SingleAnswer,
        part2MultipleAnswer,
        part2NoteCompletionAnswer,
        part2DragAndDropAnswers,
        part2SenetenceComplete,
        part2DraggedCurrentSentence,
        part2MatchingSentenceEndAnswers,
        part2TableCompletionAnswer,
        part2SummaryCompletionOneWordAnswers,
        part2TaskIdentifyingItemAnswers,
        part2ShortAnswer
      );
    }

    if (
      currentStepName === "part_test_completed" &&
      currentPartName === "Part 2"
    ) {
      resultsStoreListening.setActiveTab(2);
      resultsStoreListening.setStepManagement("Part 3", "Part 3", " ");
    }

    if (currentStepName === "Part 3") {
      if (startTestStoreListening.testType != "full") {
        resultsStoreListening.setStepManagement(
          "part_test_completed",
          "Part 3",
          ""
        );
      } else {
        resultsStoreListening.setStepManagement(
          "part_test_completed",
          "Part 3",
          "Part 4"
        );
      }
      combineAnswers(
        setPart3AllAnswers,
        part3SingleAnswer,
        part3MultipleAnswer,
        part3NoteCompletionAnswer,
        part3DragAndDropAnswers,
        part3SenetenceComplete,
        part3DraggedCurrentSentence,
        part3MatchingSentenceEndAnswers,
        part3TableCompletionAnswer,
        part3SummaryCompletionOneWordAnswers,
        part3TaskIdentifyingItemAnswers,
        part3ShortAnswer
      );
    }

    if (
      currentStepName === "part_test_completed" &&
      currentPartName === "Part 3"
    ) {
      resultsStoreListening.setActiveTab(3);
      resultsStoreListening.setStepManagement("Part 4", "Part 4", "Part 4");
    }
    if (currentStepName === "Part 4") {
      resultsStoreListening.setStepManagement(
        "part_test_completed",
        "Part 4",
        ""
      );
      combineAnswers(
        setPart4AllAnswers,
        part4SingleAnswer,
        part4MultipleAnswer,
        part4NoteCompletionAnswer,
        part4DragAndDropAnswers,
        part4SenetenceComplete,
        part4DraggedCurrentSentence,
        part4MatchingSentenceEndAnswers,
        part4TableCompletionAnswer,
        part4SummaryCompletionOneWordAnswers,
        part4TaskIdentifyingItemAnswers,
        part4ShortAnswer
      );
    }

    if (
      currentStepName === "part_test_completed" &&
      currentPartName === "Part 4"
    ) {
      resultsStoreListening.setActiveTab(4);
      resultsStoreListening.setStepManagement("confirm_your_details", "", "");
      startTestStoreListening.setTimer(1920);
      navigate("/listening");
    }
  };

  const submitTest = () => {
    resultsStoreListening.setStepManagement("confirm_your_details", "", "");
    navigate("/listening");
  };

  const caluculateBandScorePartOne = (partMarks: number,part : string): number => {
    switch (true) {
      case partMarks < 1:
        return 0;
      case partMarks < 2:
        return 2;
      case partMarks < 3:
        return 3;
      case partMarks < 4:
        return 4;
      case partMarks < 5:
        return 5;
      case partMarks < 6:
        return 5.5;
      case partMarks < 7:
        return 6;
      case partMarks < 8:
        return 6.5;
      case partMarks < 9:
        return 7.5;
      case partMarks < 10:
        return 8;
      default:
        return 9;
    }
  };

  const answerSheetTransformation = (answerSheet: any) => {
    let fullAnswerSheet: any[] = [];
    answerSheet?.forEach((element: any) => {
      let questionType = element.questionType;
      let resultedAnswer;
      switch (questionType) {
        case "Multiple choice (More than one answer)":
          resultedAnswer = multipleChoice(element);
          fullAnswerSheet.push(resultedAnswer);
          break;
        case "Multiple choice":
          resultedAnswer = singleChoice(element);
          fullAnswerSheet.push(resultedAnswer);
          break;
        case "Note Completion":
          case "Sentence Completion":
          resultedAnswer = noteAndSentenceCompletion(element);
          fullAnswerSheet.push(resultedAnswer);
          break;
        case "Table Completion":
          resultedAnswer = tableCompletion(element);
          fullAnswerSheet.push(resultedAnswer);
          break;
        case "Short Answer":
          resultedAnswer = shortAnswer(element);
          fullAnswerSheet.push(resultedAnswer);
          break;
        case "plan/map/diagram labelling":
          resultedAnswer = planDiagram(element);
          fullAnswerSheet.push(resultedAnswer);
          break;
      }
    });
    return fullAnswerSheet;
  };

  const submitAnswer = async () => {
    // the below data should come from BE;
    const now = new Date();

    // Get the components of the date and time
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(now.getDate()).padStart(2, "0");
    const formattedDateTime = `${year}-${month}-${day}`;
    if (startTestStoreListening.testType === "full") {
      combineAnswers(
        setPart1AllAnswers,
        part1SingleAnswer,
        part1MultipleAnswer,
        part1NoteCompletionAnswer,
        part1DragAndDropAnswers,
        part1SenetenceComplete,
        part1DraggedCurrentSentence,
        part1MatchingSentenceEndAnswers,
        part1TableCompletionAnswer,
        part1SummaryCompletionOneWordAnswers,
        part1TaskIdentifyingItemAnswers,
        part1ShortAnswer
      );
      combineAnswers(
        setPart2AllAnswers,
        part2SingleAnswer,
        part2MultipleAnswer,
        part2NoteCompletionAnswer,
        part2DragAndDropAnswers,
        part2SenetenceComplete,
        part2DraggedCurrentSentence,
        part2MatchingSentenceEndAnswers,
        part2TableCompletionAnswer,
        part2SummaryCompletionOneWordAnswers,
        part2TaskIdentifyingItemAnswers,
        part2ShortAnswer
      );
      combineAnswers(
        setPart3AllAnswers,
        part3SingleAnswer,
        part3MultipleAnswer,
        part3NoteCompletionAnswer,
        part3DragAndDropAnswers,
        part3SenetenceComplete,
        part3DraggedCurrentSentence,
        part3MatchingSentenceEndAnswers,
        part3TableCompletionAnswer,
        part3SummaryCompletionOneWordAnswers,
        part3TaskIdentifyingItemAnswers,
        part3ShortAnswer
      );
      combineAnswers(
        setPart4AllAnswers,
        part4SingleAnswer,
        part4MultipleAnswer,
        part4NoteCompletionAnswer,
        part4DragAndDropAnswers,
        part4SenetenceComplete,
        part4DraggedCurrentSentence,
        part4MatchingSentenceEndAnswers,
        part4TableCompletionAnswer,
        part4SummaryCompletionOneWordAnswers,
        part4TaskIdentifyingItemAnswers,
        part4ShortAnswer
      );
    }
    if (startTestStoreListening.testType === "part1") {
      combineAnswers(
        setPart1AllAnswers,
        part1SingleAnswer,
        part1MultipleAnswer,
        part1NoteCompletionAnswer,
        part1DragAndDropAnswers,
        part1SenetenceComplete,
        part1DraggedCurrentSentence,
        part1MatchingSentenceEndAnswers,
        part1TableCompletionAnswer,
        part1SummaryCompletionOneWordAnswers,
        part1TaskIdentifyingItemAnswers,
        part1ShortAnswer
      );
    }
    if (startTestStoreListening.testType === "part2") {
      combineAnswers(
        setPart2AllAnswers,
        part2SingleAnswer,
        part2MultipleAnswer,
        part2NoteCompletionAnswer,
        part2DragAndDropAnswers,
        part2SenetenceComplete,
        part2DraggedCurrentSentence,
        part2MatchingSentenceEndAnswers,
        part2TableCompletionAnswer,
        part2SummaryCompletionOneWordAnswers,
        part2TaskIdentifyingItemAnswers,
        part2ShortAnswer
      );
    }
    if (startTestStoreListening.testType === "part3") {
      combineAnswers(
        setPart3AllAnswers,
        part3SingleAnswer,
        part3MultipleAnswer,
        part3NoteCompletionAnswer,
        part3DragAndDropAnswers,
        part3SenetenceComplete,
        part3DraggedCurrentSentence,
        part3MatchingSentenceEndAnswers,
        part3TableCompletionAnswer,
        part3SummaryCompletionOneWordAnswers,
        part3TaskIdentifyingItemAnswers,
        part3ShortAnswer
      );
    }
    if (startTestStoreListening.testType === "part4") {
      combineAnswers(
        setPart4AllAnswers,
        part4SingleAnswer,
        part4MultipleAnswer,
        part4NoteCompletionAnswer,
        part4DragAndDropAnswers,
        part4SenetenceComplete,
        part4DraggedCurrentSentence,
        part4MatchingSentenceEndAnswers,
        part4TableCompletionAnswer,
        part4SummaryCompletionOneWordAnswers,
        part4TaskIdentifyingItemAnswers,
        part4ShortAnswer
      );
    }

    let res = await startTestStoreListening.submitAnswerListening();
    if (res && res.status === 200) {
      if (startTestStoreListening.testType === "full") {
        let partOneAnswerSheet = answerSheetTransformation(
          res.data.answerSheet[0]?.data.questions
        );
        let partTWOAnswerSheet = answerSheetTransformation(
          res.data.answerSheet[1]?.data.questions
        );
        let partThreeAnswerSheet = answerSheetTransformation(
          res.data.answerSheet[2]?.data.questions
        );
        let partFourAnswerSheet = answerSheetTransformation(
          res.data.answerSheet[3]?.data.questions
        );
        partOneAnswerSheet = [].concat(...partOneAnswerSheet);
        partTWOAnswerSheet = [].concat(...partTWOAnswerSheet);
        partThreeAnswerSheet = [].concat(...partThreeAnswerSheet);
        partFourAnswerSheet = [].concat(...partFourAnswerSheet);

        if (startTestStoreListening.partType === "part4") {
          let part1Marks = caluculateScore(
            startTestStoreListening.part1Answers,
            partOneAnswerSheet
          );
          let part2Marks = caluculateScore(
            startTestStoreListening.part2Answers,
            partTWOAnswerSheet
          );
          let part3Marks = caluculateScore(
            startTestStoreListening.part3Answers,
            partThreeAnswerSheet
          );
          let part4Marks = caluculateScore(
            startTestStoreListening.part4Answers,
            partFourAnswerSheet
          );
          let apiPayload: any = {
            testId: startTestStoreListening.testId,
            score: {
              partType: startTestStoreListening.testType,
              part1UserScore: part1Marks,
              part2UserScore: part2Marks,
              part3UserScore: part3Marks,
              part4UserScore: part4Marks,
              overAllUserScore:
                part1Marks + part2Marks + part3Marks + part4Marks,
            },
            //overallScore:overall
            test_type: startTestStoreListening.testType,
          };
          let overallScorePart1 = caluculateBandScorePartOne(
            part1Marks,
            "part1"
          );
          let bandNumberPart1 =
            overallScorePart1 && Math.round(overallScorePart1);
          let bandNumberStringPart1 = "band_" + bandNumberPart1;
          let detailedReportPart1 =
            bandNumberStringPart1 &&
            BandDetails[`${bandNumberStringPart1}`];

          let overallScorePart2 = caluculateBandScorePartOne(
            part2Marks,
            "part2"
          );
          let bandNumberPart2 =
            overallScorePart2 && Math.round(overallScorePart2);
          let bandNumberStringPart2 = "band_" + bandNumberPart2;
          let detailedReportPart2 =
            bandNumberStringPart2 &&
            BandDetails[`${bandNumberStringPart2}`];

          let overallScorePart3 = caluculateBandScorePartOne(
            part3Marks,
            "part3"
          );
          let bandNumberPart3 =
            overallScorePart3 && Math.round(overallScorePart3);
          let bandNumberStringPart3 = "band_" + bandNumberPart3;
          let detailedReportPart3 =
            bandNumberStringPart3 &&
            BandDetails[`${bandNumberStringPart3}`];

          let overallScorePart4 = caluculateBandScorePartOne(
            part4Marks,
            "part4"
          );
          let bandNumberPart4 =
            overallScorePart4 && Math.round(overallScorePart4);
          let bandNumberStringPart4 = "band_" + bandNumberPart4;
          let detailedReportPart4 =
            bandNumberStringPart4 &&
            BandDetails[`${bandNumberStringPart4}`];
          const overall_score_full =
            (overallScorePart1 ??
              overallScorePart2 ??
              overallScorePart3 ??
              overallScorePart4) != null
              ? (overallScorePart1 +
                  overallScorePart2 +
                  overallScorePart3 +
                  overallScorePart4) /
                4
              : 0;
          if (!apiPayload["data"]) {
            apiPayload["data"] = {};
          }
          if (!apiPayload["aiFeedback"]) {
            apiPayload["aiFeedback"] = {};
          }
          apiPayload["data"]["date"] = formattedDateTime;
          apiPayload["data"]["overallBand"] = overall_score_full;
          apiPayload["data"]["part1"] = detailedReportPart1;
          apiPayload["data"]["part1"]["overall_score"] = overallScorePart1;
          apiPayload["data"]["part1"]["date"] = formattedDateTime;
          apiPayload["data"]["part2"] = detailedReportPart2;
          apiPayload["data"]["part2"]["overall_score"] = overallScorePart2;
          apiPayload["data"]["part2"]["date"] = formattedDateTime;
          apiPayload["data"]["part3"] = detailedReportPart3;
          apiPayload["data"]["part3"]["overall_score"] = overallScorePart3;
          apiPayload["data"]["part3"]["date"] = formattedDateTime;
          apiPayload["data"]["part4"] = detailedReportPart4;
          apiPayload["data"]["part4"]["overall_score"] = overallScorePart4;
          apiPayload["data"]["part4"]["date"] = formattedDateTime;

          setPartOneScore(part1Marks);
          setPartTwoScore(part2Marks);
          setPartThreeScore(part3Marks);
          setPartFourScore(part4Marks);

          let part1AiPayload = aiFeedbackPayload(
            startTestStoreListening.part1Answers,
            partOneAnswerSheet,
            "listening"
          );
          let part2AiPayload = aiFeedbackPayload(
            startTestStoreListening.part2Answers,
            partTWOAnswerSheet,
            "listening"
          );
          let part3AiPayload = aiFeedbackPayload(
            startTestStoreListening.part3Answers,
            partThreeAnswerSheet,
            "listening"
          );
          let part4AiPayload = aiFeedbackPayload(
            startTestStoreListening.part4Answers,
            partFourAnswerSheet,
            "listening"
          );
          apiPayload["aiFeedback"]["part1"] = {
            audio: part1Questions.audioFile.resourceName,
            questions: part1AiPayload,
          };
          apiPayload["aiFeedback"]["part2"] = {
            audio: part2Questions.audioFile.resourceName,
            questions: part2AiPayload,
          };
          apiPayload["aiFeedback"]["part3"] = {
            audio: part3Questions.audioFile.resourceName,
            questions: part3AiPayload,
          };
          apiPayload["aiFeedback"]["part4"] = {
            audio: part4Questions.audioFile.resourceName,
            questions: part4AiPayload,
          };
          startTestStoreListening.completeTest(apiPayload);
        }
      } else {
        let partTypeAnswers;
        if (startTestStoreListening.partType === "part1") {
          let partOneAnswerSheet = answerSheetTransformation(
            res.data.answerSheet[0]?.data.questions
          );
          partOneAnswerSheet = [].concat(...partOneAnswerSheet);
          partTypeAnswers = startTestStoreListening.part1Answers;
          let part1Marks = caluculateScore(
            partTypeAnswers,
            partOneAnswerSheet
          );
          let apiPayloadPart1: any = {
            testId: startTestStoreListening.testId,
            score: {
              partType: "part1",
              part1UserScore: part1Marks,
              part1TotalScore:
                res.data.answerSheet?.[0]?.data?.questions.length, //
            },
            test_type: "part1",
          };
          let overall_score = caluculateBandScorePartOne(part1Marks, "part1");
          let band_number = overall_score && Math.round(overall_score);
          let band_number_string = "band_" + band_number;
          let detailedReport =
            band_number_string && BandDetails[`${band_number_string}`];
          if (!apiPayloadPart1["data"]) {
            apiPayloadPart1["data"] = {};
          }
          if (!apiPayloadPart1["aiFeedback"]) {
            apiPayloadPart1["aiFeedback"] = {};
          }
          apiPayloadPart1["data"]["overallBand"] = overall_score;
          apiPayloadPart1["data"]["date"] = formattedDateTime;
          apiPayloadPart1["data"]["part1"] = detailedReport;
          apiPayloadPart1["data"]["part1"]["overall_score"] = overall_score;
          apiPayloadPart1["data"]["part1"]["date"] = formattedDateTime;

          setPartOneScore(part1Marks);
          let part1AiPayload = aiFeedbackPayload(
            startTestStoreListening.part1Answers,
            partOneAnswerSheet,
            "listening"
          );
          apiPayloadPart1["aiFeedback"]["part1"] = {
            audio: part1Questions.audioFile.resourceName,
            questions: part1AiPayload,
          };

          startTestStoreListening.completeTest(apiPayloadPart1);
        } else if (startTestStoreListening.partType === "part2") {
          let partTwoAnswerSheet = answerSheetTransformation(
            res.data.answerSheet[0]?.data.questions
          );
          partTwoAnswerSheet = [].concat(...partTwoAnswerSheet);
          partTypeAnswers = startTestStoreListening.part2Answers;
          let part2Marks = caluculateScore(
            partTypeAnswers,
            partTwoAnswerSheet
          );
          let apiPayloadPart2: any = {
            testId: startTestStoreListening.testId,
            score: {
              partType: "part2",
              part2UserScore: part2Marks,
              part2TotalScore:
                res.data.answerSheet?.[0]?.data?.questions.length,
            },
            test_type: "part2", //
          };
          let overall_score = caluculateBandScorePartOne(part2Marks, "part2");
          let band_number = overall_score && Math.round(overall_score);
          let band_number_string = "band_" + band_number;
          let detailedReport =
            band_number_string && BandDetails[`${band_number_string}`];
          if (!apiPayloadPart2["data"]) {
            apiPayloadPart2["data"] = {};
          }
          if (!apiPayloadPart2["aiFeedback"]) {
            apiPayloadPart2["aiFeedback"] = {};
          }
          apiPayloadPart2["data"]["part2"] = detailedReport;
          apiPayloadPart2["data"]["overallBand"] = overall_score;
          apiPayloadPart2["data"]["part2"]["overall_score"] = overall_score;
          apiPayloadPart2["data"]["date"] = formattedDateTime;
          setPartTwoScore(part2Marks);

          let part2AiPayload = aiFeedbackPayload(
            startTestStoreListening.part2Answers,
            partTwoAnswerSheet,
            "listening"
          );
          apiPayloadPart2["aiFeedback"]["part2"] = {
            audio: part2Questions.audioFile.resourceName,
            questions: part2AiPayload,
          };
          startTestStoreListening.completeTest(apiPayloadPart2);
        } else if (startTestStoreListening.partType === "part3") {
          let partThreeAnswerSheet = answerSheetTransformation(
            res.data.answerSheet[0]?.data.questions
          );
          partThreeAnswerSheet = [].concat(...partThreeAnswerSheet);
          partTypeAnswers = startTestStoreListening.part3Answers;
          let part3Marks = caluculateScore(
            partTypeAnswers,
            partThreeAnswerSheet
          );
          let apiPayloadPart3: any = {
            testId: startTestStoreListening.testId,
            score: {
              partType: "part3",
              part3UserScore: part3Marks,
              part3TotalScore:
                res.data.answerSheet?.[0]?.data?.questions.length, //
            },
            test_type: "part3",
          };
          let overall_score = caluculateBandScorePartOne(part3Marks, "part3");
          let band_number = overall_score && Math.round(overall_score);
          let band_number_string = "band_" + band_number;
          let detailedReport =
            band_number_string && BandDetails[`${band_number_string}`];
          if (!apiPayloadPart3["data"]) {
            apiPayloadPart3["data"] = {};
          }
          if (!apiPayloadPart3["aiFeedback"]) {
            apiPayloadPart3["aiFeedback"] = {};
          }
          apiPayloadPart3["data"]["part3"] = detailedReport;
          apiPayloadPart3["data"]["overallBand"] = overall_score;
          apiPayloadPart3["data"]["part3"]["overall_score"] = overall_score;
          apiPayloadPart3["data"]["date"] = formattedDateTime;
          let part3AiPayload = aiFeedbackPayload(
            startTestStoreListening.part3Answers,
            partThreeAnswerSheet,
            "listening"
          );
          apiPayloadPart3["aiFeedback"]["part3"] = {
            audio: part3Questions.audioFile.resourceName,
            questions: part3AiPayload,
          };

          setPartThreeScore(part3Marks);
          startTestStoreListening.completeTest(apiPayloadPart3);
        } else {
          let partFourAnswerSheet = answerSheetTransformation(
            res.data.answerSheet[0]?.data.questions
          );
          partFourAnswerSheet = [].concat(...partFourAnswerSheet);
          partTypeAnswers = startTestStoreListening.part4Answers;
          let part4Marks = caluculateScore(
            partTypeAnswers,
            partFourAnswerSheet
          );
          let apiPayloadPart4: any = {
            testId: startTestStoreListening.testId,
            score: {
              partType: "part4",
              part3UserScore: part4Marks,
              part3TotalScore:
                res.data.answerSheet?.[0]?.data?.questions.length, //
            },
            test_type: "part4",
          };
          let overall_score = caluculateBandScorePartOne(part4Marks, "part4");
          let band_number = overall_score && Math.round(overall_score);
          let band_number_string = "band_" + band_number;
          let detailedReport =
            band_number_string && BandDetails[`${band_number_string}`];
          if (!apiPayloadPart4["data"]) {
            apiPayloadPart4["data"] = {};
          }
          if (!apiPayloadPart4["aiFeedback"]) {
            apiPayloadPart4["aiFeedback"] = {};
          }
          apiPayloadPart4["data"]["part4"] = detailedReport;
          apiPayloadPart4["data"]["overallBand"] = overall_score;
          apiPayloadPart4["data"]["part4"]["overall_score"] = overall_score;
          apiPayloadPart4["data"]["date"] = formattedDateTime;
          let part4AiPayload = aiFeedbackPayload(
            startTestStoreListening.part4Answers,
            partFourAnswerSheet,
            "listening"
          );
          apiPayloadPart4["aiFeedback"]["part4"] = {
            audio: part4Questions.audioFile.resourceName,
            questions: part4AiPayload,
          };

          setPartFourScore(part4Marks);
          startTestStoreListening.completeTest(apiPayloadPart4);
        }
      }
    }
  };

  const handleClose = (navigation : boolean) => {
    //navigate to the listening home page
    navigation && navigate("/listening");
    startTestStoreListening.setCompleteTestFailed(false);
    startTestStoreListening.setIsTestEnded(null);
    //reset the step management to initial step
    resultsStoreListening.setStepManagement("confirm_your_details", "", "");
    // for full test st the timer to maximum time like 1hour
    startTestStoreListening.setTimer(1920);
    //reset the testId,test Type
    startTestStoreListening.testId = null;
    startTestStoreListening.testType = null;
  };

  useEffect(() => {
    window.addEventListener("popstate", () => {
      // Perform your desired operations here
      resultsStoreListening.setStepManagement("confirm_your_details", "", "");
      startTestStoreListening.setTimer(1920);
      startTestStoreListening.testId = null;
      startTestStoreListening.testType = null;
      navigate("/listening");
    });
  });
  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
      localStorage.setItem("reload", "true");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    if (localStorage.getItem("reload")) {
      startTestStoreListening.setLoading(true);
      localStorage.removeItem("reload");
      resultsStoreListening.setStepManagement("confirm_your_details", "", "");
      navigate("/listening");
    } else {
      localStorage.removeItem("reload");
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [navigate]);

  const renderContent = () => {
    if (isLoading) {
      return(
        <div className="flex w-full flex-col mx-auto py-8 my-20 items-center ">
          <Loader />
        </div>
      );
    } else if (tryAgainButton) {
      return (
        <div>
          <Shellwrapper
            component={ErrorPage}
            errorMessage={errorMessage}
            onBackToHome={() => navigate("/listening")}
          />
        </div>
      );
    } else {
      return (
        <div>
          {/* for all the parts header to be same and it will be on the top so fixing the header in the parent component and based on the steps controlling the timer */}
          <header className="fixed top-0 left-0 w-full flex justify-between border-b border-b-[#E3E5E9] bg-white text-white p-4">
            <UserProfile 
              currentStepName={currentStepName}
              userName={userStore.studentDetails.studentName}
              profileImage={profileImage}
            />
            {(currentStepName === "Part 1" ||
              currentStepName === "Part 2" ||
              currentStepName === "Part 3" ||
              currentStepName === "Part 4") && (
              <div className="flex h-[25px] md:h-full my-auto">
                <img src={clockIcon} alt="clock" />
                <div className=" items-center">
                  <WritingTimer
                    store={startTestStoreListening}
                    resultStore={resultsStoreListening}
                  />
                </div>
              </div>
            )}
            <button className="flex flex-row h-[25px] md:h-full cursor-pointer" onClick={() => handleClose(true)}>
              <img src={closeIcon} alt="close" />
            </button>
          </header>
    
          {currentStepName === "confirm_your_details" && (
            <div className="flex flex-col min-h-screen items-center justify-center pt-16 w-full">
              <ConfirmYourDetails nextStep={nextStep} />
            </div>
          )}
    
          {currentStepName === "test_sound" && (
            <div className="min-h-screen pt-24">
              <TestSountIntroCard nextStep={nextStep} />
            </div>
          )}
    
          {currentStepName === "writing_instructions" && (
            <div className="min-h-screen pt-24">
              <ListeningInstructions nextStep={nextStep} />
            </div>
          )}
          {currentStepName === "Part 1" && (
            <div className="min-h-screen pt-16">
              <ListeningPart1 nextStep={nextStep} />{" "}
            </div>
          )}
          {currentStepName === "part_test_completed" && (
            <div className="min-h-screen pt-10 flex items-center justify-center">
              <PartTestCompletedCard
                nextStep={nextStep}
                currentPartName={currentPartName}
                nextPartName={nextPartName}
                startTeststore={startTestStoreListening}
                resultStore={resultsStoreListening}
                submitTest={submitTest}
                submitAnswer={submitAnswer}
                handleClose={handleClose}
                module="Reading"
              />
            </div>
          )}
          {currentStepName === "Part 2" && (
            <div className="min-h-screen pt-16">
              <ListeningPart2 nextStep={nextStep} />{" "}
            </div>
          )}
          {currentStepName === "Part 3" && (
            <div className="min-h-screen pt-16">
              <ListeningPart3 nextStep={nextStep} />{" "}
            </div>
          )}
          {currentStepName === "Part 4" && (
            <div className="min-h-screen pt-16">
              <ListeningPart4 nextStep={nextStep} />{" "}
            </div>
          )}
        </div>
      );
    }
  }

  return (
    <>
      {renderContent()}
    </>
  );
});

export default FullTestListening;