import { UserStore } from "./user-store";
import { DashboardStore } from "./dashboard-store";
import {UserApi}  from "../api/userapi";
import {TestResultsStore} from './test-results-store';
import { TestResultsApi } from "../api/test-results-api";
import { StartTestStore } from "./start-test-store";
import { StartTestApi } from "../api/start-test-api";
import {TestWritingResultsStore} from './test-writing-results-store';
import { PaymentStore } from "./payment-store";
import { PaymentApi } from "../api/payments-api";
import { WritingTestResultsApi } from "../api/writing-test-results-api";
import { StartWritingTestStore } from "./start-writing-test-store";
import { StartTestWritingApi } from "../api/start-test-writing-api";
import { StartTestStoreReading } from "./start-test-store-reading";
import { StartTestReadingApi } from "../api/start-test-reading-api";
import { ReadingTestResultsApi } from "../api/reading-test-results-api";
import { TestReadingResultsStore } from "./test-reading-results-store";
import { StartTestListeningApi } from "../api/start-test-listening-api";
import { TestListeningResultsStore } from "./test-listening-results-store";
import { StartTestStoreListening } from "./start-test-store-listening";
import { ListeningTestResultsApi } from "../api/listening-test-results-api";


export const RootStore = {
    userStore: new UserStore(new UserApi()),
    dashboardStore: new DashboardStore(),
    resultsStore:new TestResultsStore(new TestResultsApi()),
    startTestStore:new StartTestStore(new StartTestApi()),
    resultsStoreWriting : new TestWritingResultsStore(new WritingTestResultsApi()),
    paymentStore : new PaymentStore(new PaymentApi()),
    startTestStoreWriting:new StartWritingTestStore(new StartTestWritingApi()),
    startTestStoreReading:new StartTestStoreReading(new StartTestReadingApi()),
    resultsStoreReading:new TestReadingResultsStore(new ReadingTestResultsApi()),
    startTestStoreListening:new StartTestStoreListening(new StartTestListeningApi()),
    resultsStoreListening:new TestListeningResultsStore(new ListeningTestResultsApi())
  };