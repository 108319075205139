// components/CommonTable.tsx

import React from 'react';
import RenderInputFieldQuestion from './commonTableInputFields';
import { observer } from 'mobx-react-lite';

const CommonTable = observer(({
  headings,
  tbody,
  tableCompletionAnswer,
  inputHandler,
}: any) => (
  <table className="border border-[#E3E5E9] rounded-md ">
    <thead className="rounded">
      <tr className="bg-[#FFEAEA] ">
        {headings.map((item: any, index: any) => (
          <th key={item} className="py-4 pr-4">
            {item}
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      {tbody.map((row: any, rowIndex: any) => (
        <tr key={row[rowIndex]}>
          {Object.values(row).map((cell: any, cellIndex: any) => (
            <td key={cell[cellIndex]} className="border px-2 py-4">
              {<RenderInputFieldQuestion cell={cell} tableCompletionAnswer={tableCompletionAnswer} inputHandler={inputHandler}/>}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
));

export default CommonTable;
