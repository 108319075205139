const InstructionCard = ({ instructions, testType }: any) => {
  return (
    <div
      style={{
        background: "linear-gradient(216deg, #FFF9FA 1.21%, #FFF 97.42%)",
        border: "1px solid rgba(227, 24, 55, 0.30)",
      }}
      className="flex flex-col lg:w-1/2 md:w-1/2 w-[90%] mx-auto rounded-md border border-solid border-[rgba(227, 24, 55, 0.30)] bg-gradient-to-br from-[#FFF9FA] via-transparent to-[#FFF] p-5 pb-10 h-auto mx-10 "
    >
      {testType ? (
        <h1 className="text-2xl font-medium mb-6">{testType}</h1>
      ) : (
        <div className="text-[#292D35] text-24 font-semibold">
          Instructions
        </div>
      )}

      {instructions.map((item: any) => (
        <div className="text-[#292D35] mb-2 text-24" key={item}>
          {item}
        </div>
      ))}
    </div>
  );
};

export default InstructionCard;
