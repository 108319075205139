import { observer } from "mobx-react-lite";
import React from "react";

const SummaryCompletionOneWordFromText = ({
  item,
  twoWordAnswerSummaryCompletionInputValues,
  handleChangeSummaryCompletionOneWordAnswers,
}: any) => {
 
  const regex = /{qId:(\d+)}/g;
  return (
    <>
      <div className="text-[#3C424C] text-sm">
        {" "}
        Complete the summary. Write{" "}
        <span className="font-semibold">NO MORE THAN TWO WORDS</span> from the
        text for each answer:
      </div>
      <div className="my-2 text-[#3C424C] text-base text-justify leading-8 pb-4">
        { item?.["SummaryCompletionselectingWordFromText"]['passage']
            .split(regex)
            .map((part: any, index: any) => {
              if (index % 2 === 0) {
                return part;
              } else {
                const qId = part;
                
                return (
                  <input
                    key={qId}
                    type="text"
                    value={twoWordAnswerSummaryCompletionInputValues(qId)}
                    placeholder={` Enter answer for  ${qId}`}
                    className="border border-[#E3E5E9] rounded-lg p-1 my-1"
                    onChange={(e) => {
                      twoWordAnswerSummaryCompletionInputValues(qId)
                       handleChangeSummaryCompletionOneWordAnswers(
                      qId,
                      e.target.value
                    )}
                      
                      
                    }
                  />
                );
              }
            })}
      </div>
    </>
  );
};

export default observer(SummaryCompletionOneWordFromText);
