import { makePersistable } from "mobx-persist-store";
import { makeAutoObservable } from "mobx";
import { StartTestListeningApi } from "../api/start-test-listening-api";

interface Part1Data {
  test_id: number;
  data: {
    audiofile: Array<{
      name: string;
      comment: string;
      fileName: string;
      extension: string;
      uploadDate: string;
      resourceName: string;
    }>;
    questions: Array<{
      questionType: string;
      questionRange: string;
      listOfQuestions: Array<{
        items: Record<string, any>;
        answers: Array<Record<string, any>>;
        options: Array<string>;
        questionId: string;
        questionName: string;
        questionImage: Array<string>;
        additionalProps: Record<string, any>;
      }>;
      questionRefrenceId: string;
      questionDescription: string;
    }>;
  };
  type: string;
}


type multipleAnswers = {
  qId: number; // Assuming questionId is always a number
  answer: any[];
};
type noteCompletionAnsewrs = {
  qId: number;
  answer: any;
};
type summaryCompletionOneWordAnswers = {
  qId: number;
  twoWoedAnswer: any;
};
type dragAndDropAnswers = {
  newIndex: any;
  draggedItem: any;
};
type taskIdentifying = {
  qId: number;
  optionValue: any;
};
type SenetenceComplete = {
  qId: number;
  answer: any;
};
type matchingSentenceEnd = {
  newIndex: any;
  draggedItem: any;
};
type tableCompletion = {
  qId: number; // Assuming questionId is always a number
  answer: any[];
};
type shortAnswer = {
  qId: number;
  answer: any;
};
type planDiagram = {
  qId: number;
  answer: any;
}
interface ResultData {
  detail: string; 
}

export class StartTestStoreListening {
  statusCode: any;
  errorMessage: any;
  testId: any = null;
  testType = null;
  partType = null;
  freeTestData = [];
  part1Questions: any = {};
  part2Questions: any = {};
  part3Questions: any = {};
  part4Questions: any = {};
  part1Audio: string = "";
  part2Audio: string = "";
  part3Audio: string = "";
  part4Audio: string = "";
  timer: any = 1920;
  storeName: any = "listening";
  isLoading: boolean = false;
  tryAgainButton: boolean = false;
  isTestEnded: any = null;
  part1Answers: any = [];
  part2Answers: any = [];
  part3Answers: any = [];
  part4Answers: any = [];
  part1SingleAnswer: any = [];
  part1MultipleAnswer: multipleAnswers[] = [];
  part1NoteCompletionAnswer: noteCompletionAnsewrs[] = [];
  part1SummaryCompletionOneWordAnswers: summaryCompletionOneWordAnswers[] = [];
  part1DragAndDropAnswers: dragAndDropAnswers[] = [];
  part1TaskIdentifyingItemAnswers: taskIdentifying[] = [];
  part1SenetenceComplete: SenetenceComplete[] = [];
  part1DraggedCurrentSentence: dragAndDropAnswers[] = [];
  part1MatchingSentenceEndAnswers: matchingSentenceEnd[] = [];
  part1TableCompletionAnswer: tableCompletion[] = [];
  part1ShortAnswer: shortAnswer[] = [];
  part1PlanDiagramAnswer: planDiagram[] = [];
  part2SingleAnswer: any = [];
  part2MultipleAnswer: multipleAnswers[] = [];
  part2NoteCompletionAnswer: noteCompletionAnsewrs[] = [];
  part2SummaryCompletionOneWordAnswers: summaryCompletionOneWordAnswers[] = [];
  part2DragAndDropAnswers: dragAndDropAnswers[] = [];
  part2TaskIdentifyingItemAnswers: taskIdentifying[] = [];
  part2SenetenceComplete: SenetenceComplete[] = [];
  part2DraggedCurrentSentence: dragAndDropAnswers[] = [];
  part2MatchingSentenceEndAnswers: matchingSentenceEnd[] = [];
  part2TableCompletionAnswer: tableCompletion[] = [];
  part2ShortAnswer: shortAnswer[] = [];
  part2PlanDiagramAnswer: planDiagram[] = [];
  part3SingleAnswer: any = [];
  part3MultipleAnswer: multipleAnswers[] = [];
  part3NoteCompletionAnswer: noteCompletionAnsewrs[] = [];
  part3SummaryCompletionOneWordAnswers: summaryCompletionOneWordAnswers[] = [];
  part3DragAndDropAnswers: dragAndDropAnswers[] = [];
  part3TaskIdentifyingItemAnswers: taskIdentifying[] = [];
  part3SenetenceComplete: SenetenceComplete[] = [];
  part3DraggedCurrentSentence: dragAndDropAnswers[] = [];
  part3MatchingSentenceEndAnswers: matchingSentenceEnd[] = [];
  part3TableCompletionAnswer: tableCompletion[] = [];
  part3ShortAnswer: shortAnswer[] = [];
  part3PlanDiagramAnswer: planDiagram[] = [];
  part4SingleAnswer: any = [];
  part4MultipleAnswer: multipleAnswers[] = [];
  part4NoteCompletionAnswer: noteCompletionAnsewrs[] = [];
  part4SummaryCompletionOneWordAnswers: summaryCompletionOneWordAnswers[] = [];
  part4DragAndDropAnswers: dragAndDropAnswers[] = [];
  part4TaskIdentifyingItemAnswers: taskIdentifying[] = [];
  part4SenetenceComplete: SenetenceComplete[] = [];
  part4DraggedCurrentSentence: dragAndDropAnswers[] = [];
  part4MatchingSentenceEndAnswers: matchingSentenceEnd[] = [];
  part4TableCompletionAnswer: tableCompletion[] = [];
  part4ShortAnswer: shortAnswer[] = [];
  part4PlanDiagramAnswer: planDiagram[] = [];
  isFreeTestLoading = false;
  //Overall Scores
  partOneScore: any = null;
  partTwoScore: any = null;
  partThreeScore: any = null;
  partFourScore: any = null;
  overallScore: any = null;

  //answersheets from Be
  answerSheet: any = [];
  part1AnswerSheet: any = [];
  part2AnswerSheet: any = [];
  part3AnswerSheet: any = [];
  part4AnswerSheet: any = [];

  completeTestFailed: boolean = false;

  constructor(private startTestListeningAPi: StartTestListeningApi) {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "StartListeningTestStore",
      properties: [
        "testType",
        "tryAgainButton",
        "completeTestFailed",
        "part1Questions",
        "testId",
        "errorMessage",
      ],
      storage: window.localStorage,
    });
    this.setPart1SingleAnswer = this.setPart1SingleAnswer.bind(this);
    this.setPart2SingleAnswer = this.setPart2SingleAnswer.bind(this);
    this.setPart3SingleAnswer = this.setPart3SingleAnswer.bind(this);
    this.setPart4SingleAnswer = this.setPart4SingleAnswer.bind(this);
    this.setPart1MultipleAnswer = this.setPart1MultipleAnswer.bind(this);
    this.setPart2MultipleAnswer = this.setPart2MultipleAnswer.bind(this);
    this.setPart3MultipleAnswer = this.setPart3MultipleAnswer.bind(this);
    this.setPart4MultipleAnswer = this.setPart4MultipleAnswer.bind(this);
    this.setPart1SenetenceComplete = this.setPart1SenetenceComplete.bind(this);
    this.setPart2SenetenceComplete = this.setPart2SenetenceComplete.bind(this);
    this.setPart3SenetenceComplete = this.setPart3SenetenceComplete.bind(this);
    this.setPart4SenetenceComplete = this.setPart4SenetenceComplete.bind(this);
    this.setPart1NoteCompletionAnswer =
      this.setPart1NoteCompletionAnswer.bind(this);
    this.setPart2NoteCompletionAnswer =
      this.setPart2NoteCompletionAnswer.bind(this);
    this.setPart3NoteCompletionAnswer =
      this.setPart3NoteCompletionAnswer.bind(this);
    this.setPart4NoteCompletionAnswer =
      this.setPart4NoteCompletionAnswer.bind(this);
    this.setPart1SummaryCompletionOneWordAnswers =
      this.setPart1SummaryCompletionOneWordAnswers.bind(this);
    this.setPart2SummaryCompletionOneWordAnswers =
      this.setPart2SummaryCompletionOneWordAnswers.bind(this);
    this.setPart3SummaryCompletionOneWordAnswers =
      this.setPart3SummaryCompletionOneWordAnswers.bind(this);
    this.setPart4SummaryCompletionOneWordAnswers =
      this.setPart4SummaryCompletionOneWordAnswers.bind(this);
    this.setPart1DragAndDropAnswers =
      this.setPart1DragAndDropAnswers.bind(this);
    this.setPart2DragAndDropAnswers =
      this.setPart2DragAndDropAnswers.bind(this);
    this.setPart3DragAndDropAnswers =
      this.setPart3DragAndDropAnswers.bind(this);
    this.setPart4DragAndDropAnswers =
      this.setPart4DragAndDropAnswers.bind(this);
    this.setPart1TaskIdentifyingItemAnswers =
      this.setPart1TaskIdentifyingItemAnswers.bind(this);
    this.setPart2TaskIdentifyingItemAnswers =
      this.setPart3TaskIdentifyingItemAnswers.bind(this);
    this.setPart3TaskIdentifyingItemAnswers =
      this.setPart3TaskIdentifyingItemAnswers.bind(this);
    this.setPart4TaskIdentifyingItemAnswers =
      this.setPart4TaskIdentifyingItemAnswers.bind(this);
    this.setPart1DraggedCurrentSentence =
      this.setPart1DraggedCurrentSentence.bind(this);
    this.setPart2DraggedCurrentSentence =
      this.setPart2DraggedCurrentSentence.bind(this);
    this.setPart3DraggedCurrentSentence =
      this.setPart3DraggedCurrentSentence.bind(this);
    this.setPart4DraggedCurrentSentence =
      this.setPart4DraggedCurrentSentence.bind(this);
    this.setPart1MatchingSentenceEndAnswers =
      this.setPart1MatchingSentenceEndAnswers.bind(this);
    this.setPart2MatchingSentenceEndAnswers =
      this.setPart2MatchingSentenceEndAnswers.bind(this);
    this.setPart3MatchingSentenceEndAnswers =
      this.setPart3MatchingSentenceEndAnswers.bind(this);
    this.setPart4MatchingSentenceEndAnswers =
      this.setPart4MatchingSentenceEndAnswers.bind(this);
    this.setPart1TableCompletionAnswer =
      this.setPart1TableCompletionAnswer.bind(this);
    this.setPart2TableCompletionAnswer =
      this.setPart2TableCompletionAnswer.bind(this);
    this.setPart3TableCompletionAnswer =
      this.setPart3TableCompletionAnswer.bind(this);
    this.setPart4TableCompletionAnswer =
      this.setPart4TableCompletionAnswer.bind(this);
    this.setPart1ShortAnswer = this.setPart1ShortAnswer.bind(this);
    this.setPart2ShortAnswer = this.setPart2ShortAnswer.bind(this);
    this.setPart3ShortAnswer = this.setPart3ShortAnswer.bind(this);
    this.setPart4ShortAnswer = this.setPart4ShortAnswer.bind(this);
    this.setPart1PlanDiagramAnswer = this.setPart1PlanDiagramAnswer.bind(this);
    this.setPart2PlanDiagramAnswer = this.setPart2PlanDiagramAnswer.bind(this);
    this.setPart3PlanDiagramAnswer = this.setPart3PlanDiagramAnswer.bind(this);
    this.setPart4PlanDiagramAnswer = this.setPart4PlanDiagramAnswer.bind(this);
    this.setPart1AllAnswers = this.setPart1AllAnswers.bind(this);
    this.setPart2AllAnswers = this.setPart2AllAnswers.bind(this);
    this.setPart3AllAnswers = this.setPart3AllAnswers.bind(this);
    this.setPart4AllAnswers = this.setPart4AllAnswers.bind(this);
    this.setPartOneScore = this.setPartOneScore.bind(this);
    this.setPartTwoScore = this.setPartTwoScore.bind(this);
    this.setPartThreeScore = this.setPartThreeScore.bind(this);
    this.setPartFourScore = this.setPartFourScore.bind(this);
  }

  async setLoading(bool: boolean) {
    this.isLoading = bool;
  }

  async setPartOneScore(score: any) {
    this.partOneScore = score;
  }

  async setPartTwoScore(score: any) {
    this.partTwoScore = score;
  }

  async setPartThreeScore(score: any) {
    this.partThreeScore = score;
  }

  async setPartFourScore(score: any) {
    this.partFourScore = score;
  }

  async setPart1AllAnswers(answers: any) {
    this.part1Answers = answers;
  }
  async setPart2AllAnswers(answers: any) {
    this.part2Answers = answers;
  }
  async setPart3AllAnswers(answers: any) {
    this.part3Answers = answers;
  }
  async setPart4AllAnswers(answers: any) {
    this.part4Answers = answers;
  }
  async setTimer(time: any) {
    this.timer = time;
  }
  async setTestIdListening(testId: any) {
    this.testId = testId;
  }

  async setTestTypeListening(typeTest: any) {
    this.testType = typeTest;
  }

  async setPartTypeListening(partType: any) {
    this.partType = partType;
  }

  async setCompleteTestFailed(value: any) {
    this.completeTestFailed = value;
  }

  async setIsTestEnded(value: any) {
    this.isTestEnded = value;
  }

  async setPart1SingleAnswer(answer: any) {
    this.part1SingleAnswer = answer;
  }

  async setPart1MultipleAnswer(multipleAnswer: any) {
    this.part1MultipleAnswer = multipleAnswer;
  }

  async setPart1NoteCompletionAnswer(noteCompletionAnswer: any) {
    this.part1NoteCompletionAnswer = noteCompletionAnswer;
  }

  async setPart1SummaryCompletionOneWordAnswers(
    summaryCompletionOneWordAnswers: any
  ) {
    this.part1SummaryCompletionOneWordAnswers = summaryCompletionOneWordAnswers;
  }

  async setPart1DragAndDropAnswers(dragAndDropAnswers: any) {
    this.part1DragAndDropAnswers = dragAndDropAnswers;
  }

  async setPart1TaskIdentifyingItemAnswers(taskIdentifyingItemAnswers: any) {
    this.part1TaskIdentifyingItemAnswers = taskIdentifyingItemAnswers;
  }

  async setPart1SenetenceComplete(senetenceComplete: any) {
    this.part1SenetenceComplete = senetenceComplete;
  }

  async setPart1DraggedCurrentSentence(draggedCurrentSentence: any) {
    this.part1DraggedCurrentSentence = draggedCurrentSentence;
  }

  async setPart1MatchingSentenceEndAnswers(matchingSentenceEndAnswers: any) {
    this.part1MatchingSentenceEndAnswers = matchingSentenceEndAnswers;
  }

  async setPart1TableCompletionAnswer(tableCompletionAnswer: any) {
    this.part1TableCompletionAnswer = tableCompletionAnswer;
  }

  async setPart1ShortAnswer(answer: any) {
    this.part1ShortAnswer = answer;
  }

  async setPart1PlanDiagramAnswer(answer: any) {
    this.part1PlanDiagramAnswer = answer;
  }

  async setPart2SingleAnswer(answer: any) {
    this.part2SingleAnswer = answer;
  }

  async setPart2MultipleAnswer(multipleAnswer: any) {
    this.part2MultipleAnswer = multipleAnswer;
  }

  async setPart2NoteCompletionAnswer(noteCompletionAnswer: any) {
    this.part2NoteCompletionAnswer = noteCompletionAnswer;
  }

  async setPart2SummaryCompletionOneWordAnswers(
    summaryCompletionOneWordAnswers: any
  ) {
    this.part2SummaryCompletionOneWordAnswers = summaryCompletionOneWordAnswers;
  }

  async setPart2DragAndDropAnswers(dragAndDropAnswers: any) {
    this.part2DragAndDropAnswers = dragAndDropAnswers;
  }

  async setPart2TaskIdentifyingItemAnswers(taskIdentifyingItemAnswers: any) {
    this.part2TaskIdentifyingItemAnswers = taskIdentifyingItemAnswers;
  }

  async setPart2SenetenceComplete(senetenceComplete: any) {
    this.part2SenetenceComplete = senetenceComplete;
  }

  async setPart2DraggedCurrentSentence(draggedCurrentSentence: any) {
    this.part2DraggedCurrentSentence = draggedCurrentSentence;
  }

  async setPart2MatchingSentenceEndAnswers(matchingSentenceEndAnswers: any) {
    this.part2MatchingSentenceEndAnswers = matchingSentenceEndAnswers;
  }

  async setPart2TableCompletionAnswer(tableCompletionAnswer: any) {
    this.part2TableCompletionAnswer = tableCompletionAnswer;
  }
  async setPart2ShortAnswer(answer: any) {
    this.part2ShortAnswer = answer;
  }

  async setPart2PlanDiagramAnswer(answer: any) {
    this.part2PlanDiagramAnswer = answer;
  }

  async setPart3SingleAnswer(answer: any) {
    this.part3SingleAnswer = answer;
  }

  async setPart3MultipleAnswer(multipleAnswer: any) {
    this.part3MultipleAnswer = multipleAnswer;
  }

  async setPart3NoteCompletionAnswer(noteCompletionAnswer: any) {
    this.part3NoteCompletionAnswer = noteCompletionAnswer;
  }

  async setPart3SummaryCompletionOneWordAnswers(
    summaryCompletionOneWordAnswers: any
  ) {
    this.part3SummaryCompletionOneWordAnswers = summaryCompletionOneWordAnswers;
  }

  async setPart3DragAndDropAnswers(dragAndDropAnswers: any) {
    this.part3DragAndDropAnswers = dragAndDropAnswers;
  }

  async setPart3TaskIdentifyingItemAnswers(taskIdentifyingItemAnswers: any) {
    this.part3TaskIdentifyingItemAnswers = taskIdentifyingItemAnswers;
  }

  async setPart3SenetenceComplete(senetenceComplete: any) {
    this.part3SenetenceComplete = senetenceComplete;
  }

  async setPart3DraggedCurrentSentence(draggedCurrentSentence: any) {
    this.part3DraggedCurrentSentence = draggedCurrentSentence;
  }

  async setPart3MatchingSentenceEndAnswers(matchingSentenceEndAnswers: any) {
    this.part3MatchingSentenceEndAnswers = matchingSentenceEndAnswers;
  }

  async setPart3TableCompletionAnswer(tableCompletionAnswer: any) {
    this.part3TableCompletionAnswer = tableCompletionAnswer;
  }
  async setPart3ShortAnswer(answer: any) {
    this.part3ShortAnswer = answer;
  }

  async setPart3PlanDiagramAnswer(answer: any) {
    this.part3PlanDiagramAnswer = answer;
  }

  async setPart4SingleAnswer(answer: any) {
    this.part4SingleAnswer = answer;
  }

  async setPart4MultipleAnswer(multipleAnswer: any) {
    this.part4MultipleAnswer = multipleAnswer;
  }

  async setPart4NoteCompletionAnswer(noteCompletionAnswer: any) {
    this.part4NoteCompletionAnswer = noteCompletionAnswer;
  }

  async setPart4SummaryCompletionOneWordAnswers(
    summaryCompletionOneWordAnswers: any
  ) {
    this.part4SummaryCompletionOneWordAnswers = summaryCompletionOneWordAnswers;
  }

  async setPart4DragAndDropAnswers(dragAndDropAnswers: any) {
    this.part4DragAndDropAnswers = dragAndDropAnswers;
  }

  async setPart4TaskIdentifyingItemAnswers(taskIdentifyingItemAnswers: any) {
    this.part4TaskIdentifyingItemAnswers = taskIdentifyingItemAnswers;
  }

  async setPart4SenetenceComplete(senetenceComplete: any) {
    this.part4SenetenceComplete = senetenceComplete;
  }

  async setPart4DraggedCurrentSentence(draggedCurrentSentence: any) {
    this.part4DraggedCurrentSentence = draggedCurrentSentence;
  }

  async setPart4MatchingSentenceEndAnswers(matchingSentenceEndAnswers: any) {
    this.part4MatchingSentenceEndAnswers = matchingSentenceEndAnswers;
  }

  async setPart4TableCompletionAnswer(tableCompletionAnswer: any) {
    this.part4TableCompletionAnswer = tableCompletionAnswer;
  }
  async setPart4ShortAnswer(answer: any) {
    this.part4ShortAnswer = answer;
  }

  async setPart4PlanDiagramAnswer(answer: any) {
    this.part4PlanDiagramAnswer = answer;
  }

  async getFreeTestDataListening() {
    try {
      this.isLoading = true;
      this.isFreeTestLoading = true;
      let freeTests =
        await this.startTestListeningAPi.fetchFreeTestDataListening();
      if (freeTests.status === 200) {
        this.isLoading = false;
        this.freeTestData = freeTests.data;
        this.tryAgainButton = false;
        this.isFreeTestLoading = false;
      }
    } catch (e: any) {
      console.log(e, "Free Test End point failed");
      this.isLoading = false;
      this.tryAgainButton = true;
      this.isFreeTestLoading = false;
    }
  }

  async generateTestListening(typeTest: any) {
    let res;
    try {
      this.isLoading = true;
      res = await this.startTestListeningAPi.generateTestListening(typeTest);
      if (res.status === 200) {
        this.testId = res.data.test_id;
        this.startFreeTestListening(typeTest);
        this.tryAgainButton = false;
      } else if (res.data.istestcompleted) {
        this.isLoading = false;
        this.tryAgainButton = true;
        this.errorMessage = res.data.reason;
      } else {
        this.isLoading = false;
        this.tryAgainButton = true;
        this.errorMessage = res.data.detail;
      }
    } catch (e) {
      console.log(e, "generateTest end point failed");
      this.isLoading = false;
      this.tryAgainButton = true;
      this.errorMessage = res.data.detail;
    }
  }

  async startFreeTestListening(typeTst: any) {
    this.isLoading = true;
    let result;
    try {
      result = await this.startTestListeningAPi.startFreeTestListening(
        this.testId
      );
      if (result?.status === 200) {
        let payload = {
          id: this.testId,
          type: typeTst,
        };
        let res3 = await this.startTestListeningAPi.getTestListening(payload);
        if (res3?.status === 200) {
          let fullTestData: any = res3.data;
          this.isLoading = false;
          this.setQuestionsData(typeTst, fullTestData);
        } else {
          this.isLoading = false;
          this.tryAgainButton = true;
          this.statusCode = res3?.status;
          this.errorMessage = (res3?.data as ResultData)?.detail ?? "";
        }
      } else {
        this.isLoading = false;
        this.tryAgainButton = false;
      }
    } catch (err) {
      console.error(err);
      this.isLoading = false;
      this.tryAgainButton = true;
      this.statusCode = result?.status;
      this.errorMessage = (result?.data as ResultData)?.detail ?? "";
    }
  }

  setQuestionsData(testType: string, testData: any) {
    if (testType === "part1") {
      this.part1Questions = testData.data[0].data;
      this.tryAgainButton = false;
    } else if (testType === "part2") {
      this.part2Questions = testData.data[0].data;
      this.tryAgainButton = false;
    } else if (testType === "part3") {
      this.part3Questions = testData.data[0].data;
      this.tryAgainButton = false;
    } else if (testType === "part4") {
      this.part4Questions = testData.data[0].data;
      this.tryAgainButton = false;
    } else {
      if (testData?.data?.length > 0) {
        this.part1Questions =
          testData.data[0].testType === "Part-1" && testData.data[0].data;
        this.part2Questions =
          testData.data[1].testType === "Part-2" && testData.data[1].data;
        this.part3Questions =
          testData.data[2].testType === "Part-3" && testData.data[2].data;
        this.part4Questions =
          testData.data[3].testType === "Part-4" && testData.data[3].data;
      }
      this.tryAgainButton = false;
    }
  }

  async submitAnswerListening() {
    this.isTestEnded = true;
    console.log(this.testType, this.partType, "TESTTYPEPART1ANSWERS");
    try {
      let payload;
      if (this.testType === "full") {
        payload = {
          test_id: this.testId,
          userAnswers: [
            ...this.part1Answers,
            ...this.part2Answers,
            ...this.part3Answers,
            ...this.part4Answers,
          ],
        };
      } else if (this.partType === "part1") {
          payload = {
            test_id: this.testId,
            userAnswers: this.part1Answers,
          };
        } else if (this.partType === "part2") {
          payload = {
            test_id: this.testId,
            userAnswers: this.part2Answers,
          };
        } else if (this.partType === "part3") {
          payload = {
            test_id: this.testId,
            userAnswers: this.part3Answers,
          };
        } else if (this.partType === "part4") {
          payload = {
            test_id: this.testId,
            userAnswers: this.part4Answers,
          };
        }

      const res: any = await this.startTestListeningAPi.submitTestListening(payload);
      if (res?.status === 200) {
        return res;
      } else {
        this.isTestEnded = false;
        this.completeTestFailed = true;
      } 
    } catch (err) {
      console.error(err);
      this.isTestEnded = false;
      this.completeTestFailed = true;
    }
  }

  async completeTest(payload: any) {
    try {
      console.log(payload, "ENDPOINT PAYLOAD");
      let response = await this.startTestListeningAPi.endTestListening(payload);
      if (response && response.status === 200) {
        this.isTestEnded = null;
        this.completeTestFailed = false;
      } else {
        this.isTestEnded = false;
        this.completeTestFailed = true;
      }
    } catch (e) {
      this.isTestEnded = false;
      this.completeTestFailed = true;
      console.log(e);
    }
  }

  async clearAllAnswers() {
    this.part1Answers = [];
    this.part2Answers = [];
    this.part3Answers = [];
    this.part4Answers = [];
    this.part1SingleAnswer = [];
    this.part1MultipleAnswer = [];
    this.part1NoteCompletionAnswer = [];
    this.part1SummaryCompletionOneWordAnswers = [];
    this.part1DragAndDropAnswers = [];
    this.part1TaskIdentifyingItemAnswers = [];
    this.part1SenetenceComplete = [];
    this.part1DraggedCurrentSentence = [];
    this.part1MatchingSentenceEndAnswers = [];
    this.part1TableCompletionAnswer = [];
    this.part1ShortAnswer = [];
    this.part2SingleAnswer = [];
    this.part2MultipleAnswer = [];
    this.part2NoteCompletionAnswer = [];
    this.part2SummaryCompletionOneWordAnswers = [];
    this.part2DragAndDropAnswers = [];
    this.part2TaskIdentifyingItemAnswers = [];
    this.part2SenetenceComplete = [];
    this.part2DraggedCurrentSentence = [];
    this.part2MatchingSentenceEndAnswers = [];
    this.part2TableCompletionAnswer = [];
    this.part2ShortAnswer = [];
    this.part3SingleAnswer = [];
    this.part3MultipleAnswer = [];
    this.part3NoteCompletionAnswer = [];
    this.part3SummaryCompletionOneWordAnswers = [];
    this.part3DragAndDropAnswers = [];
    this.part3TaskIdentifyingItemAnswers = [];
    this.part3SenetenceComplete = [];
    this.part3DraggedCurrentSentence = [];
    this.part3MatchingSentenceEndAnswers = [];
    this.part3TableCompletionAnswer = [];
    this.part3ShortAnswer = [];
    this.part4SingleAnswer = [];
    this.part4MultipleAnswer = [];
    this.part4NoteCompletionAnswer = [];
    this.part4SummaryCompletionOneWordAnswers = [];
    this.part4DragAndDropAnswers = [];
    this.part4TaskIdentifyingItemAnswers = [];
    this.part4SenetenceComplete = [];
    this.part4DraggedCurrentSentence = [];
    this.part4MatchingSentenceEndAnswers = [];
    this.part4TableCompletionAnswer = [];
    this.part4ShortAnswer = [];

    //Overall Scores
    this.partOneScore = null;
    this.partTwoScore = null;
    this.partThreeScore = null;
    this.partFourScore = null;
    this.overallScore = null;

    //answersheets from Be
    this.answerSheet = [];
    this.part1AnswerSheet = [];
    this.part2AnswerSheet = [];
    this.part3AnswerSheet = [];
    this.part4AnswerSheet = [];
  }
}
