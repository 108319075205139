import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';


const MatchingSentenceEnding = ({item,setDragSentenceAnswers,darggedCurrentSentence,
  handleDragOver,handleSentenceDragStart,
  handleDragSentenceStartFromAnswer,
  handleDropSentenceFromDisplayedItem,
  handleSentenceDrop,dragSentenceAnswers}:any) => {

  const { answers, passage } = item["drag-and-drop-summary-completion-object"];



  const dargAndDropPlaceholdersSentence = () => {
    const regex = /{qId:(\d+)}/g;
    return passage.split(regex).map((part: any, index: any) => {
      if (index % 2 === 0) {
        return part;
      } else {
        const qId = part;
        let displayedItem = darggedCurrentSentence.find(
          (item: any, index: any) => item.qId == qId
        );
        
        return (
          <button //these have been changed from span tag to button to avoid sonar issue
            key={qId}
            onDragOver={(e) => handleDragOver(e)}
            onDrop={(e) => handleSentenceDrop(e, qId)}
            className={`rounded-lg p-1 my-1  text-[#E3E5E9] text-justify ${
              displayedItem?.qId
                ? "border border-[#E31837]"
                : "border border-[#E3E5E9]"
            }`}
          >
            {displayedItem?.qId ? (
              <button //these have been changed from span tag to button to avoid sonar issue
                className="text-[#292D35] cursor-pointer"
                draggable
                onDragOver={(e) => handleDragOver(e)}
                onDragStart={(e) => handleSentenceDragStart(e, displayedItem)}
              >
                {displayedItem?.answer}
              </button>
            ) : (<div>Drop Here qId:${qId}</div>
              
            )}
            {}
          </button>
        );
      }
    });
  };

useEffect(() => {
  console.log(answers,"ANSWERRRR")
  setDragSentenceAnswers(answers);
},[answers]);
  return (<>
  <div className="text-[#3C424C] text-sm">
  Complete each sentence with the correct ending. Choose the correct answer and drag and drop it into the field.
      </div>
      <div className="mt-2 text-[#3C424C] text-base text-justify leading-8">
        {dargAndDropPlaceholdersSentence()}
        <button //these have been changed from span tag to button to avoid sonar issue
          className="flex flex-wrap mt-6 border via-black min-h-[3rem]"
         onDragOver={(e) => handleDragOver(e)}
          onDrop={(e) => handleDropSentenceFromDisplayedItem(e)}
        >
          {dragSentenceAnswers && dragSentenceAnswers.length > 0 && dragSentenceAnswers.map((draganswer: any, index: any) => (
            <button //these have been changed from span tag to button to avoid sonar issue
              className=" border border-[#E31837] rounded-md m-1 px-4 py-2 text-sm bg-[#FFEAEA] cursor-pointer"
              key={draganswer}
              draggable
              onDragStart={(e) => handleDragSentenceStartFromAnswer(e, draganswer)}
              onDragOver={(e) => handleDragOver(e)}
            >
              {draganswer}
            </button>
          ))}
        </button>
      </div>
  </>
  )
}

export default observer(MatchingSentenceEnding)