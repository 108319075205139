import { observer } from "mobx-react-lite";
import { useStore } from "../../Hooks/use-store";

const Tab = ({ label, onClick, isActive }: any) => {
  return (
    <button
      className={`cursor-pointer px-4 pt-4 border-t-[5px] rounded-sm font-extrabold ${
        isActive ? "border-red-600 text-[#E31837] " : "border-transparent"
      }`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};
const Tabs = ({ tabs }: any) => {
  const { resultsStoreWriting, startTestStoreWriting } = useStore();

  const handleTabClick = (index: any) => {
    resultsStoreWriting.setActiveTab(index);
    index === 0
      ? resultsStoreWriting.setStepManagement("Part 1", "", "")
      : resultsStoreWriting.setStepManagement("Part 2", "Part 2", "");
  };

  const renderTabs = () => {
    if (startTestStoreWriting.testType === "part1") {
      return (
        <Tab
          key={0}
          label="Part 1"
          onClick={() => handleTabClick(0)}
          isActive="true"
        />
      );
    } else if (startTestStoreWriting.testType === "part2") {
      return (
        <Tab
          key={1}
          label="Part 2"
          onClick={() => handleTabClick(1)}
          isActive="true"
        />
      );
    } else {
      return tabs.map((tab: any, index: any) => (
        <Tab
          key={tab}
          label={tab}
          onClick={() => handleTabClick(index)}
          isActive={index === resultsStoreWriting.activeTab}
        />
      ));
    }
  };

  return <div className="flex  rounded-t-[8px]  pl-4">{renderTabs()}</div>;
};

const PartTestsLayout = observer(
  ({
    title,
    desc,
    children,
    nextStep,
    buttonTitle,
    disabled,
    partType,
  }: any) => {
    const tabs = ["Part 1", "Part 2"];

    return (
      <div>
        <div className="border h-full border-[#E3E5E9] p-4 m-6 bg-[#FFF] rounded-lg">
          <p className="font-extrabold text-base md:text-xl mb-2">{title}</p>
          <h5>{desc}</h5>
        </div>
        {children}
        <div className="flex justify-between fixed bg-white  border-t border-t-[#E3E5E9]  bottom-0 w-full">
          <div>
            <Tabs tabs={tabs} partType={partType} />
          </div>
          <div>
            <button
              disabled={disabled}
              onClick={nextStep}
              className={` ${
                disabled
                  ? "bg-[#ABB2BE] text-white"
                  : "text-[#E31837] border-[#E31837] "
              } border my-4 px-4 py-2 rounded-full `}
            >
              {buttonTitle}
            </button>
          </div>
        </div>
      </div>
    );
  }
);

export default PartTestsLayout;
