import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";

const SummaryCompletionSelectingFromListOfWords = observer(({
  item,
  handleDragOver,
  handleDragStart,
  handleDrop,
  handleDragStartFromAnswer,
  handleDropFromDisplayedItem,
  darggedCurrentItem,
  dragAnswers,
  setDragAnswers
}: any) => {
  const { answers, passage } = item["drag-and-drop-summary-completion-object"];
 

  const dargAndDropPlaceholders = () => {
    const regex = /{qId:(\d+)}/g;
    return passage.split(regex).map((part: any, index: any) => {
      if (index % 2 === 0) {
        return part;
      } else {
        const qId = part;
        let displayedItem = darggedCurrentItem.find(
          (item: any, index: any) => item.qId == qId
        );
        return (
          <button //these have been changed from span tag to button to avoid sonar issue
            key={qId}
            onDragOver={(e) => handleDragOver(e)}
            onDrop={(e) => handleDrop(e, qId)}
            className={`rounded-lg p-1 my-1 text-[#E3E5E9] text-justify ${
              displayedItem?.qId
                ? "border border-[#E31837]"
                : "border border-[#E3E5E9]"
            }`}
          >
            {displayedItem?.qId ? (
              <button //these have been changed from span tag to button to avoid sonar issue
                className="text-[#292D35] cursor-pointer"
                draggable
                onDragOver={(e) => handleDragOver(e)}
                onDragStart={(e) => handleDragStart(e, displayedItem)}
              >
                {displayedItem?.answer}
              </button>
            ) : (
              `Drop Here qId:${qId}`
            )}
            {}
          </button>
        );
      }
    });
  };

useEffect(() => {
  setDragAnswers(answers);
},[answers]);

  return (
    <>
      <div className="text-[#3C424C] text-sm">
        {" "}
        Complete the summary using the list of words. Choose the correct answer
        and drag & drop it into the field
      </div>
      <div className="mt-2 text-[#3C424C] text-base text-justify leading-8">
        {dargAndDropPlaceholders()}
        <button //these have been changed from span tag to button to avoid sonar issue
          className="flex flex-wrap mt-6 border via-black min-h-[3rem]"
          onDragOver={(e) => handleDragOver(e)}
          onDrop={(e) => handleDropFromDisplayedItem(e)}
        >
          {dragAnswers && dragAnswers.length > 0 && dragAnswers.map((draganswer: any, index: any) => {
            return(
            <button //these have been changed from span tag to button to avoid sonar issue
              className="border border-[#E31837] rounded-md m-1 px-4 py-2 text-sm bg-[#FFEAEA] cursor-pointer"
              key={draganswer}
              draggable
              onDragStart={(e) => handleDragStartFromAnswer(e, draganswer)}
              onDragOver={(e) => handleDragOver(e)}
            >
              {draganswer}
            </button>
          )})}
        </button>{" "}
      </div>
    </>
  );
});

export default SummaryCompletionSelectingFromListOfWords;
