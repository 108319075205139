import React, { useEffect, useState } from 'react'

const ProgressiveImage = (props:any) => {
    const {src,placeHolderImage,height,width,alt,isRounded} =props
    const [imgSrc,setImgSrc] = useState(placeHolderImage || src);


    useEffect(() => {
        const img = new Image()
        img.src = src;
        img.onload = () => {
            setImgSrc(src)
        }
    },[src])
  return (
    <img src={imgSrc} className={`${isRounded && 'rounded-full'} `} height={height} width={width} alt={alt} />
  )
}

export default ProgressiveImage