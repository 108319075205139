import React from 'react';
import LockedIcon from '../../icons/Lock.svg'; // Make sure to replace 'path-to-locked-icon' with the actual path to your icon

interface LockedContentOverlayProps {
  startHandler: () => void;
}

const LockedContentOverlay: React.FC<LockedContentOverlayProps> = ({ startHandler }) => {
  return (
    <div
      className="flex flex-col absolute top-0 left-0 min-h-screen right-0 rounded-b-[16px] bg-[#2D293566] w-full h-full justify-center items-center"
      style={{ backdropFilter: 'blur(5px)' }}
    >
      <div>
        <img src={LockedIcon} alt="lock" />
      </div>
      <div className="text-[#FFFF] text-center">
        <p>
          Get a plan to check your AI feedback. It shows pronunciation,
          fluency, and relevance of each sentence you speak.
        </p>
      </div>
      <button
        className="mx-auto bg-white rounded-full px-4 py-2 font-medium text-[#FF495C] cursor-pointer mt-5"
        onClick={startHandler}
      >
        CHECKOUT OUR PLANS
      </button>
    </div>
  );
};

export default LockedContentOverlay;
