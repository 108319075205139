import React, { useEffect, useState } from "react";
import BottomRightIcon from "../../icons/undraw_different_love_a-3-rg 1-right (1).png";
import BottomLeftIcon from "../../icons/undraw_different_love_a-3-rg 1-left.png";
import PhoneInput from "react-phone-input-2";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useNavigate,useSearchParams } from "react-router-dom";
import { useStore } from "../../Hooks/use-store";
import { toast } from "react-toastify";

const OAuthVerificationPage = () => {
  const { userStore } = useStore();
  const [mobileNumber, setMobileNumber] = useState("");
  const [searchParams] = useSearchParams();
  const [isValid, setIsValid] = useState(false);
  const [isMobileNumberExists, setIsMobileNumberExists] = useState(false);
  let navFromSignUp = useNavigate();

  const [isVerificationInProgress, setIsVerificationInProgress] =
    useState(false);
  const [oauthPayload, setOauthPayload] = useState({
    email: "",
    user_id: "",
    mobile: "",
    code: "",
    isnewuser: false,
  });
  const navigate = useNavigate();

  const handleOnChange = (value: any, country: any) => {
    if (isValidPhoneNumber("+" + value)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
    let mobile = `+${value}`;
    let isnewuser = true;
    setOauthPayload((prevState: any) => ({
      ...prevState,
      mobile,
      isnewuser,
    }));
    setMobileNumber(`+${value}`);
  };

  const verifyDetails = async () => {
    try {
      setIsVerificationInProgress(true);
      let res: any = await userStore.updateOAuthMobile(oauthPayload);
      if (res.status === 200) {
        setIsVerificationInProgress(false);
        if (res.data.isNavigateOTP) {
          navFromSignUp("/otp")
          await userStore.request_otp(oauthPayload.mobile);
        } else {
          res.data.already_registered
            ? navFromSignUp(`/${userStore.userDashboards[0].toLowerCase()}`)
            : navFromSignUp("/details");
        }
      } else {
        setIsVerificationInProgress(false);
        toast.error(res.data.detail);
      }
    } catch (e: any) {
      console.error(e, "ERROR!");
    }
  };

  useEffect(() => {
    let code: any = searchParams.get("code");
    let result: any = atob(code);

    if (result) {
      const emailRegex = result?.match(/email=([^&]+)/);
      const userIdRegex = result?.match(/user_id=([^&]+)/);
      const mobileRegex = result?.match(/mobile=([^&]+)/);

      let email = emailRegex?.[1];
      let user_id =  userIdRegex?.[1];
      let mobile =  mobileRegex?.[1];
      let isnewuser: boolean;
      if (mobile) {
        setIsMobileNumberExists(true);
        isnewuser = false;
        setOauthPayload((prevState: any) => ({
          ...prevState,
          email,
          user_id,
          mobile,
          code,
          isnewuser,
        }));
      } else {
        setIsMobileNumberExists(false);
        isnewuser = true;
        setOauthPayload((prevState: any) => ({
          ...prevState,
          email,
          user_id,
          mobile,
          code,
          isnewuser,
        }));
      }
    }
  }, []);

  useEffect(() => {
    if (isMobileNumberExists) {
      verifyDetails();
    }
  }, [isMobileNumberExists]);

  console.log(isMobileNumberExists, oauthPayload, "VINOD");
  return (
    <>
      {" "}
      <div className="bg-[#FFC9C9] p-4 md:p-0 w-full min-h-screen flex justify-center items-center relative ">
        <div className="md:pt-20 md:pb-28 md:px-[120px] p-4 flex flex-col justify-center items-center border rounded-2xl bg-[#FFFFFF] md:w-[50%] w-full max-w-screen-md z-[999]">
          <h1 className="text-[#E31837] text-[40px] font-manrope font-bold tracking-wider text-center mb-2">
            IELTS
          </h1>

          <span className=" text-gray-600 px-2 border-red-300 py-2 my-2 rounded-3xl font-bold text-lg  outline-none">
            {oauthPayload?.email}
          </span>
          {!isMobileNumberExists ? (
            <PhoneInput
              containerClass="w-fit"
              inputClass="h-12 border border-[1px] rounded-3xl border border-red-600 mb-4 px-2"
              country={"in"}
              placeholder=""
              value={mobileNumber}
              onChange={handleOnChange}
              onKeyDown={(event) => {}}
            />
          ) : (
            <span className=" text-gray-600 px-2 border-red-300 font-bold text-lg rounded-3xl  outline-none">
              {oauthPayload?.mobile}
            </span>
          )}
          {isVerificationInProgress && <span className="directLoader"></span>}

          {!isMobileNumberExists && (
            <div className="flex gap-2 mt-[2rem]">
              <button
                className={` rounded-full  ${
                  !isValid || isVerificationInProgress
                    ? "bg-gray-300 "
                    : "bg-[#E31837] hover:text-red-500 hover:bg-red-200"
                } py-2 text-white w-[6.5rem] h-[3rem]  `}
                disabled={!isValid || isVerificationInProgress}
                onClick={() => verifyDetails()}
              >
                Submit{" "}
              </button>
              <button
                className=" bg-red-500 rounded-full  py-2 text-white w-[6.5rem] h-[3rem] hover:bg-red-200 hover:text-red-500 "
                onClick={() => navigate("/login")}
              >
                Cancel{" "}
              </button>
            </div>
          )}
        </div>
      </div>
      <img
        src={BottomRightIcon}
        alt="right"
        className="absolute bottom-0 right-0"
      />
      <img
        src={BottomLeftIcon}
        alt="left"
        className="absolute bottom-0 left-0"
      />
    </>
  );
};

export default OAuthVerificationPage;
