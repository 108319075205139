import apiSauce from "./api-sauce";

export class ReadingTestResultsApi{
    async testResultsUpdateReading(params:any,page:number):Promise<any> {
        if(params){
            return await apiSauce.apisauce?.get(`/reading-progress?page=${page}&limit=17&test_type=${params}`)
        }else{
            return await apiSauce.apisauce?.get(`/reading-progress?page=${page}&limit=17`)
        }
        
    }


    async fetchFreeTestDataReading():Promise<any>{
        return await apiSauce.apisauce?.get('/free-reading-tests')

    }

    async getAverageScoreDataReading():Promise<any>{

        return await apiSauce.apisauce?.get('/get-reading-chart')
    }

    async getTestScoreApiReading(tstId:any) :Promise<any>{
        return await apiSauce.apisauce?.get(`get-reading-test-score?test_id=${tstId}`)
    }

     async getQuestionAnalysisApiReading(tsId:any) :Promise<any> {
        return await apiSauce.apisauce?.get(`get-reading-question-analysis?test_id=${tsId}`)
     }
     async isFreeTestCompletedReading(tId:any):Promise<any>{
        // return await apiSauce.apisauce?.get(`is_test_completed?test_id=${tId}`)
      }
}



