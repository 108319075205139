
import { observer } from "mobx-react-lite";
import React from "react";

const ShortAnswer = ({ item,handleChangeShortAnswer ,shortAnswerInputValues}: any) => {
  let range = item["questionRange"].split("-");
  
  let [startingQuestion, endingQuestion] = range;

  let numberOfQns = +endingQuestion - +startingQuestion;
  const inputValue = Array(numberOfQns + 1).fill("");
 console.log(inputValue,"inputValue")
  return (
    <>
      {" "}
      <div className="text-[#3C424C] text-sm my-2">
        Write <span className="font-bold">ONE WORD ONLY </span>in each field.
      </div>
      <div className="text-[#292D35] text-base font-bold">
        {item["questionDescription"]}
      </div>
      {inputValue?.map((item: any, i) => (
          <input
            type="text"
            key={item}
            className="border border-[#E3E5E9] rounded-lg p-1 pl-3 my-2 w-40"
            placeholder={`qId:${+startingQuestion + i}`}
            value={shortAnswerInputValues(+startingQuestion + i)}
            onChange={(e) => handleChangeShortAnswer(+startingQuestion + i,e.target.value)}
          />
        ))}
    </>
  );
};

export default observer(ShortAnswer);
