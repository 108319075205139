import React from "react";

const AiFeedbackQuestionDescription = ({ questionType }: any) => {


  switch (questionType) {
    case "Multiple choice (More than one answer)" :
      case "Multiple Choice (more than one answer)":
      return (
        <div className="text-[#3C424C] text-sm font-avenir mb-4 ">
          {" "}
          Choose the <strong>Multiple Correct Answers</strong>
        </div>
      );
      case "Short Answer":
        return (
          <div className="text-[#3C424C] text-sm font-avenir mb-4 ">
            {" "}
            Answer the question.Write<strong className="font-semibold"> One Word Only</strong>in each field
          </div>
        );

    case "Multiple choice":
      case "Multiple Choice":
      return (
        <div className="text-[#3C424C] text-sm font-avenir mb-4"> Choose the <strong>Single Correct Answer</strong></div>
      );

    case "Note Completion":
      return (
        <div className="text-[#3C424C] text-sm font-avenir mb-4">
          {" "}
          Complete the notes. Write{" "}
          <span className="font-semibold">ONE WORD ONLY</span> from the text for
          each answer.
        </div>
      );

    case "Identifying Information (True/False/Not Given)":
      return (
        <div className="text-[#3C424C] text-sm my-2 font-avenir mb-4">
          Choose <span className="font-bold">TRUE</span> if the statement agrees
          with the information given in the text, choose{" "}
          <span className="font-bold">FALSE</span> if the statement contradicts
          the information, or choose{''}
          <span className="font-bold">NOT GIVEN</span>{''}if there is no
          information on this.
        </div>
      );

    case "Summary Completion (selecting word from text)":
      return (
        <div className="text-[#3C424C] text-sm font-avenir mb-4">
          {" "}
          Complete the summary. Write{" "}
          <span className="font-semibold">NO MORE THAN TWO WORDS</span> from the
          text for each answer:
        </div>
      );

    case "Summary Completion (selecting from a list of words or phrases)":
      return (
        <div className="text-[#3C424C] text-sm font-avenir mb-4">
          {" "}
          Complete the summary using the list of words. Choose the correct
          answer and drag & drop it into the field
        </div>
      );

    case "Sentence Completion":
      return (
        <div className="text-[#3C424C] text-sm my-2 font-avenir mb-4">
          {" "}
          Complete the notes. Write{" "}
          <span className="font-semibold">ONE WORD ONLY</span> from the text for
          each answer.
        </div>
      );

    case "Sentence Endings":
      return (
        <div className="text-[#3C424C] text-sm font-avenir mb-4">
          Complete each <strong>Sentence with the correct ending</strong>. Choose the correct
          answer and drag and drop it into the field.
        </div>
      );

    case "Table Completion":
      return (
        <div className="text-[#3C424C] text-sm mb-5 font-avenir">
          Complete the table. Write{" "}
          <span className="font-semibold">ONE WORD ONLY </span>from
          the text for each answer.
        </div>
      );
    
    case "Matching Features":
      return (
        <div className="text-[#3C424C] text-sm font-avenir mb-4">
           Choose the correct group, You may choose any group more than once.
        </div>
      )

    default:
      return null;
  }
};

export default AiFeedbackQuestionDescription;
