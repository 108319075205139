import apiSauce from "./api-sauce";

export class ListeningTestResultsApi{
    async testResultsUpdateListening(params:any,page:number):Promise<any> {
        if(params){
            return await apiSauce.apisauce?.get(`/listening-progress?page=${page}&imit=17&test_type=${params}`)
        }else{
            return await apiSauce.apisauce?.get(`/listening-progress?page=${page}&limit=17`)
        }
        
    }


    async fetchFreeTestDataListening():Promise<any>{
        return await apiSauce.apisauce?.get('/free-listening-tests')

    }

    async getAverageScoreDataListening():Promise<any>{

        return await apiSauce.apisauce?.get('/get-listening-chart')
    }

    async getTestScoreApiListening(tstId:any) :Promise<any>{
        return await apiSauce.apisauce?.get(`get-listening-test-score?test_id=${tstId}`)
    }

     async getQuestionAnalysisApiListening(tsId:any,part:any) :Promise<any> {
        return await apiSauce.apisauce?.get(`get-question-analysis?test_id=${tsId}&type=${part}`)
     }

     async getQuestionAnalysisApiReading(tsId:any) :Promise<any> {
        return await apiSauce.apisauce?.get(`get-listening-question-analysis?test_id=${tsId}`)
     }
     async isFreeTestCompletedListening(tId:any):Promise<any>{
        // return await apiSauce.apisauce?.get(`is_test_completed?test_id=${tId}`)
      }
}


