import { observer } from 'mobx-react-lite';
import React from 'react'

const SenetenceCompletion = ({item,replaceWithInputFieldsSentenceCompletion}:any) => {
  return (
   <>
    <div className="text-[#3C424C] text-sm my-2">
        {" "}
        Complete the sentences. Write{" "}
        <span className="font-semibold">ONE WORD ONLY</span> from the text for
        each answer.
      </div>
      <div className="text-[#3C424C] text-lg font-semibold mt-2" >
        {item["question-description"]}
      </div>
      {item["list-of-questions"].length > 0 &&
        item["list-of-questions"].map((question: any, index: any) => {
          return (
            <div key={question.qId} className="text-[#3C424C] text-base text-justify">
              <li>
                {replaceWithInputFieldsSentenceCompletion(question.qName, index, question.qId)}
              </li>
            </div>
          );
        })}
   </>
  )
}

export default observer(SenetenceCompletion)