import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../Hooks/use-store";
import { observer } from "mobx-react-lite";


function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const Profile = observer(() => {
  const { userStore, dashboardStore } = useStore();
  const navigate = useNavigate();

  const logoutHandler = () => {
    userStore.clearStoredDate();
    localStorage.clear()
    navigate("/login");
    dashboardStore.showProfilePage(false);
    dashboardStore.updateCurrentNavBar('Speaking');
    userStore.updateMobileNumber("")
  };

  return (
    <Menu as="div" className="relative ml-3">
        <div>
          <Menu.Button className="flex rounded-full bg-white text-sm">
            {userStore.profilePic ? (
              <img
                src={userStore.profilePic}
                alt="profile pic"
                className="w-[2.5rem] h-[2.5rem] object-cover rounded-full"
              />
            ) : (
              <div className="w-[2.5rem] h-[2.5rem] rounded-full bg-[#FFEAEA] flex justify-center items-center text-[#4E5664] text-xl my-[1rem]"></div>
            )}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className="absolute flex flex-col left-0 z-10 mt-2 w-48 origin-top-left rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5"
            onClick={() => {
              dashboardStore.showProfilePage(true);
            }}
          >
            <Menu.Item>
              {({ active }) => (
                <button
                  className={classNames(
                    active ? "bg-gray-100" : "",
                    "block px-4 py-2 text-sm text-gray-700"
                  )}
                  onClick={() => {
                    dashboardStore.setProfilePageTabIndex(0);
                    navigate("/profile");
                  }}
                >
                  Profile
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  className={classNames(
                    active ? "bg-gray-100" : "",
                    "block px-4 py-2 text-sm text-gray-700"
                  )}
                  onClick={() => {
                    dashboardStore.setProfilePageTabIndex(2);
                    navigate("/profile");
                  }}
                >
                  Settings
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  className={classNames(
                    active ? "bg-gray-100" : "",
                    "block px-4 py-2 text-sm text-gray-700"
                  )}
                  onClick={(e) => {
                    logoutHandler();
                  }}
                >
                  Logout
                </button>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      </Menu>
  );
});
export default Profile;
