import { makePersistable } from 'mobx-persist-store';
import {  makeAutoObservable } from "mobx";
import { StartTestWritingApi } from '../api/start-test-writing-api';

interface SubmitTestStatus {
    part1: boolean;
    part2: boolean;
}

export class StartWritingTestStore{
    errorMessage:any;
    testId :any= null;
    testType =null;
    partType = null;
    timer:any = 3600;
    freeTestData = [];
   
    part1Questions:any ={};
    part2Questions:any ={};
    isFreeTestLoading:boolean =false
    isLoading:boolean = false;
    isTestEnded:any = null;
    storeName:any = "writing";
    tryAgainButton:boolean = false;
    completeTestFailed:boolean = false;

    submitTestStatus: SubmitTestStatus = {
        part1: false,
        part2: false
    };
    

    constructor(private startTestWritingApi :StartTestWritingApi){
        makeAutoObservable(this);
        makePersistable(this,{name:"StartWritingTestStore",properties:['testId','testType','part1Questions','part2Questions','isTestEnded','storeName','completeTestFailed','tryAgainButton','errorMessage'],storage:window.localStorage});
    }

async setTimer(time:any){
    this.timer= time;
}


async setTestType(type:any){
    this.testType = type;
}

async setPartType(type:any){
    this.partType = type;

}
async setTestIdFreeTest(tId:any){
    this.testId = tId;
}
async setTryAgainButton(tryAgainButton: any){
    this.tryAgainButton = tryAgainButton;
}

async setCompleteTestFailed(value : any){
    this.completeTestFailed = value;
}

    async getFreeTestDataWriting() {
        try {
            this.isLoading = true;
            this.isFreeTestLoading = true
            const response = await this.startTestWritingApi.fetchFreeTestDataWriting();
            if (response.ok) {
                this.isLoading = false;
                this.freeTestData = response.data;
                this.isFreeTestLoading = false
                return response;
            }

        } catch (e) {
            this.isFreeTestLoading = false
            console.log(e, "ERROR")
        }

    }
    


    async generateTestProWriting(typeTst: any) {
        let res
        try {
            this.isLoading = true;
             res = await this.startTestWritingApi.generateProTestApiWriting(typeTst);
            if (res.status === 200) {
                this.tryAgainButton = false;
                this.testId = res.data.test_id;
                this.startFreeTestWriting(typeTst) 
            }else if(res.data.istestcompleted){
                this.isLoading = false;                               
                this.tryAgainButton = true;
                this.errorMessage = res.data.reason;
              }
              else{
                this.isLoading = false;                               
                this.tryAgainButton = true;
                this.errorMessage = res.data.detail;
              }
        } catch (e) {
            this.tryAgainButton = true;
            this.isLoading = false;
            this.errorMessage = res.data.detail;
        }

    }


  async startFreeTestWriting(typeTst: any) {
    let res2
    let res3
    try {
         res2 = await this.startTestWritingApi.startFreeTestWriting(this.testId);
        if (res2.status === 200) {
            let payload = {
                id: this.testId,
                type: typeTst,
            };
            try{
                 res3 = await this.startTestWritingApi.getFreeTestPartOneDataApiWriting(payload);
                if(res3.status === 200 ){
                    if(typeTst === 'part1'){
                        this.part1Questions.image=res3.data.part1.image;
                        this.part1Questions.question=res3.data.part1.question_name;
                    }else if(typeTst === 'part2'){
                        this.part2Questions.title = res3.data.part2.title;
                        this.part2Questions.question = res3.data.part2.question_name;
                    }else{
                        this.part1Questions.image=res3.data.full.part1.image;
                        this.part1Questions.question=res3.data.full.part1.question_name;
                        this.part2Questions.title = res3.data.full.part2.title;
                        this.part2Questions.question = res3.data.full.part2.question_name;
                    }
                    this.isLoading = false;
                }else {
                    this.isLoading = false;
                    this.tryAgainButton = true;
                    this.errorMessage = res3.data.detail;
                } 
            }catch(err){
                this.isLoading = false;
                this.tryAgainButton = true;
                this.errorMessage = res3.data.detail;
                console.error(err);
            } 
        }else {
            this.isLoading = false;
            this.tryAgainButton = true;
            this.errorMessage = res2.data.detail;
        } 
    } catch(err) {
        this.isLoading = false;
        this.tryAgainButton = true;
        this.errorMessage = res2.data.detail;
        console.error(err);
    }   
  }

//   async getFreeTestPartOneDataWriting(tId: any, tType: any) {
//     try{
//     let payload = {
//       id: tId,
//       type: tType,
//     };
//     //this.isLoading = true;
//     const res = await this.startTestWritingApi.getFreeTestPartOneDataApiWriting(payload);
    

//     }catch(e){
//       console.log(e,"getFreeTestWritingPartOneData")
//     }
//   }

async setIsTestEnded(value:any){
    this.isTestEnded = value;
}

    async submitAnswerWriting(answer: any, partType: any) {
        this.isTestEnded = true;
        try {
            let payload = {
                test_id: this.testId,
                answer: answer,
                submit_answer_type: partType
            }
            if(!answer){
                payload['answer'] = "Not Attempted"
            }
            const res = await this.startTestWritingApi.submitAnswerApiWriting(payload);
            if (res.status === 200) {
                if(this.testType !== "full"){
                  this.completeTest();
                }else if(partType === "part1"){
                    this.submitTestStatus.part1 = true;
                }else if(partType === "part2"){
                    this.submitTestStatus.part2 = true;
                }
            } else {
                this.isTestEnded = false;
                this.completeTestFailed = true;
                if(partType === "part1"){
                    this.submitTestStatus.part1 = false;
                }else if(partType === "part2"){
                    this.submitTestStatus.part2 = false;
                }
            }
        } catch (err) {
            this.isTestEnded = false;
            this.completeTestFailed = true;
            if(partType === "part1"){
                this.submitTestStatus.part1 = false;
            }else if(partType === "part2"){
                this.submitTestStatus.part2 = false;
            }
            console.error(err)
        }
    }

  async completeTest(){
    try{
        let tId = this.testId;
        let response = await this.startTestWritingApi.endTestApiWriting(tId);
        if(response.status === 200){
            this.isTestEnded = null
            this.completeTestFailed = false;
            this.submitTestStatus.part1 = false;
            this.submitTestStatus.part2 = false;
            return response.data.message
        }else {
            this.isTestEnded = false;
            this.completeTestFailed = true;
            this.submitTestStatus.part1 = false;
            this.submitTestStatus.part2 = false;
        }     
    }catch(e){
        this.completeTestFailed = true;
        this.isTestEnded = false;
        this.submitTestStatus.part1 = false;
        this.submitTestStatus.part2 = false;
        console.error(e);
    }
  }
}