import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { StoreContext } from './Hooks/use-store';
import { RootStore } from './store/root-store';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(

     <StoreContext.Provider value={RootStore}>
    <App/>
  </StoreContext.Provider>
  
);
reportWebVitals();
