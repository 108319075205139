import ReadingPartTest from './ReusablePart'
import { useStore } from "../../../Hooks/use-store";
import { observer } from 'mobx-react-lite';


const Readingpart2 = observer(({ nextStep }: any) => {
    const { startTestStoreReading } = useStore();
    const { part2Questions } = startTestStoreReading;
    const part2StateData = {
      singleAnswer : startTestStoreReading.part2SingleAnswer,
      setSingleAnswer : startTestStoreReading.setPart2SingleAnswer,
      multipleAnswer : startTestStoreReading.part2MultipleAnswer,
      setMultipleAnswer : startTestStoreReading.setPart2MultipleAnswer,
      taskIdentifyingItemAnswers : startTestStoreReading.part2TaskIdentifyingItemAnswers,
      setTaskIdentifyingItemAnswers : startTestStoreReading.setPart2TaskIdentifyingItemAnswers,
      noteCompletionAnswer : startTestStoreReading.part2NoteCompletionAnswer,
      setNoteCompletionAnswer : startTestStoreReading.setPart2NoteCompletionAnswer,
      senetenceComplete : startTestStoreReading.part2SenetenceComplete,
      setSenetenceComplete : startTestStoreReading.setPart2SenetenceComplete,
      summaryCompletionOneWordAnswers : startTestStoreReading.part2SummaryCompletionOneWordAnswers,
      setSummaryCompletionOneWordAnswers : startTestStoreReading.setPart2SummaryCompletionOneWordAnswers,
      dragAnswers : startTestStoreReading.part2DragAnswers,
      setDragAnswers : startTestStoreReading.setPart2DragAnswers,
      dragAndDropAnswers : startTestStoreReading.part2DragAndDropAnswers,
      setDragAndDropAnswers : startTestStoreReading.setPart2DragAndDropAnswers,
      dragSentenceAnswers : startTestStoreReading.part2DragSentenceAnswers,
      setDragSentenceAnswers : startTestStoreReading.setPart2DragSentenceAnswers,
      draggedCurrentSentence : startTestStoreReading.part2DraggedCurrentSentence,
      setDraggedCurrentSentence : startTestStoreReading.setPart2DraggedCurrentSentence,
      tableCompletionAnswer : startTestStoreReading.part2TableCompletionAnswer,
      setTableCompletionAnswer : startTestStoreReading.setPart2TableCompletionAnswer,
      matchingHeadingOptions : startTestStoreReading.part2MatchingHeadingOptions,
      setMatchingHeadingOptions : startTestStoreReading.setPart2MatchingHeadingOptions,
      matchingHeadingAnswer : startTestStoreReading.part2MatchingHeadingAnswer,
      setMatchingHeadingAnswer : startTestStoreReading.setPart2MatchingHeadingAnswer,
      matchingFeatureAnswers : startTestStoreReading.part2MatchingFeatureAnswers,
      setMatchingFeatures : startTestStoreReading.setPart2MatchingFeatures,
    }


  return (
    <ReadingPartTest store={startTestStoreReading} stateData={part2StateData} paraQuestions={part2Questions} nextStep={nextStep} partType={'part2'}/>
  )
})

export default Readingpart2