import apiSauce from "./api-sauce";

export class UserApi{

async requestOtp(payload: any) : Promise<any>{
    return await apiSauce.apisauce?.post('/send-otp',JSON.stringify(payload))
}

async verifyOtp(payload:any) : Promise<any>{
    return await apiSauce.apisauce?.post('/verify-otp?version=2.0',JSON.stringify(payload))
}

async requestOtpEmail(payload: any) : Promise<any>{
    return await apiSauce.apisauce?.post('/send-otp-email',JSON.stringify(payload))
}

async verifyOtpEmail(payload:any) : Promise<any>{
    return await apiSauce.apisauce?.post('/verify-otp-email?version=2.0',JSON.stringify(payload))
}
async updateOAuthMobile(payload:any):Promise<any>{
    return await apiSauce.apisauce?.post('/submit',JSON.stringify(payload))
}

async updateUserApi(payload :any,first_Login : boolean):Promise<any> {
    return await apiSauce.apisauce?.post(`/update-user?is_first_login=${first_Login}&version=2.0`,JSON.stringify(payload))
}

async loggedInUserApi():Promise<any>{
    return await apiSauce.apisauce?.get('/logged-in-user')
}
async deleteUserApi():Promise<any>{
    return await apiSauce.apisauce?.delete('/delete-user')

}

async uploadProfilePictureApi(file:any):Promise<any>{

    return await apiSauce.apisauce?.post('/update-picture',file)
}

async deleteProfilePicApi():Promise<any>{
    return await apiSauce.apisauce?.delete('/delete-profile-image')
}

async getRefreshToken(payLoad :any):Promise<any>{
    return await apiSauce.apisauce?.post('/get_auth_token?version=2.0',payLoad);
}

async getSsoLoginToken(token : string){
    return await apiSauce.apisauce?.get(`/sso/login?token=${token}`);
}

async checkUserExistOrNot(payload : any){
    return await apiSauce.apisauce?.post('/checkUserExistOrNot',payload);
}

}