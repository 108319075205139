import { useEffect } from "react";
import InstructionCard from "../../instructionsCard/instructionCard";
import { useStore } from "../../../Hooks/use-store";
import { observer } from "mobx-react-lite";

const Part2Test = observer((props:any) => {
  let { startTestStore } = useStore();

  useEffect(()=>{
    startTestStore.setCurrentStep("step2")
    startTestStore.setCounter(false)
  },[])

  let PartTwoINstructions = [
    "1.  Please have pen & paper ready for note taking.    ",
    "2.In this part, examiner will give you a task card which asks you to talk about a particular topic.    ",
    "3.You will be given 1 minute to prepare and 1-2 minutes to talk about the topic.    ",
    "4.You will not be interrupted during this time, so it is important to keep talking    ",
  ];

  return (
    <div className="w-full mx-auto">
        <InstructionCard testType="Part 2" instructions={PartTwoINstructions} />
    </div>
  );
});

export default Part2Test;
