import { observer } from "mobx-react-lite";
import CheckCircle from "../../icons/CheckCircle.png";
import { useEffect } from "react";
import EndTestLoader from "../parts/partTestCompleted/endTestLoader";

const PartTestCompletedCard = observer(
  ({ currentPartName, nextPartName, nextStep ,startTeststore,resultStore,submitTest,submitAnswer,module,handleClose}: any) => {

  useEffect(() => {
    submitAnswer();
  } ,[]);
    return (
      <div
        style={{
          background: "linear-gradient(216deg, #FFF9FA 1.21%, #FFF 97.42%)",
          border: "1px solid rgba(227, 24, 55, 0.30)",
        }}
        className="lg:w-[40%] w-[90%] mx-auto my-auto rounded-md border border-solid border-[rgba(227, 24, 55, 0.30)] bg-gradient-to-br from-[#FFF9FA] via-transparent to-[#FFF] py-5  h-auto mx-10 "
      >
        <div className="flex flex-col justify-center items-center ">
          {nextPartName && <>
            <h1 className="font-extrabold text-xl mt-4">Good Job</h1>
          <h1 className="text-2xl font-[350] mb-6">
            {currentPartName} is Completed
          </h1>
          <img className="mt-2 mb-6" src={CheckCircle} alt="checkcircle"/>
          </>}
          
          {nextPartName ? (<>
            <h1 className="text-center text-xl font-[350]">
              Click <span className="text-[#E31837]">Continue Test </span>to{" "}
              <br /> start {nextPartName}
            </h1>
            <button
            className="mx-auto bg-red-500 rounded-full px-4 py-2 text-white"
            onClick={nextStep}
          >
            Continue Test
          </button>
          </>
           
          ) : (
           <EndTestLoader store={startTeststore} handleClose={handleClose} module="writing"/>
          )}
          {/* <div className="flex justify-center mt-10" >
            {(startTeststore.testType === "part1" || startTeststore.testType === "part2" || startTeststore.testType === "part3")?
            <button
            className="mx-auto bg-red-500 rounded-full px-4 py-2 text-white"
            onClick={submitTest}
          >
             Back to Home
          </button> :<button
              className="mx-auto bg-red-500 rounded-full px-4 py-2 text-white"
              onClick={nextStep}
            >
              {nextPartName ? "Continue Test" : "Back to Home"}
            </button>}
            
          </div>{" "} */}
        </div>
      </div>
    );
  }
);

export default PartTestCompletedCard;
