
import upArrow from '../../icons/upArrowAccordian.png';
import downArrow from '../../icons/downArrowAccordian.png';
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";


const PartHeader = (props: any) => {
    let pathColor; // Default path color
    let trailColor;
    let textColor;
  
    if (props.percentage >= 0 && props.percentage <= 50) {
      pathColor = " #FF495C";
      trailColor = "#FFC2B6";
      textColor = "#FF495C";
    }
    if (props.percentage > 50 && props.percentage <= 77) {
      pathColor = "#FFBB28";
      trailColor = "#FFECA9";
      textColor = "#FFBB28";
    }
    if (props.percentage > 77 && props.percentage <= 100) {
      pathColor = "#59A51A";
      trailColor = "#DCF8A7";
      textColor = "#59A51A";
    }
  const accordianControl = () => {
    if(props.type === "Part1"){
      let partA = {...props.accordian}
      partA.part1 = !partA.part1
    props.setAccordian(partA)
    }else if(props.type === "Part2"){
      let partB = {...props.accordian}
      partB.part2 = !partB.part2
    props.setAccordian(partB)
    }else{
      let partC = {...props.accordian}
      partC.part3 = !partC.part3
    props.setAccordian(partC)
    }
  }
  
  const iconAccordian = () => {
    if (props.type === "Part1" && props.accordian.part1) {
      return upArrow;
    } else if (props.type === "Part2" && props.accordian.part2) {
      return upArrow;
    } else if (props.type === "Part3" && props.accordian.part3) {
      return upArrow;
    } else {
      return downArrow;
    }
  };
  
    return (
      <button className="h-[80px] flex flex-row justify-between  border-[#E3E5E9]" onClick={accordianControl} >
        <div className="text text-[#E31837] text-xl font-semibold pl-[16px] pt-[29px] md:pr-4">
          <span className="flex flex-row">{props.type} <img src={iconAccordian()} alt='accordian' /></span>
        </div>
        
        <div className="text-[#4E5664] flex flex-row pt-[11px] pr-[16px]">
          <div className="flex justify-center items-center pr-[8px]">
            Band Score
          </div>{" "}
          <div className="w-[60px] h-[60px]">
            {" "}
            <CircularProgressbar
              value={props.percentage}
              text={props.fraction}
              styles={buildStyles({
                rotation: 1,
                strokeLinecap: "round",
                textSize: "16px",
                pathTransitionDuration: 0.5,
                pathColor: pathColor,
                textColor: textColor,
                trailColor: trailColor,
              })}
            />
          </div>
        </div>
      </button>
    );
  };

export default PartHeader