import { useState, useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import Select from "react-select";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useStore } from "../../Hooks/use-store";
import BottomRightIcon from "../../icons/undraw_different_love_a-3-rg 1-right (1).png";
import BottomLeftIcon from "../../icons/undraw_different_love_a-3-rg 1-left.png";
import PhoneInput from "react-phone-input-2";
import { isValidPhoneNumber } from "react-phone-number-input";
import { toJS } from "mobx";
import { toast } from "react-toastify";

type OptionType = {
  label: string;
  value: string;
  isDisabled?: boolean;
};

function FirstLoginScreen() {
  const { step } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { userStore } = useStore();

  const [buttonDisabled, setButtonDisabled] = useState<any>({
    name: true,
    email: !userStore.isEmail,
    country: true,
    month: true,
    mobileNumber: userStore.isEmail,
  });
  const [errorState, setErrorState] = useState({
    email: false,
    mobileNumber: false,
  });
  const [formData, setFormData] = useState({
    name: "Before we start, please tell us your name",
    inputPlaceholder: "Name",
    buttonText: "Next",
    selectedOptions: null,
  });
  const emailExists = useRef(false);
  const mobileExists = useRef(false);
  const [isValid, setIsValid] = useState(false);

  console.log(toJS(buttonDisabled), "buttonDisabled");

  const countryOptions: OptionType[] = [
    { label: "India", value: "india" },
    { label: "USA", value: "usa" },
    { label: "UK", value: "uk" },
    { label: "Canada", value: "canada" },
    { label: "Australia", value: "australia" },
  ];

  const monthOptions: OptionType[] = [
    { label: "Select", value: "", isDisabled: true },
    { label: "Within a week", value: "one_week" },
    { label: "Within 2 weeks", value: "two_weeks" },
    { label: "Within a month", value: "one_month" },
    { label: "Within 3 months", value: "three_months" },
  ];

  const customStyles = {
    control: (base: any) => ({
      ...base,
      border: "1px solid #E31837",
      borderRadius: "1.5rem",
      boxShadow: "none",
      "&:hover": { borderColor: "#E31837" },
    }),
    option: (base: any) => ({
      ...base,
      background: "transparent",
      border: "none",
      color: "#4E5664",
    }),
  };

  const { name, inputPlaceholder, buttonText } = formData; //

  const handleNextClick = async () => {
    if (
      location.pathname === "/details" &&
      userStore.studentDetails.mobileNumber
    ) {
      navigate("/details/email");
    } else if (
      location.pathname === "/details" &&
      userStore.studentDetails.emailId
    ) {
      navigate("/details/mobileNumber");
    } else if (step === "email" || step === "mobileNumber") {
      const res = await userStore.checkUserExistOrNot();
      if (res?.status === 200) {
        navigate("/details/country");
      } else {
        toast.error((res?.data as any)?.detail || "An error occurred");
      }
    } else if (step === "country") {
      navigate("/details/month");
    }

    if (step === "month") {
      // userDetails is not null, so you can safely access its properties
      let res = await userStore.updateUser();
      try {
        if (res.status === 200) {
          navigate(`/${userStore.userDashboards[0].toLowerCase()}`);
        } else {
          toast.error(res?.data?.detail);
        }
      } catch (err) {
        console.log(err, "err678");
      }
    }
  };

  const handleSelectChange = (selectedValues: any) => {
    if (step === "country") {
      userStore.updateCountry(selectedValues.value);
    } else if (step === "month") {
      let data = JSON.parse(JSON.stringify(userStore.studentDetails));
      data.month = selectedValues.value;
      data.when_Exam = selectedValues.value;
      userStore.updateStudentDetails(data);
      userStore.updateMonth(selectedValues.value);
    }
    setFormData({ ...formData, selectedOptions: selectedValues.value });
  };

  const handleEmail = (e: any) => {
    const { value } = e.target;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    userStore.updateEmailId(value);

    if (emailRegex.test(value)) {
      setErrorState({ email: false, mobileNumber: false });
    } else {
      setErrorState({ email: true, mobileNumber: false });
    }
  };
  const handleOnChange = (value: any, country: any) => {
    const isValid = isValidPhoneNumber("+" + value);
    console.log(isValid, "isValid");
    if (isValid) {
      setErrorState({ email: false, mobileNumber: false });
    } else {
      setErrorState({ email: false, mobileNumber: true });
    }
    setIsValid(isValid);
    userStore.updateMobileNumber(`+${value}`);
  };

  useEffect(() => {
    if (userStore.studentDetails.emailId.trim() !== "") {
      emailExists.current = true;
    } else {
      emailExists.current = false;
    }
    if (userStore.studentDetails.mobileNumber.trim() !== "") {
      mobileExists.current = true;
    } else {
      mobileExists.current = false;
    }
  }, []);

  console.log(
    mobileExists.current,
    isValid,
    !userStore.studentDetails.mobileNumber,
    "mobileExists.current"
  );

  useEffect(() => {
    if (step === "email") {
      setFormData({
        name: "Could you please share your email address with us?",
        inputPlaceholder: "Email",
        buttonText: "Next",
        selectedOptions: userStore.studentDetails.emailId || null,
      });
      setButtonDisabled((prevState: any) => ({
        ...prevState,
        email: !userStore.studentDetails.emailId,
      }));
    } else if (step === "mobileNumber") {
      setFormData({
        name: "Could you please share your mobileNumber number with us?",
        inputPlaceholder: "mobileNumber",
        buttonText: "Next",
        selectedOptions: userStore.studentDetails.mobileNumber || null,
      });
      setButtonDisabled((prevState: any) => ({
        ...prevState,
        mobileNumber: !(userStore.studentDetails.mobileNumber && isValid),
      }));
    } else if (step === "country") {
      setFormData({
        name: "Which country do you belong to?",
        inputPlaceholder: "Country",
        buttonText: "Next",
        selectedOptions: userStore.studentDetails.selectedCountry || null,
      });
      setButtonDisabled((prevState: any) => ({
        ...prevState,
        country: !userStore.studentDetails.selectedCountry,
      }));
    } else if (step === "month") {
      setFormData({
        name: "When are you planning to give the exam?",
        inputPlaceholder: "Month",
        buttonText: "Continue",
        selectedOptions: userStore.studentDetails.selectedMonth || null,
      });
      setButtonDisabled((prevState: any) => ({
        ...prevState,
        month: !userStore.studentDetails.selectedMonth,
      }));
    } else {
      setButtonDisabled((prevState: any) => ({
        ...prevState,
        name: !userStore.studentDetails.studentName,
      }));
    }
  }, [
    step,
    userStore.studentDetails.selectedCountry,
    userStore.studentDetails.selectedMonth,
    userStore.studentDetails.studentName,
    userStore.studentDetails.emailId,
    userStore.studentDetails.mobileNumber,
    isValid,
  ]);

  useEffect(() => {
    if (step === "email" && emailExists.current) {
      const timer = setTimeout(() => {
        handleNextClick();
      }, 2000);
      return () => clearTimeout(timer);
    } else if (step === "mobileNumber" && mobileExists.current) {
      const timer = setTimeout(() => {
        handleNextClick();
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [step]);

  const options: Array<any> =
    inputPlaceholder === "Country" ? countryOptions : monthOptions; // Changed dynamic options handling

  const selectedValue =
    inputPlaceholder === "Month"
      ? monthOptions.find(
          (option) => option.value === userStore.studentDetails.selectedMonth
        )
      : countryOptions.find(
          (option) => option.value === userStore.studentDetails.selectedCountry
        );

  const renderInputFeild = () => {
    if (inputPlaceholder === "Country" || inputPlaceholder === "Month") {
      return (
        <div className="mb-4 w-[80%]">
          <Select
            value={selectedValue}
            onChange={handleSelectChange}
            options={options}
            styles={customStyles}
            isOptionDisabled={(option) => !!option.isDisabled}
          />
        </div>
      );
    } else if (inputPlaceholder === "Name") {
      return (
        <input
          type="text"
          placeholder={inputPlaceholder}
          className="w-[80%] h-12 border-[1px] rounded-3xl border-red-600 mb-4 px-2"
          onChange={(e) => userStore.updateStudentName(e.target.value)}
          value={userStore.studentDetails.studentName}
        />
      );
    } else if (inputPlaceholder === "Email") {
      return (
        <>
          <input
            type="text"
            disabled={emailExists.current}
            placeholder={inputPlaceholder}
            className="w-[80%] h-12 border-[1px] rounded-3xl border-red-600 mb-2 px-2"
            onChange={(e) => handleEmail(e)}
            value={userStore.studentDetails.emailId}
          />
          {errorState.email && (
            <p className="mb-2 text-[#E31837] text-xs">
              Enter a valid email address to proceed.
            </p>
          )}
        </>
      );
    } else if (inputPlaceholder === "mobileNumber") {
      return (
        <div className="w-full md:w-[80%] mb-3">
          <PhoneInput
            containerClass="w-full"
            inputClass="h-12 border border-[1px] rounded-3xl border border-red-600 mb-4 px-2"
            country={"in"}
            placeholder=""
            value={userStore.studentDetails.mobileNumber}
            onChange={handleOnChange}
            disabled={mobileExists.current}
          />
          {errorState.email && (
            <p className="mb-2 text-[#E31837] text-xs">
              Enter a valid email address to proceed.
            </p>
          )}
        </div>
      );
    }
  };

  console.log(buttonDisabled, "buttonDisabled");

  return (
    <>
      <div className="bg-[#FFC9C9] w-full min-h-screen flex justify-center items-center relative">
        <div className="flex flex-col justify-center items-center border rounded-2xl bg-[#FFFFFF] lg:w-[50%] md:w-[50%] w-3/4  max-w-screen-md p-4 z-[999]">
          <h1 className="text-[#E31837] text-4xl font-manrope font-semibold tracking-wider text-center mb-4">
            IELTS
          </h1>
          <p className="text-[#3C424C] text-lg font-extrabold text-center mb-4">
            {name}
          </p>

          {renderInputFeild()}

          {!(inputPlaceholder === "Email" && emailExists.current) &&
          !(inputPlaceholder === "mobileNumber" && mobileExists.current) ? (
            <button
              onClick={handleNextClick}
              disabled={
                buttonDisabled[step ?? "name"] ||
                errorState.email ||
                errorState.mobileNumber
              }
              className={`text-[#FFFFFF] bg-[#E31837] w-[80%] h-12 border-[1px] rounded-3xl mb-4 px-120 ${
                buttonDisabled[step || "name"] ||
                errorState.email ||
                errorState.mobileNumber
                  ? "bg-gray-300 cursor-not-allowed"
                  : ""
              }`}
            >
              {buttonText}
            </button>
          ) : (
            <div className="directLoader"></div>
          )}
        </div>
      </div>
      <img
        src={BottomRightIcon}
        alt="right"
        className="absolute bottom-0 right-0"
      />
      <img
        src={BottomLeftIcon}
        alt="left"
        className="absolute bottom-0 left-0"
      />
    </>
  );
}

export default observer(FirstLoginScreen);
