import { observer } from "mobx-react-lite";
import { useStore } from "../../Hooks/use-store";
import ConfirmYourDetails from "../common/confirmYourDetails";
import WritingInstructions from "./writingInstructions";
import WritingPart1 from "./parts/WritingPart1";
import PartTestCompletedCard from "../common/PartTestCompletedCard";
import WritingPart2 from "./parts/WritingPart2";
import { useNavigate } from "react-router-dom";
import closeIcon from "../../icons/closeTest.png";
import { useEffect, useState } from "react";
import profileImage from "../../icons/UserCircle.png";
import clockIcon from "../../icons/Alarm.png";
import WritingTimer from "../common/writingTimer";
import Loader from "../../../src/Loader/index";
import { Shellwrapper } from "../Routing/RoutingC";
import ErrorPage from "../../pages/errorPage";

const FullTestWriting = observer(() => {
  const { resultsStoreWriting, userStore, startTestStoreWriting } = useStore();
  const { stepManagement } = resultsStoreWriting;
  const { currentStepName, currentPartName, nextPartName } = stepManagement;
  const navigate = useNavigate();
  const { writingPart1Answer, writingPart2Answer } = resultsStoreWriting;
  const { testType, partType, tryAgainButton, isLoading, errorMessage } =
    startTestStoreWriting;

    const [isOverflowHidden, setIsOverflowHidden] = useState(false);

  const submitTest = () => {
    resultsStoreWriting.setStepManagement("confirm_your_details", "", "");
    navigate("/writing");
  };

  const submitAnswer = () => {
    if (testType === "full") {
      if (partType === "part2") {
        startTestStoreWriting.submitAnswerWriting(
          writingPart1Answer.text,
          "part1"
        );
        startTestStoreWriting.submitAnswerWriting(
          writingPart2Answer.text,
          "part2"
        );
      }
    } else {
      let answer =
        partType === "part1"
          ? writingPart1Answer.text
          : writingPart2Answer.text;
      startTestStoreWriting.submitAnswerWriting(answer, partType);
    }
  };
  useEffect(() => {
    // Calculate content height and toggle overflow-hidden accordingly
    const contentElement = document.getElementById("content");
    if (contentElement) {
      const hasOverflow =
        contentElement.scrollHeight > contentElement.clientHeight;
        setIsOverflowHidden(hasOverflow);
    }
  }, []);

  useEffect(() => {
    if (startTestStoreWriting.completeTestFailed) {
      resultsStoreWriting.setStepManagement(
        "part_test_completed",
        "Part 1",
        ""
      );
    }
  }, []);

  useEffect(() => {
    if (
      startTestStoreWriting.submitTestStatus.part1 &&
      startTestStoreWriting.submitTestStatus.part2
    ) {
      startTestStoreWriting.completeTest();
    }
  }, [
    startTestStoreWriting.submitTestStatus.part1,
    startTestStoreWriting.submitTestStatus.part2,
  ]);

  const nextStep = () => {
    if (currentStepName === "confirm_your_details") {
      resultsStoreWriting.setStepManagement("writing_instructions", "", "");
    }

    if (currentStepName === "writing_instructions") {
      resultsStoreWriting.setActiveTab(0);
      startTestStoreWriting.setTimer(3600);
      resultsStoreWriting.setStepManagement("Part 1", "", "");
    }

    if (currentStepName === "Part 1") {
      if (testType != "full") {
        resultsStoreWriting.setStepManagement(
          "part_test_completed",
          "Part 1",
          ""
        );
      } else {
        resultsStoreWriting.setStepManagement(
          "part_test_completed",
          "Part 1",
          "Part 2"
        );
      }
    }

    if (
      currentStepName === "part_test_completed" &&
      currentPartName === "Part 1"
    ) {
      resultsStoreWriting.setActiveTab(1);
      resultsStoreWriting.setStepManagement("Part 2", "Part 2", "");
    }

    if (currentStepName === "Part 2") {
      resultsStoreWriting.setStepManagement(
        "part_test_completed",
        "Part 2",
        ""
      );
    }
    if (
      currentStepName === "part_test_completed" &&
      currentPartName === "Part 2"
    ) {
      resultsStoreWriting.setStepManagement("confirm_your_details", "", "");
      startTestStoreWriting.setTimer(3600);
      navigate("/writing");
    }
  };

  const handleClose = (navigation : boolean) => {
    navigation && navigate("/writing");
    startTestStoreWriting.setCompleteTestFailed(false);
    startTestStoreWriting.setIsTestEnded(null);
    resultsStoreWriting.setStepManagement("confirm_your_details", "", "");
    startTestStoreWriting.setTimer(3600);
    startTestStoreWriting.testId = null;
    startTestStoreWriting.testType = null;
  };
  useEffect(() => {
    resultsStoreWriting.setWritingPart1Answers("", "");
    resultsStoreWriting.setWritingPart2Answers("", "");
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
      localStorage.setItem("reload", "true");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    if (localStorage.getItem("reload")) {
      resultsStoreWriting.setLoading(true);
      localStorage.removeItem("reload");
      resultsStoreWriting.setStepManagement("confirm_your_details", "", "");
      startTestStoreWriting.setTimer(3600);
      navigate("/writing");
    } else {
      localStorage.removeItem("reload");
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [navigate]);

  useEffect(() => {
    window.addEventListener("popstate", () => {
      resultsStoreWriting.setStepManagement("confirm_your_details", "", "");
      startTestStoreWriting.setCompleteTestFailed(false);
      startTestStoreWriting.setIsTestEnded(null);
      startTestStoreWriting.setTimer(3600);
      startTestStoreWriting.testId = null;
      startTestStoreWriting.testType = null;
      navigate("/writing");
    });

    // Clean up the event listener when the component unmounts
    return () => window.removeEventListener("popstate", () => {});
  }, []);

  const errorPage = () => {
    if (tryAgainButton) {
      return (
        <div>
          <Shellwrapper
            component={ErrorPage}
            errorMessage={errorMessage}
            onBackToHome={() => {
              navigate("/writing");
            }}
          />
        </div>
      );
    } else {
      return (
        <div className={` ${isOverflowHidden ? "overflow-hidden" : ""}`}>
          <header className="fixed top-0 left-0 w-full flex justify-between border-b border-b-[#E3E5E9] bg-white text-white p-4">
            {currentStepName !== "confirm_your_details" ? (
              <div className="flex mx-4  ">
                <img src={profileImage} alt="profile" className="mr-2" />
                <div className="text-[#8F98A9] md:text-xl text-sm flex items-center">
                  {" "}
                  {userStore.studentDetails.studentName}
                </div>
              </div>
            ) : (
              <div className="text-[#292D35] md:text-xl text-sm">
                {" "}
                Full Test
              </div>
            )}
            {(currentStepName === "Part 1" || currentStepName === "Part 2") && (
              <div className="flex h-[25px] md:h-full my-auto">
                <img src={clockIcon} alt="clockIcon" />
                <div className=" items-center">
                  <WritingTimer
                    store={startTestStoreWriting}
                    resultStore={resultsStoreWriting}
                  />
                </div>
              </div>
            )}

            <div className="flex flex-row h-[25px] md:h-full cursor-pointer">
              <button onClick={() => handleClose(true)}>
                <img src={closeIcon} alt="close" />
              </button>
            </div>
          </header>

          {currentStepName === "confirm_your_details" && (
            <div className="flex flex-col min-h-screen items-center justify-center pt-16 w-full">
              <ConfirmYourDetails nextStep={nextStep} />
            </div>
          )}
          {currentStepName === "writing_instructions" && (
            <div className="min-h-screen pt-24">
              <WritingInstructions nextStep={nextStep} />
            </div>
          )}
          {currentStepName === "Part 1" && (
            <div className="min-h-screen pt-16">
              <WritingPart1 nextStep={nextStep} />{" "}
            </div>
          )}
          {currentStepName === "part_test_completed" && (
            <div className="min-h-screen pt-10 flex items-center justify-center">
              <PartTestCompletedCard
                nextStep={nextStep}
                currentPartName={currentPartName}
                nextPartName={nextPartName}
                startTeststore={startTestStoreWriting}
                resultStore={resultsStoreWriting}
                submitTest={submitTest}
                submitAnswer={submitAnswer}
                handleClose={handleClose}
                module="Writing"
              />
            </div>
          )}
          {currentStepName === "Part 2" && (
            <div className="min-h-screen pt-16">
              <WritingPart2 nextStep={nextStep} />{" "}
            </div>
          )}
        </div>
      );
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="flex w-full flex-col mx-auto py-8 my-20 items-center ">
          <Loader />
        </div>
      ) : (
        errorPage()
      )}
    </>
  );
});

export default FullTestWriting;
