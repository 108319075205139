import apiSauce from "./api-sauce";

export class StartTestListeningApi{

    async fetchFreeTestDataListening():Promise<any>{
        return await apiSauce.apisauce?.get('/free-listening-tests')

    }
    async generateTestListening(typeTest:any):Promise<any>{
        return await apiSauce.apisauce?.post(`/generate-listening-test?type=${typeTest}`)
    }

    async startFreeTestListening(id:any){
        return await apiSauce.apisauce?.post(`/start-listening-test?test_id=${id}`)
    }

    async getTestListening(payload:any){
        return await apiSauce.apisauce?.get(`/get-listening_test?test_id=${payload.id}&type=${payload.type}`)
    }

    async submitTestListening(payload:any){
        return await apiSauce.apisauce?.post(`/submit-listening-answer`, payload)
    }

    async endTestListening(payload:any){
        return await apiSauce.apisauce?.post(`/end-listening-test`,payload)
    }

}