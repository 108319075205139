import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import React from 'react';

interface Label {
  name: string;
  id:number;
}

interface UseBoxCustomHookProps {
  value: number;
  handleTabs: (event: React.SyntheticEvent, newValue: number | null) => void;
  tabLabels: Label[]; 
  children?: React.ReactNode;
}

const BoxTabs = ({
  value,
  handleTabs,
  tabLabels,
  children,
}: UseBoxCustomHookProps) => {
  return (
    <div>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleTabs}
            TabIndicatorProps={{
              style: {
                backgroundColor: "red",
                height: "4px",
                borderRadius: "10px 10px 0px 0px",
              }, // Change the indicator color to red
            }}
            sx={{
              // Custom style for selected tab
              "& .MuiTabs-indicator": {
                backgroundColor: "red",
              },
              "& .MuiTab-root": {
                color: "#737E93",
                fontSize: "20px",
                fontWeight: "500",
              },
              "& .Mui-selected": {
                color: "red",
              },
              ".Mui-selected": {
                color: "red",
                fontSize: "20px",
                fontWeight: "600",
              },
            }}
          >
            {tabLabels.map((label) => (
              <Tab key={label.id} label={label.name} />
            ))}
          </Tabs>
        </Box>
      </Box>
      {children}
    </div>
  );
}

export default BoxTabs;
