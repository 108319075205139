import React,{useState,useEffect} from 'react'
import { create } from 'apisauce';
import { Switch } from '@headlessui/react'
import Loader from "../../src/Loader/index";
import crossCircle from "../icons/cross-circle-svgrepo-com.png"
import checkCircle from "../icons/checkCircleGreen.svg"

const hostURL = window.location.host;
const cambriURL = hostURL === 'ielts.cambri.ai' || hostURL === 'ielts-web-app-production.web.app' ? 'https://api.cambri.ai' : 'https://dev.api.cambri.ai';

const CambriUtilities = () => {

    const [actionType,setActionType] = React.useState();

    const handleActionChange = (e:any) => {
      let value = e.target.value;
      setActionType(value);
    }
  
    const customComponent = (action:any) => {
       switch (action) {
        case 'userData' : return (<UsersData/>);
        case 'payments' : return (<PaymentsData/>);
      }  
    }

  return (
    <>
        <div className='text-xl'>
            <p>Choose an action</p>
            <select className='w-72 border rounded px-2 py-1' value={actionType} onChange={(e) => handleActionChange(e)}>
             <option >--select an option--</option>
             <option value="userData">User's Data</option>
             <option value="payments">Payments</option>
            </select>
        </div>
        <div className='my-10 relative'>
            {customComponent(actionType)}
        </div>
    </>
  )
}

export default CambriUtilities


export const UsersData = () => {

    const [data, setData] = useState<Array<any>>([]);
    const [loading,setLoading] = useState<boolean>();
    const [error,setError] = useState<boolean>();
    const [proEnabled, setProEnabled] = useState(false);
    const [businessUserEnabled, setBusinessUserEnabled] = useState(false);

    useEffect(() => {
        getProUsers();
    },[])

    const getProUsers = async () => {
        setLoading(true);
        try{
           let response : any = await create({
            baseURL: cambriURL,
            headers: {
              'Accept': "application/json",
              'Content-Type': 'application/json'
            },
           }).get(`/api/v1/admin/get_all_users`);
           if (response?.status === 200){
            setData(response.data);
            setLoading(false);
           }else{
            setLoading(false);
            setError(true);
           }
        }catch (err) {
          setLoading(false);
          setError(true);
          console.error(err);
        }
      }

    let tableData;    
    if (proEnabled && businessUserEnabled) {
      tableData = data.filter((item) => item.premium_user && item.isenterprise);
    } else if (proEnabled) {
      tableData = data.filter((item) => item.premium_user);
    } else if (businessUserEnabled) {
      tableData = data.filter((item) => item.isenterprise);
    } else {
      tableData = data;
    }


    const sortTableData = (data  :any) => {
      return data.sort((a : any, b:any) => {
        const dateA = new Date(a.audit.created).getTime();
        const dateB = new Date(b.audit.created).getTime();
        return dateB - dateA;
      });
    };

    let sortedTableData = sortTableData(tableData);

    const renderContent = () => {
      if (loading) {
        return (
          <div className="w-[10%] mx-auto my-36">
            <Loader />
          </div>
        );
      } else if (error) {
        return (<p className="text-4xl flex items-center justify-center">Api Failure</p>);
      } else {
        return (
          <div>
            <div className='w-[90%] flex flex-wrap mx-auto '>
              <div className='min-w-[12rem] px-3 py-5 my-5 mr-10 border shadow-md rounded-xl bg-slate-50'>
                <h3 className='text-xl font-semibold'>Total users</h3>
                <p>{data.length}</p>
              </div>
              <div className='min-w-[12rem] px-3 py-5 my-5 mr-10 border shadow-md rounded-xl bg-slate-50'>
                <h3 className='text-xl font-semibold'>Total Pro users</h3>
                <p>{data.filter((item) => item.premium_user).length}</p>
              </div>
              <div className='min-w-[12rem] px-3 py-5 my-5 mr-10 border shadow-md rounded-xl bg-slate-50'>
                <h3 className='text-xl font-semibold'>Total Enterprise users</h3>
                <p>{data.filter((item) => item.isenterprise).length}</p>
              </div>
              <div className='min-w-[12rem] px-3 py-5 my-5 mr-10 border shadow-md rounded-xl bg-slate-50'>
                <h3 className='text-xl font-semibold'>Users joined today</h3>
                <p>
                  {data.filter((item) => {
                    const today = new Date();
                    const itemDate = new Date(item.audit.created);
                    return itemDate.getFullYear() === today.getFullYear() &&
                      itemDate.getMonth() === today.getMonth() &&
                      itemDate.getDate() === today.getDate();
                  }).length}
                </p>
              </div>
            </div>
      
            <div className='flex justify-end mx-auto space-x-5 w-[90%] mb-5'>
              <div className='flex'>
                <p className='text-xl'>Pro Users : </p>
                <Switch
                  checked={proEnabled}
                  onChange={setProEnabled}
                  className={`${proEnabled ? 'bg-teal-900' : 'bg-teal-700'} ml-3 relative inline-flex h-[28px] w-[54px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
                >
                  <span
                    aria-hidden="true"
                    className={`${proEnabled ? 'translate-x-6' : 'translate-x-0'} pointer-events-none inline-block h-[24px] w-[24px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                  />
                </Switch>
              </div>
              <div className='flex'>
                <p className='text-xl'>Enterprise Users : </p>
                <Switch
                  checked={businessUserEnabled}
                  onChange={setBusinessUserEnabled}
                  className={`${businessUserEnabled ? 'bg-teal-900' : 'bg-teal-700'} ml-3 relative inline-flex h-[28px] w-[54px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white/75`}
                >
                  <span
                    aria-hidden="true"
                    className={`${businessUserEnabled ? 'translate-x-6' : 'translate-x-0'} pointer-events-none inline-block h-[24px] w-[24px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                  />
                </Switch>
              </div>
            </div>
      
            <table className="table-auto border-collapse mx-auto w-[90%]">
              <thead className="sticky -top-1 bg-white">
                <tr>
                  <th className="py-2 px-4 border">User Id</th>
                  <th className="py-2 px-4 border">Email</th>
                  <th className="py-2 px-4 border">Mobile</th>
                  <th className="py-2 px-4 border">Joined On</th>
                  <th className="py-2 px-4 border">Pro user</th>
                  <th className="py-2 px-4 border">Credits</th>
                  <th className="py-2 px-4 border">Latest SOP</th>
                  <th className="py-2 px-4 border">SOP Count</th>
                  <th className="py-2 px-4 border">Enterprise User</th>
                </tr>
              </thead>
              <tbody className="max-h-[400px] overflow-y-auto">
                {sortedTableData.map((item: any) => (
                  <tr key={item.id}>
                    <td className="py-2 px-4 border text-center">{item.id}</td>
                    <td className="py-2 px-4 border max-w-[10rem] overflow-hidden cursor-pointer whitespace-nowrap overflow-ellipsis">
                      <span title={item.uname}>{item.uname}</span>
                    </td>
                    <td className="py-2 px-4 border max-w-[10rem] overflow-hidden whitespace-nowrap overflow-ellipsis">
                      <span title={item.phonenumber}>{item.phonenumber}</span>
                    </td>
                    <td className="py-2 px-4 border text-center">
                      {new Date(item.audit.created).toLocaleDateString('en-IN', { month: 'short', day: '2-digit', year: 'numeric' })}
                    </td>
                    <td className="py-2 px-4 border text-center">
                      <img src={item.premium_user ? checkCircle : crossCircle} alt='status' className='h-7 w-7 mx-auto' />
                    </td>
                    <td className="py-2 px-4 border text-center">{item.credits}</td>
                    <td className="py-2 px-4 border text-center">{item.latestSop}</td>
                    <td className="py-2 px-4 border text-center">{item.NoOfSopGenerated}</td>
                    <td className="py-2 px-4 border text-center">
                      <img src={item.isenterprise ? checkCircle : crossCircle} alt='status' className='h-7 w-7 mx-auto' />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        );
      }
    }
    return <>{renderContent()}</>;
}

export const PaymentsData = () => {
  const [tableData, setTableData] = useState<any[]>();
  const [tableLoading, setTableLoading] = useState<boolean>();
  const [error, setError] = useState<boolean>();

  useEffect(() => {
    getPaymentsData();
  }, []);

  const getPaymentsData = async () => {
    setTableLoading(true);
    try {
      let res = await create({
        baseURL: cambriURL,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).get(`/api/v1/admin/get_all_payment_details`);
      if (res?.status === 200) {
        setTableData(res.data as any);
        setTableLoading(false);
      } else {
        setTableLoading(false);
        setError(true);
      }
    } catch (err) {
      setTableLoading(false);
      setError(true);
      console.error(err);
    }
  };

  const renderStatus = (data : any) => {
    if (data.status === "success") {
      return (<p className="text-green-500">{data.status}</p>);
    } else if (data.status === "pending") {
      return (<p className="text-orange-500">{data.status}</p>);
    } else {
      return (<p className="text-red-600">{data.status}</p>);
    }
  }

  const renderPayment = (data: any) => {
    if (data.more?.payment_log) {
      return data.more.payment_log.map((log: any) => (
        <div key={log.timestamp}>
          <p className="font-semibold">
            Status: <span className="font-normal">{log.status}</span>
          </p>
          <p className="font-semibold">
            LogTime:{" "}
            <span className="font-normal">
              {String(
                new Date(log.timestamp).toLocaleString("en-IN", {
                  month: "short",
                  day: "2-digit",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                })
              )}
            </span>
          </p>
        </div>
      ));
    } else {
      return "N/A";
    }
  };

  const renderData = () => {
    if (tableLoading) {
      return (
        <div className="w-[10%] mx-auto my-36">
          <Loader />
        </div>
      );
    } else if (error) {
      return <p className="mx-auto">Something went wrong</p>;
    } else {
      return (
        <div>
          <table className="table-auto border-collapse mx-auto w-[90%]">
            <thead className="sticky -top-1 bg-white">
              <tr>
                <th className="py-2 px-4 border">Order Id</th>
                <th className="py-2 px-4 border">Amount</th>
                <th className="py-2 px-4 border">Time</th>
                <th className="py-2 px-4 border">Status</th>
                <th className="py-2 px-4 border">User Id</th>
                <th className="py-2 px-4 border">Plan Id</th>
                <th className="py-2 px-4 border">Payment Log</th>
              </tr>
            </thead>
            <tbody className="max-h-[400px] overflow-y-auto">
              {tableData?.map((item: any) => (
                  <tr key={item.order_id}>
                    <td className="py-2 px-4 border text-center">
                      {item.order_id}
                    </td>
                    <td className="py-2 px-4 border text-center">
                      &#8377;{item.payment_amount}
                    </td>
                    <td className="py-2 px-4 border text-center">
                      {String(
                        new Date(item.Timestamp).toLocaleString("en-IN", {
                          month: "short",
                          day: "2-digit",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          second: "2-digit",
                        })
                      )}
                    </td>
                    <td className="py-2 px-4 border text-center">
                      {renderStatus(item)}
                    </td>
                    <td className="py-2 px-4 border text-center">
                      {item.user_id}
                    </td>
                    <td className="py-2 px-4 border text-center">
                      {item.planid}
                    </td>
                    <td className="py-2 px-4 border">
                      {renderPayment(item)}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      );
    }
  };

  return <>{renderData()}</>;
}