import { TestResultsApi } from "../api/test-results-api";
import { makePersistable } from "mobx-persist-store";
import { makeObservable, observable, action } from "mobx";
import { BaseTestResultsStore } from "./baseClass";

export class TestResultsStore extends BaseTestResultsStore {
  graphData = {
    dates: Array<string>,
    scores: Array<number>,
  };
  individualTestScore: any = {};
  getQuestionAnalysisPartOne: any = {};
  getQuestionAnalysisPartTwo: any = {};
  getQuestionAnalysisPartThree: any = {};
  isFreeTestLoading: boolean = false;
  isGrapghDataLoading: boolean = false;

  constructor(private testResultsApi: TestResultsApi) {
    super("speakingResults");
    makeObservable(this, {
        graphData:observable,
        individualTestScore:observable,
        getQuestionAnalysisPartOne:observable,
        getQuestionAnalysisPartTwo:observable,
        getQuestionAnalysisPartThree:observable,
        isFreeTestLoading:observable,
        isGrapghDataLoading:observable,
        testResultsUpdate:action,
        getFreeTestData:action,
        setTestCompleted:action,
        freeTestCompletedClicked:action,
        getGraphData:action,
        getTestScore:action,
        getQuestionAnalysis:action,
        isFreeTestCompleted:action
    });
    makePersistable(this, {
      name: "TestResultsStore",
      properties: [
        "isFreeTestCompletedClicked",
        "lastPageNumber",
        "individualTestScore",
        "getQuestionAnalysisPartOne",
        "getQuestionAnalysisPartTwo",
        "getQuestionAnalysisPartThree",
      ],
      storage: window.localStorage,
    });
  }

  async testResultsUpdate(querryParam: string, page: number) {
    try {
      this.isLoading = true;
      const response = await this.testResultsApi.testResultsUpdate(
        querryParam,
        page
      );
      if (response.status === 200) {
        this.isLoading = false;
        this.testResults = response.data.data;
        if (response.data.data.length === 0) {
          this.isTestsFound = true;
        } else {
          this.isTestsFound = false;
        }
        return response;
      }
    } catch (e) {
      this.isLoading = false;
      console.log(e);
    }
  }

  async getFreeTestData() {
    try {
      this.isFreeTestLoading = true;
      const response = await this.testResultsApi.fetchFreeTestData();
      this.freeTestData = response.data;
      if (response.ok) {
        this.isFreeTestLoading = false;
        return response;
      }
    } catch (e) {
      this.isFreeTestLoading = false;
      console.log(e);
    }
  }

  async setTestCompleted(tId: any, tType: any) {
    this.completedTestId = tId;
    this.completedTestType = tType;
  }

  async freeTestCompletedClicked(bool: boolean) {
    this.isFreeTestCompletedClicked = bool;
  }

  async getGraphData() {
    return await this.testResultsApi.getAverageScoreData();
  }

  // async getAverageTestScore() {
  //   try {
  //     this.isGrapghDataLoading = true;
  //     const res = await this.testResultsApi.getAverageScoreData();
  //     if (res.status === 200) {
  //       this.averageScore = res.data.avg_score ? res.data.avg_score : 0;
  //       const transformedData = res.data.data.reduce(
  //         (result: any, item: any) => {
  //           result.scores.push(item.score);
  //           const dateObject = new Date(item.date);
  //           result.dates.push(formatDateToCustomFormat(dateObject));
  //           return result;
  //         },
  //         { scores: [] as string[], dates: [] as number[] }
  //       );
  //       this.graphData = transformedData;
  //       this.isGrapghDataLoading = false;
  //       this.hasUserTakenAnyTest = res.data.data.length > 0;
  //       return res.data.avg_score;
  //     }
  //   } catch (e) {
  //     this.isGrapghDataLoading = false;
  //     console.log(e, "ERROR");
  //   }
  // }

  async getTestScore(tstId: any) {
    this.isResultsLoading = true;
    try {
      const res = await this.testResultsApi.getTestScoreApi(tstId);
      if (res.status === 200) {
        this.isResultsLoading = false;
        this.individualTestScore = res.data;
      }
    } catch (e) {
      console.log(e);
    }
  }
  async getQuestionAnalysis(tstId: any, typeOfTest: any) {
    if (typeOfTest === "full") {
      try {
        const res = await this.testResultsApi.getQuestionAnalysisApi(
          tstId,
          "part1"
        );
        if (res.status === 200) {
          this.getQuestionAnalysisPartOne = res.data;
        }
      } catch (e) {
        console.log(e, "questionanalysispart1");
      }
      try {
        const res = await this.testResultsApi.getQuestionAnalysisApi(
          tstId,
          "part2"
        );
        if (res.status === 200) {
          this.getQuestionAnalysisPartTwo = res.data;
        }
      } catch (e) {
        console.log(e, "questionanalysispart2");
      }
      try {
        const res = await this.testResultsApi.getQuestionAnalysisApi(
          tstId,
          "part3"
        );
        if (res.status === 200) {
          this.getQuestionAnalysisPartThree = res.data;
        }
      } catch (e) {
        console.log(e, "questionanalysispart3");
      }
    } else {
      try {
        const res = await this.testResultsApi.getQuestionAnalysisApi(
          tstId,
          typeOfTest
        );
        if (res.status === 200) {
          if (typeOfTest === "part1") {
            this.getQuestionAnalysisPartOne = res.data;
          } else if (typeOfTest === "part2") {
            this.getQuestionAnalysisPartTwo = res.data;
          } else {
            this.getQuestionAnalysisPartThree = res.data;
          }
        }
      } catch (e) {
        console.log(e, "questionanalysispart");
      }
    }
  }

  async isFreeTestCompleted(test_id: any) {
    this.isLoading = true;
    try {
      const res = await this.testResultsApi.isFreeTestCompletedSpeaking(
        test_id
      );
      if (res.status === 200) {
        console.log(res.data, "VINOD RES");
        return res.data.is_completed;
      }
      this.isLoading = false;
    } catch (e) {
      console.log(e, "idFreeTest Completed");
      this.isLoading = false;
    }
  }
}
