import React from 'react'
import { useStore } from '../../../Hooks/use-store'
import ListeningPart from './ReusableListeningPart';
import { observer } from 'mobx-react-lite';

const ListeningPart1 = observer(({ nextStep } : any) => {

  const { startTestStoreListening } = useStore();
  const { part1Questions } = startTestStoreListening;
  const part1StateData = {
    singleAnswer : startTestStoreListening.part1SingleAnswer,
    setSingleAnswer : startTestStoreListening.setPart1SingleAnswer,
    multipleAnswer : startTestStoreListening.part1MultipleAnswer,
    setMultipleAnswer : startTestStoreListening.setPart1MultipleAnswer,
    taskIdentifyingItemAnswers : startTestStoreListening.part1TaskIdentifyingItemAnswers,
    setTaskIdentifyingItemAnswers : startTestStoreListening.setPart1TaskIdentifyingItemAnswers,
    noteCompletionAnswer : startTestStoreListening.part1NoteCompletionAnswer,
    setNoteCompletionAnswer : startTestStoreListening.setPart1NoteCompletionAnswer,
    planDiagramAnswer : startTestStoreListening.part1PlanDiagramAnswer,
    setPlanDiagramAnswer : startTestStoreListening.setPart1PlanDiagramAnswer,
    shortAnswer : startTestStoreListening.part1ShortAnswer,
    setShortAnswer : startTestStoreListening.setPart1ShortAnswer,
    senetenceComplete : startTestStoreListening.part1SenetenceComplete,
    setSenetenceComplete : startTestStoreListening.setPart1SenetenceComplete,
    summaryCompletionOneWordAnswers : startTestStoreListening.part1SummaryCompletionOneWordAnswers,
    setSummaryCompletionOneWordAnswers : startTestStoreListening.setPart1SummaryCompletionOneWordAnswers,
    dragAndDropAnswers : startTestStoreListening.part1DragAndDropAnswers,
    setDragAndDropAnswers : startTestStoreListening.setPart1DragAndDropAnswers,
    draggedCurrentSentence : startTestStoreListening.part1DraggedCurrentSentence,
    setDraggedCurrentSentence : startTestStoreListening.setPart1DraggedCurrentSentence,
    tableCompletionAnswer : startTestStoreListening.part1TableCompletionAnswer,
    setTableCompletionAnswer : startTestStoreListening.setPart1TableCompletionAnswer
  }

  return (
      <ListeningPart store={startTestStoreListening} partType={'part1'} stateData={part1StateData} paraQuestions={part1Questions} nextStep={nextStep}/>
  )
})

export default ListeningPart1