import { makePersistable } from "mobx-persist-store";
import { makeObservable, observable, action } from "mobx";

export class BaseTestResultsStore {
  testResults: any[] = [];
  freeTestData: any[] = [];
  averageScore: number = 0;
  isTestsFound: boolean = false;
  individualTestScorePart1: any = {};
  individualTestScorePart2: any = {};
  showResults: boolean = false;
  storeName: string;
  currentPageNumber: number = 1;
  lastPageNumber: number | null = null;
  isFreeTestCompletedClicked: boolean = false;
  completedTestId: any = null;
  completedTestType: any = null;
  hasUserTakenAnyTest: boolean = false;
  stepManagement: any = {
    currentStepName: "confirm_your_details",
    currentPartName: "",
    nextPartName: "",
  };
  isLoading: boolean = false;
  activeTab: number = 0;
  isResultsLoading: boolean = false;

  constructor(storeName: string) {
    this.storeName = storeName;
    makeObservable(this, {
      testResults: observable,
      freeTestData: observable,
      averageScore: observable,
      isTestsFound: observable,
      individualTestScorePart1: observable,
      individualTestScorePart2: observable,
      showResults: observable,
      currentPageNumber: observable,
      lastPageNumber: observable,
      isFreeTestCompletedClicked: observable,
      completedTestId: observable,
      completedTestType: observable,
      hasUserTakenAnyTest: observable,
      stepManagement: observable,
      isLoading: observable,
      activeTab: observable,
      isResultsLoading: observable,
      setCurrentPageNumber: action,
      setLastPageNumber: action,
      setActiveTab: action,
      setStepManagement: action,
      viewResultsPage: action,
    });
    makePersistable(this, {
      name: `${storeName}ResultsStore`,
      properties: [
        "showResults",
        "individualTestScorePart1",
        "individualTestScorePart2",
        "completedTestType",
        "completedTestId",
        "averageScore",
      ],
      storage: window.localStorage,
    });
    this.setCurrentPageNumber = this.setCurrentPageNumber.bind(this);
  }

  setCurrentPageNumber(page: number) {
    console.log(page,"PAGE in BASE CLASS")
   this.currentPageNumber = page;
   
  }

  setLastPageNumber(page: number) {
    this.lastPageNumber = page;
  }

  setActiveTab(index: number) {
    this.activeTab = index;
  }

  setStepManagement(
    stepName: string,
    currentPartName: string,
    nextPartName: string
  ) {
    this.stepManagement.currentStepName = stepName;
    this.stepManagement.currentPartName = currentPartName;
    this.stepManagement.nextPartName = nextPartName;
  }

  async viewResultsPage(bool: boolean) {
    this.showResults = bool;
  }
}
