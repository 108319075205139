import { observer } from "mobx-react-lite";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import useGetFraction from "./useGetFraction";
import { ILinearProgressBar } from "./detailedResults";

const LinerProgressBar = (props: ILinearProgressBar) => {
  let average;
  let percentage: any;
  let name;
  let outerbgcolor;
  let innerbgcolor;

  if (props.item) {
    average = props.item.score;
    percentage = ((average / 9) * 100).toFixed(2);
    name = props.item.parameter;
  }

  if (percentage >= 0) {
    if (percentage >= 0 && percentage < 50) {
      outerbgcolor = "#FFE4DA";
      innerbgcolor = "#FF495C";
    }
    if (percentage >= 50 && percentage < 77) {
      outerbgcolor = "#FFF6D3";
      innerbgcolor = "#FFBB28";
    }
    if (percentage >= 77 && percentage <= 100) {
      outerbgcolor = " #DCF8A7";
      innerbgcolor = "#59A51A";
    }
  }
  return (
    <>
      <div className="flex flex-row justify-between text-[#4E5664] text-lg font-semibold mb-2">
        <p>{name}</p>
        <p>{average}/9</p>
      </div>
      <div
        className="w-full h-[8px]  rounded-2xl"
        style={{
          backgroundColor: outerbgcolor,
        }}
      >
        <div
          className=" h-full rounded-2xl"
          style={{
            backgroundColor: innerbgcolor,
            width: `${percentage}%`,
          }}
        ></div>
      </div>
    </>
  );
};

const ReadingReport = ({ partDetails }: any) => {
  const { fraction, percentage, pathColor, trailColor, textColor } =
    useGetFraction(partDetails);

  return partDetails ? (
    <div>
      <div className="flex flex-row flex-wrap justify-between">
        <div className=" w-[44.65rem] mb-auto lg:ml-[24px] md:ml-[24px] flex flex-col">
          <div className="pl-2 pt-[12px] text-xl font-semibold font-Avenir text-[#4E5664]">
            Band Description
          </div>
          <div className="ml-2 my-5">
            <LinerProgressBar
              item={{
                parameter: partDetails.skill_level,
                score: partDetails.overall_score,
              }}
            />
          </div>
          <div className="ml-2 mb-3 text-justify text-[#4E5664] font-Avenir">
            <p>{partDetails.description}</p>
          </div>
        </div>
        <div className="w-[22.88rem] h-[18.75rem] flex justify-center items-center mb-2">
          <div className="w-[180px] h-[180px] ">
            <CircularProgressbar
              value={percentage}
              text={`${fraction}/9`}
              styles={buildStyles({
                rotation: 1,
                strokeLinecap: "round",
                textSize: "20px",
                pathTransitionDuration: 0.5,
                pathColor: pathColor,
                textColor: textColor,
                trailColor: trailColor,
              })}
            />
            <div className="text-[20px] font-Avenir font-medium text-[#3C424C] flex justify-center pt-[20px]">
              Avg Band Score
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div>
      <p className="text-center my-20">No data</p>
    </div>
  );
};

export default observer(ReadingReport);
