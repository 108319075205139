import { useEffect, useState } from "react";
import "./progress.css";
import { useStore } from "../../../Hooks/use-store";

const Progress = ({maxTime}:any) => {
  const [widthPercentage, setWidthPercentage] = useState(100);
  const {startTestStore} = useStore()
  

  useEffect(() => {
    const interval = setInterval(() => {
      setWidthPercentage((prevProgress: any) => {
        if (prevProgress === 0) {
       startTestStore.setProgressBar(100);
          return 100;
        } else {
          startTestStore.setProgressBar(prevProgress - 1);
          return prevProgress - 1;
        }
      });
    }, maxTime / 100); // 60000 milliseconds (1 minute) divided by 100 to decrease 1% every 1 minute

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="progressbar">
      <div
        className="progressbarbg"
        style={{ width: `${widthPercentage}%` }}
      ></div>
    </div>
  );
};

export default Progress;
