import { observer } from "mobx-react-lite";
import { useStore } from "../../Hooks/use-store";
import WritingInstructionsCard from "./WritingInstructionsCard";

const ConfirmYourDetails = observer(({ nextStep }: any) => {
  const { userStore } = useStore();
  let fullTestInstructions = [
    `1.Name : ${userStore.studentDetails.studentName}`,
    // "2.Date of birth :",
    `2.Candidate number : ${userStore.studentDetails.mobileNumber}`,
  ];

  return (
    <div className="flex justify-center items-center flex-col w-full">
      <h4 className="w-[80%] md:w-[25%] text-base md:text-2xl font-semibold md:font-normal mb-1">
        Confirm your details
      </h4>
      <WritingInstructionsCard
        width="w-1/3"
        border={"#E3E5E9"}
        bg={"var(--neutral-white, #FFF)"}
      >
        <>
          {fullTestInstructions.map((item: any) => (
            <div className="text-[#292D35] mb-2 text-24" key={item}>
              {item}
            </div>
          ))}
          <h4 className="mt-4">
            If your details are not correct, please inform your invigilator.
          </h4>
          <button
            onClick={nextStep}
            className="bg-[#E31837] text-white py-2 mt-6 md:w-1/2 w-[80%] mx-auto rounded-full"
          >
            My details are correct
          </button>
        </>
      </WritingInstructionsCard>
    </div>
  );
});

export default ConfirmYourDetails;
