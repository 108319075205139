import { observer } from "mobx-react-lite";
import PartTestsLayout from "../PartTestsLayout";
import { useStore } from "../../../Hooks/use-store";
import { useEffect, useState } from "react";

const WritingPart2 = observer(({ nextStep }: any) => {
  const { resultsStoreWriting, startTestStoreWriting } = useStore();
  const { writingPart2Answer } = resultsStoreWriting;
  const [wordCount, setWordCount] = useState(0);
  let time = startTestStoreWriting.timer;
  const handleChange = (e: any) => {
    // Get the current value from the textarea
    const currentValue = e.target.value;

    // Calculate the word count based on the current value
    const words = currentValue.split(/\s+/).filter(Boolean);
    // Check if the word count exceeds 1000
    if (words.length > 1000) {
      // Slice the value to fit within the 1000-word limit
      const trimmedValue = currentValue.split(/\s+/).slice(0, 1000).join(" ");
      resultsStoreWriting.setWritingPart2Answers(trimmedValue, "");
      alert(
        "Attention: Your content surpasses the 1000-word limit. Kindly reduce the word count."
      );
      return; // Prevent further execution
    } else {
      // Update the state with the new value
      resultsStoreWriting.setWritingPart2Answers(currentValue, "");
    }

    // Update the word count display
    setWordCount(words.length);
  };

  useEffect(() => {
    startTestStoreWriting.setPartType("part2");
  }, []);

  useEffect(() => {
    if (time <= 0) {
      resultsStoreWriting.setStepManagement(
        "part_test_completed",
        "Part 2",
        ""
      );
    }
  }, [time]);

  useEffect(() => {
    const words = writingPart2Answer.text
      ? writingPart2Answer.text.trim().split(/\s+/)
      : [];
    if (words.length > 0) {
      setWordCount(words.length);
    } else {
      setWordCount(0);
    }
  }, [writingPart2Answer.text]);

  return (
    <div className="h-screen">
      <PartTestsLayout
        buttonTitle={"Submit"}
        nextStep={nextStep}
        partType={"Part2"}
        title={"Academic Writing Part 2"}
        desc={
          "1. You should spend 40 minutes on this task. Write at least 250 words."
        }
        disabled={wordCount < 250}
      >
        <div className="flex flex-col md:flex-row p-6  h-full md:h-[500px] justify-between mx-6 bg-[#F8F9FA] border border-[#E3E5E9] rounded-lg">
          <div className="w-full md:w-4/5">
            <p className="text-base md:text-2xl font-extrabold mb-4">
              {startTestStoreWriting?.part2Questions?.title}
            </p>
            <p className="mb-8 md:w-11/12 w-full">
              {startTestStoreWriting?.part2Questions?.question}
            </p>
          </div>
          <div className="w-full">
            <div className="h-[95%]">
              <textarea
                onChange={handleChange}
                placeholder="Write your answer..."
                value={writingPart2Answer.text}
                className="w-full bg-[#FFF] p-4 border border-[E3E5E9] rounded-lg h-[90%]"
              ></textarea>
              <span className="text-red-600 text-sm">
                *The maximum word count for this test is 1000 words.Please
                ensure your content adheres to this limit
              </span>
            </div>
            <div className="flex justify-between pb-4">
              <p className="text-[#4E5664] text-xs md:text-lg">
                Word Count: {wordCount}
              </p>
              <p className="text-[#292D3566] text-xs md:text-lg cursor-pointer">
                UPLOAD IMAGE
              </p>
            </div>
          </div>
        </div>
      </PartTestsLayout>
    </div>
  );
});

export default WritingPart2;
