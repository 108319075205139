import {ApisauceInstance, create} from "apisauce";
import {ApiConfig, DEFAULT_API_CONFIG} from './api-config';


export class Api {
    apisauce?: ApisauceInstance;
 
  config: ApiConfig
  userToken: string = ""

  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config
  }

  async setUp() {
    this.apisauce = create({
      baseURL: this.config.url,
      headers: {
        Accept: "application/json",
        'Content-Type': 'application/json',
         'Authorization': 'bearer ' + this.userToken,
      },
    })
  }
}
