import React from 'react'
import { useStore } from '../../../Hooks/use-store'
import ListeningPart from './ReusableListeningPart';
import { observer } from 'mobx-react-lite';

const ListeningPart3 = observer(({ nextStep } : any) => {
  const { startTestStoreListening } = useStore();
  const { part3Questions } = startTestStoreListening;
  const part3StateData = {
    singleAnswer : startTestStoreListening.part3SingleAnswer,
    setSingleAnswer : startTestStoreListening.setPart3SingleAnswer,
    multipleAnswer : startTestStoreListening.part3MultipleAnswer,
    setMultipleAnswer : startTestStoreListening.setPart3MultipleAnswer,
    taskIdentifyingItemAnswers : startTestStoreListening.part3TaskIdentifyingItemAnswers,
    setTaskIdentifyingItemAnswers : startTestStoreListening.setPart3TaskIdentifyingItemAnswers,
    noteCompletionAnswer : startTestStoreListening.part3NoteCompletionAnswer,
    setNoteCompletionAnswer : startTestStoreListening.setPart3NoteCompletionAnswer,
    planDiagramAnswer : startTestStoreListening.part3PlanDiagramAnswer,
    setPlanDiagramAnswer : startTestStoreListening.setPart3PlanDiagramAnswer,
    shortAnswer : startTestStoreListening.part3ShortAnswer,
    setShortAnswer : startTestStoreListening.setPart3ShortAnswer,
    senetenceComplete : startTestStoreListening.part3SenetenceComplete,
    setSenetenceComplete : startTestStoreListening.setPart3SenetenceComplete,
    summaryCompletionOneWordAnswers : startTestStoreListening.part3SummaryCompletionOneWordAnswers,
    setSummaryCompletionOneWordAnswers : startTestStoreListening.setPart3SummaryCompletionOneWordAnswers,
    dragAndDropAnswers : startTestStoreListening.part3DragAndDropAnswers,
    setDragAndDropAnswers : startTestStoreListening.setPart3DragAndDropAnswers,
    draggedCurrentSentence : startTestStoreListening.part3DraggedCurrentSentence,
    setDraggedCurrentSentence : startTestStoreListening.setPart3DraggedCurrentSentence,
    tableCompletionAnswer : startTestStoreListening.part3TableCompletionAnswer,
    setTableCompletionAnswer : startTestStoreListening.setPart3TableCompletionAnswer
  }

  

  return (
      <ListeningPart store={startTestStoreListening} partType={'part3'} stateData={part3StateData} paraQuestions={part3Questions} nextStep={nextStep}/>
  )
})

export default ListeningPart3