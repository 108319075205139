import React from "react";
import { observer } from "mobx-react-lite";

const MatchingFeatures = ({ 
    item,
    handleMatchingFeature,
    MatchingFeatureAnswers
     }: any) => {

        console.log(MatchingFeatureAnswers,"match")

  let obj = item["mathcingFeaturequestionTable"]["opitions"];
  let tbody = item["mathcingFeaturequestionTable"]["list-of-questions"];
  let headerRange = Object.keys(obj).map((item) => item);
 
  return (
    <>
      <div className="text-[#3C424C] text-sm">
        Choose the correct group, <span className="font-semibold">{headerRange[0]}-{headerRange[(headerRange.length - 1)]}</span>,
        for each item. You may choose any group more than once
      </div>
      <table className="my-4 ">
        <tr className=" bg-[#FFEAEA] border rounded-t-md border-[#E3E5E9] ">
          <th className="w-1/2 py-2"></th>
          {Object.keys(obj).map((item) => {
            return <th className="py-2 border border-[#E3E5E9]" key={item}>{item}</th>;
          })}
        </tr>

        {tbody.map((item: any, index: any) => {
          return (
            <tr className="border border-[#E3E5E9]" key={item.qId}>
              <td className="pl-4 py-2">
                {item.qId}.{item.qName}
              </td>
              {Object.keys(obj).map((itmm, ind) => {
                let isChcked = MatchingFeatureAnswers.length > 0 && MatchingFeatureAnswers.some(
                  (question: any) =>
                    question.qId == item.qId && question.answer == obj[itmm]
                );
                const radioId = `${item.qId}-${itmm}`; // Generate a unique ID for each radio button
                return (
                  <td className="pl-10 border border-[#E3E5E9]" key={itmm}>
                    <input
                      type="radio"
                      id={radioId}
                      name={`options-${item.qId}`} // Use a unique name for each row
                      value={obj[itmm]}
                      checked={isChcked}
                      onChange={(e) => handleMatchingFeature(item.qId, obj[itmm])}
                    />
                    {/* <label htmlFor={radioId}>{obj[itmm]}</label>{" "} */}
                    {/* Add labels for better usability */}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </table>
      <table className="my-4 w-3/4">
        {Object.entries(obj).map((item, index) => {
          let optionValue: string | number;

          if (typeof item[1] === "string" || typeof item[1] === "number") {
            optionValue = item[1];
          } else {
            // Handle the case where the value is of an unexpected type
            optionValue = ""; // or any other default value
          }
          
          return (
            <tr key={optionValue}>
              <td className="border py-2 pl-4 border-[#E3E5E9] ">{item[0]}</td>
              <td className="border py-2 pl-4 border-[#E3E5E9] w-3/4">
                {optionValue}
              </td>
            </tr>
          );
        })}
      </table>
    </>
  );
};

export default observer(MatchingFeatures);
