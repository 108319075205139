
import { observer } from "mobx-react-lite";
import React from "react";

const MultiAnswerQuestions = ({
  item,
  handleCheckBoxChange,
  multipleAnswers,
}: any) => {
  return (
    <>
      <div className="text-[#3C424C] text-sm">
        {" "}
        Choose the multiple correct answers
      </div>
      <div className="flex flex-wrap w-full justify-between">
        {item["listOfQuestions"] &&
          item["listOfQuestions"].length > 0 &&
          item["listOfQuestions"].map((question: any) => {
            return (
              <div  key={question.questionId} className="w-[45%] p-4 border border-[#E3E5E9] rounded-xl m-2 flex flex-col">
                <div className="text-[#292D35] text-base">{`${question.questionId}. ${question.questionName}`}</div>
                <div>
                  {question.options.map((option: any) => {
                    const filteredItem = multipleAnswers?.find((item: any) => item.qId == question.questionId);
                    const isDisabled = filteredItem&&!filteredItem.answer?.includes(option) &&filteredItem.answer.length >= 2;
                    const isChecked = multipleAnswers?.some((item: any) => item.qId == question.questionId && item.answer.includes(option)) ?? false;
                    return (
                      <div  key={question.questionId } className="text-[#737E93]">
                        <input
                          type="checkbox"
                          className="w-3 h-3 mr-1 "
                          name={question.questionId}
                          value={option}
                          checked={isChecked}
                          disabled={isDisabled}
                          onChange={(e) =>
                            handleCheckBoxChange(
                              e.target.name,
                              e.target.value,
                              e.target.checked
                            )
                          }
                        />{" "}
                        {option}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default observer(MultiAnswerQuestions);
