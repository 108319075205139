import { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../Hooks/use-store";
import { setLinearProgressColors } from "../../../utils";

export type ILinearProgressBar = {
  item: any;
};

const LinerProgressBar = (props: ILinearProgressBar) => {
  const { item } = props;
  const { average, percentage, name, outerbgcolor, innerbgcolor } =
    setLinearProgressColors(item);

  return (
    <>
      <div className="flex flex-row justify-between text-[#4E5664] font-semibold">
        <p>{name}</p>
        <p>{average}/9</p>
      </div>
      <div
        className="w-full h-[8px]  rounded-2xl"
        style={{
          backgroundColor: outerbgcolor,
        }}
      >
        <div
          className=" h-full rounded-2xl"
          style={{
            backgroundColor: innerbgcolor,
            width: `${percentage}%`,
          }}
        ></div>
      </div>
    </>
  );
};

const ReportCardDetails = (props: any) => {
  const { dashboardStore } = useStore();
  return (
    <div
      className={`flex flex-col ${
        dashboardStore.showDashboardSideBar
          ? "xl:w-[14rem] md:w-[10rem]"
          : "xl:w-[16rem] md:w-[12rem]"
      } h-1/4 border border-[#E3E5E9] rounded-[8px] mt-[5px] mx-2`}
    >
      <div className="px-[16px] pt-[16px]">
        <LinerProgressBar item={props.reportData} />
      </div>
      <ul
        className="pt-[22px] px-[16px] ml-4 "
        style={{ listStyleType: "disc" }}
      >
        {props.reportData.description.map((item: any) => (
          <li key={item} className="my-2 text-[#737E93]">
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

const DetailedResults = ({ store }: any) => {
  const [fraction, setFraction] = useState<string>("0");
  let { individualTestScore } = store;
  const { dashboardStore } = useStore();

  let percentage = (individualTestScore?.overall_score / 9) * 100; // Calculate the percentage (5/9 * 100)

  let pathColor = "#3FC7FA"; // Default path color
  let trailColor = "#d6d6d6";
  let textColor = "black";
  if (percentage >= 0 && percentage <= 50) {
    pathColor = " #FF495C";
    trailColor = "#FFC2B6";
    textColor = "#FF495C";
  }
  if (percentage > 50 && percentage <= 77) {
    pathColor = "#FFBB28";
    trailColor = "#FFECA9";
    textColor = "#FFBB28";
  }
  if (percentage > 77 && percentage <= 100) {
    pathColor = "#59A51A";
    trailColor = "#DCF8A7";
    textColor = "#59A51A";
  }
  useEffect(() => {
    if (individualTestScore) {
      let decimalPart = Number(
        (
          individualTestScore?.overall_score -
          Math.floor(individualTestScore?.overall_score)
        ).toFixed(2)
      );
      let value;
      if (decimalPart > 0 && decimalPart <= 0.24) {
        value = Math.floor(individualTestScore?.overall_score);
      } else if (decimalPart >= 0.25 && decimalPart <= 0.49) {
        value = Math.floor(individualTestScore?.overall_score) + 0.5;
      } else if (decimalPart > 0.5 && decimalPart <= 0.74) {
        value = Math.floor(individualTestScore?.overall_score) + 0.5;
      } else if (decimalPart >= 0.75 && decimalPart <= 0.99) {
        value = Math.ceil(individualTestScore?.overall_score);
      } else {
        value = individualTestScore?.overall_score;
      }
      typeof value === "number" && setFraction(value.toString());
    } else {
      setFraction("0");
    }
  }, [individualTestScore]);

  return (
    <div>
      <div className="flex flex-row flex-wrap sm:flex-nowrap">
        <div className="w-[22.88rem] h-[18.75rem] border border-[#E3E5E9] rounded-[8px] flex justify-center items-center mb-2">
          <div className="w-[180px] h-[180px] ">
            <CircularProgressbar
              value={percentage}
              text={`${fraction || "0"}/9`}
              styles={buildStyles({
                rotation: 1,
                strokeLinecap: "round",
                textSize: "20px",
                pathTransitionDuration: 0.5,
                pathColor: pathColor,
                textColor: textColor,
                trailColor: trailColor,
              })}
            />
            <div className="text-[20px] font-Avenir font-medium text-[#3C424C] flex justify-center pt-[20px]">
              Avg Band Score
            </div>
          </div>
        </div>
        <div
          className={`border border-[#E3E5E9] rounded-[8px] ${
            dashboardStore.showDashboardSideBar
              ? "xl:w-[44.625rem] md:w-[rem]"
              : "xl:w-[51.5rem]  md:w-[30rem]"
          } h-[18.75rem] xl:ml-[24px] md:ml-[24px] flex flex-col`}
        >
          <div className="pl-[31px] pt-[12px] border-b-[1px] border-[#E3E5E9]">
            Score Summary
          </div>
          <div className="flex flex-col mx-[32px] my-[16px] justify-between h-full">
            {individualTestScore.detailed_report &&
              individualTestScore.detailed_report.length > 0 &&
              individualTestScore.detailed_report.map(
                (item: any) => {
                  return <LinerProgressBar item={item} key={item} />;
                }
              )}
          </div>
        </div>
      </div>
      <div className="my-[30px]">
        <div className="font-medium text-2xl font-Avenir text-[#292D35] ">
          Detailed Report
        </div>
        <div className="flex flex-row  overflow-x-scroll lg:overflow-x-hidden md:overflow-x-hidden">
          {individualTestScore.detailed_report &&
            individualTestScore.detailed_report.length > 0 &&
            individualTestScore.detailed_report.map((item: any) => {
              return <ReportCardDetails reportData={item} key={item.name} />;
            })}
        </div>
      </div>
    </div>
  );
};

export default observer(DetailedResults);
